import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { RestService } from './rest.service';
import { AuthService } from './auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { genericRetryStrategy } from '../helpers/generic-retry-strategy';
import { catchError, retryWhen } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    isAuthenticated = false;

    constructor(private restService: RestService, private appService: AppService, private authService: AuthService) {
        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
        });
    }

    sendAnalyticsReports(meetingId, email, date) {
        let body = {
            email: email,
            date: date
        };
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/analytics/mailReports`,
            body
        );
    }

    getPollingEventData(webinarId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/status/updates/host${
                !this.isAuthenticated ? '/guest' : ''
            }`
        );
    }

    getWebinarStats(webinarId) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/stats`);
    }

    getLikeDetails(meetingId) {
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/meeting/${meetingId}/webinar/likesdetails${
                !this.isAuthenticated ? '/guest' : ''
            }`
        );
    }

    getAnalyticsData(webinarId, date: string) {
        let params = new HttpParams().set('date', date);
        return this.restService
            .get(
                `${this.appService.getEnvVariable('BASE_URL')}/meeting/${webinarId}/webinar/analytics/past${
                    !this.isAuthenticated ? '/guest' : ''
                }`,
                { params: params }
            )
            .pipe(
                retryWhen(genericRetryStrategy({ maxRetryAttempts: 10 })),
                catchError((error) => {
                    throw error;
                })
            );
    }

    getDataForGraphView(webinarDetails) {
        let viewers = 40;
        let reactions = 20;
        let questions = 15;
        let comments = 20;

        let eventDuration = Math.floor(webinarDetails.scheduledDuration / 60000); //in mins

        let graphViewData = {
            viewers,
            reactions,
            questions,
            comments,
            eventDuration
        };
        return graphViewData;
    }

    getAllSessions(webinarId) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/sessions/${webinarId}`);
    }
}
