import { Injectable, Inject, isDevMode } from '@angular/core';

import { LocalStorageService } from './local-storage.service';

import { environment } from './../../../environments/environment';
import 'url-search-params-polyfill';
import { APP_CONSTANT } from 'src/app/constants';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class AppService {
    private userInfo: any = {};
    private appQueryParams: URLSearchParams;
    config: any = {};
    private currentJiomeetId = '';
    private defaultLogin = '/login';
    public isMyJio;
    public webConfigurableStatus = new BehaviorSubject('default');
    public tagGoogleManagerId: any;
    historyId;
    constructor(private localStorageService: LocalStorageService) {}

    camerasSize = -1;
    enableVirtualBackground = false;
    setUserInfo(info) {
        this.userInfo = info;
        this.localStorageService.addItem('userInfo', info);
    }

    getUserInfo() {
        return this.localStorageService.getItem('userInfo') || this.userInfo;
    }

    setGuestUserInfo(info) {
        this.userInfo = info;
        this.localStorageService.addItem('guestUserInfo', info);
    }

    getGuestUserInfo() {
        return this.localStorageService.getItem('guestUserInfo') || {};
    }

    getEnvVariable(param) {
        return environment[param];
    }

    setConfig(config) {
        this.config = config;
        this.config['USER_NEW_SPEAKER_UI'] = true;
        this.config['EVENTS_STUIO'] = {
            enable: true,
            editor: {
                showScreenSharePanel: false,
                showTextPanel: false,
                showCameraPanel: false,
                stackPanel: {
                    enable: true
                },
                showShare: false
            }
        };
        this.webConfigurableStatus.next('config');
        this.addTagManagerScript();
    }

    setConfigVariable(param, value) {
        this.config[param] = value;
    }

    getConfigVariable(param) {
        return this.config[param];
    }

    getConfigVariableWithDefault(param, defaultVal: any = true) {
        return this.config[param] ?? defaultVal;
    }

    getMaxParticipantInCall(room) {
        const useAgoraMaxParticipantsFromConfig = this.getConfigVariable('useAgoraMaxParticipants');
        const agoraMaxParticipantsFromConfig = this.getConfigVariable('agoraMaxParticipants');
        return useAgoraMaxParticipantsFromConfig ? agoraMaxParticipantsFromConfig : room?.agoraMaxParticipants;
    }

    get isOnLine() {
        return window.navigator.onLine;
    }

    isJmStatsEnable() {
        if (!localStorage) {
            return false;
        }
        const value = localStorage.getItem('debug');
        const hostURL = this.getEnvVariable('HOST_URL');
        if (value === 'jio-media-client:*' || hostURL.includes('prestage')) {
            return true;
        } else {
            false;
        }
    }

    setCurrentCallJiomeetID(jiomeetid) {
        this.currentJiomeetId = jiomeetid;
    }

    getCurrentCallJiomeetID() {
        return this.currentJiomeetId;
    }

    setHistoryId(historyId) {
        this.historyId = historyId;
    }

    logout() {
        this.setUserInfo({});
    }

    setAppQueryParams(params: URLSearchParams) {
        this.appQueryParams = params;
    }

    getAppQueryParams(): URLSearchParams {
        return this.appQueryParams;
    }

    getAndSaveUserPreferredLang(lang = null) {
        if (lang) {
            this.localStorageService.addItem('userLang', lang);
            return lang;
        }

        return this.localStorageService.getItem('userLang') || 'en';
    }

    getWebinarPollingInterval() {
        return this.getConfigVariable('event_polling_interval') ?? APP_CONSTANT.WEBINAR_POLLING_EVENT_STATUS_INTERVAL;
    }
    getWebinarAnalyticsPollingInterval() {
        return (
            this.getConfigVariable('event_analytics_polling_interval') ??
            APP_CONSTANT.WEBINAR_POLLING_EVENT_STATUS_INTERVAL
        );
    }

    getWebinarWebsocketState(userType = APP_CONSTANT.WEBINAR_ATTENDEE_SOCKET_STATE) {
        return this.getConfigVariable(userType) ?? true; // the default state would be enabled.
    }

    getWebinarPollingState(userType = APP_CONSTANT.WEBINAR_ATTENDEE_POLLING_STATE) {
        return this.getConfigVariable(userType) ?? true; // the default state would be enabled.
    }

    getAppTitle() {
        // return 'JioConf';
        return this.getConfigVariable('IS_APP_TITLE_JIOEVENTS') ? 'JioEvents' : 'JioConf';
    }

    isJioEvents() {
        return this.getConfigVariable('IS_APP_TITLE_JIOEVENTS') ? true : false;
    }

    isJioEventsRedirectionEnabled() {
        return this.getConfigVariable('ENABLE_JIO_EVENTS_REDIRECTION') ? true : false;
    }

    getDefaultWebsiteLogo(color: 'white' | 'black' | 'transparent' = 'black', poweredByText = false) {
        return `assets/img/${this.isJioEvents() ? 'jioevents' : 'jioconf'}/logos/logo_${color}${
            poweredByText ? '_pb' : ''
        }.svg`;
    }

    getLoginPageUrl() {
        return this.getConfigVariable('JIOCONF_REDIRECTION')
            ? this.getConfigVariable('JIOCONF_REDIRECTION') + 'login'
            : this.defaultLogin;
    }

    getJobStatusInterval() {
        return this.getConfigVariable('job_polling_interval') ?? APP_CONSTANT.JOB_STATUS_INTERVAL;
    }

    getDownloadFileInterval() {
        return this.getConfigVariable('download_file_interval') ?? APP_CONSTANT.DOWNLOAD_FILE_INTERVAL;
    }

    getMyjioUrl() {
        var eventsBaseUrl = this.getEnvVariable('local')
            ? this.getEnvVariable('HOST_URL')
            : this.getConfigVariable('JIO_EVENTS_BASE_URL') || this.getEnvVariable('HOST_URL');
        return eventsBaseUrl + '/integrations/myjio';
    }

    getJioEventsBaseUrl() {
        // Add support for multiple frontend deployment with same backend
        var eventsBaseUrl = this.getEnvVariable('local')
            ? `${this.getEnvVariable('HOST_URL')}`
            : this.getConfigVariable('JIO_EVENTS_BASE_URL') || this.getEnvVariable('HOST_URL');

        var hostname = window.location.hostname;
        var supportedHosts = this.getConfigVariable('supported_hosts');
        if (supportedHosts && supportedHosts[hostname]) {
            return supportedHosts[hostname];
        } else {
            return eventsBaseUrl;
        }
    }

    getBaseUrl() {
        return window.location.href.indexOf('/jioconf') > -1
            ? this.getEnvVariable('HOST_URL') + 'jioconf/'
            : this.getJioEventsBaseUrl();
    }

    async canEnableVirtualBackground() {
        var isVbConfigEnabled = this.getConfigVariable('IS_VIRTUAL_BACKGROUND_ENABLED');
        if (isVbConfigEnabled && this.camerasSize === -1) {
            try {
                var mediaDevices = await navigator.mediaDevices.enumerateDevices();
                var cameras = mediaDevices.filter((deviceInfo: MediaDeviceInfo) => {
                    return deviceInfo.kind === 'videoinput';
                });
                this.camerasSize = cameras.length;
                //   if (this.camerasSize === 1) {
                // Enable vb when there is only one camera. TODO do this when its built in camera
                this.enableVirtualBackground = true;
                // }
            } catch (err) {
                this.camerasSize = 0;
                console.log('error while evaluating cameras for enabling vb');
                console.log(err);
            }
        }

        return this.enableVirtualBackground;
    }

    addTagManagerScript() {
        this.tagGoogleManagerId = this.getConfigVariable('googleTagManager');
        if (!this.tagGoogleManagerId) {
            return;
        }
        const googleTagManagerScript = document.createElement('script');
        googleTagManagerScript.innerHTML = `window.dataLayer = window.dataLayer || [];
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${this.tagGoogleManagerId}');`;
        document.head.appendChild(googleTagManagerScript);
        const body = document.getElementsByTagName('BODY')[0];
        const div = document.createElement('DIV');
        div.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${this.tagGoogleManagerId}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
        body.appendChild(div);
    }

    getAddonsBaseUrl() {
        return isDevMode()
            ? 'https://localhost:4200/api/addons'
            : this.getConfigVariableWithDefault('ADDONS_API_BASE_URL', 'https://rc.jioevents.com/api/addons');
    }
}
