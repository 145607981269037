import { Injectable } from '@angular/core';

import { tap, catchError, retry } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';

import { AppService } from './app.service';
import { RestService } from './rest.service';
import { LocalStorageService } from './local-storage.service';
import { EventEmitterService } from './event-emitter.service';
import { UserService } from './user.service';

import { APP_EVENTS } from '../../constants';
import { SessionStorageService } from './session-storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private isAuthenticated;
    sessionTimeout = false;
    serverDown = false;
    private isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject(this.getIsAuthenticated());
    private authInfo;
    private rilAuthInfo;

    constructor(
        private appService: AppService,
        private restService: RestService,
        private userService: UserService,
        private localStorageService: LocalStorageService,
        private sessionStorageService: SessionStorageService,
        private eventEmitterService: EventEmitterService
    ) {}

    setRILAuthInfo(info) {
        this.rilAuthInfo = info;
        this.localStorageService.addItem('rilUserInfo', info);
    }

    getRILAuthInfo() {
        return this.rilAuthInfo || this.localStorageService.getItem('rilUserInfo');
    }

    setAuthInfo(info) {
        if (info.jwt) {
            this.sessionTimeout = false;
            this.authInfo = info;
            this.localStorageService.addItem('userInfo', info);
            this.isAuthenticated = true;
            this.isAuthenticated$.next(true);

            this.eventEmitterService.emit({
                type: APP_EVENTS.AUTHORIZED,
                data: null
            });
        }
    }

    getAuthInfo() {
        return this.authInfo || this.localStorageService.getItem('userInfo');
    }

    getIsAuthenticated(): boolean {
        if (this.isAuthenticated$) {
            this.isAuthenticated$.next(this.isAuthenticated || !!this.getAuthInfo());
        }
        return this.isAuthenticated || !!this.getAuthInfo();
    }

    getIsAuthenticated$() {
        return this.isAuthenticated$;
    }

    login({ email, password }) {
        return this.restService
            .post(`${this.appService.getEnvVariable('BASE_URL')}/user/login`, {
                email,
                password
            })
            .pipe(
                tap((authInfo: any) => {
                    if (authInfo.success !== false) {
                        this.setAuthInfo(authInfo);
                    }
                })
            );
    }

    signup({ emailId, name, lname, code = '' }) {
        const payload: any = {
            ...(emailId && { emailId }),
            ...(name && { name }),
            ...(lname && { lname }),
            ...(code && { code })
        };
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/sign/signup`, payload);
    }

    resendEmail(emailId) {
        const payload: any = {
            emailId
        };
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/sign/resend`, payload);
    }

    getOtp({ phoneNo, code = '' }) {
        const payload: any = {
            ...(phoneNo && { phoneNo }),
            ...(code && { code })
        };
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/getotp`, payload);
    }

    verifyOTP({ phoneNo, otp, code = '', username = '', lname = '' }) {
        const payload: any = {
            ...(phoneNo && { phoneNo }),
            ...(otp && { otp }),
            ...(code && { code }),
            ...(username && { username }),
            ...(lname && { lname })
        };
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/verifyotp`, payload).pipe(
            tap((authInfo: any) => {
                if (authInfo.success !== false) {
                    if (!authInfo.token) {
                        this.setAuthInfo(authInfo);
                    }
                }
            })
        );
    }

    verifyOtpDualLogin({ phoneNo, otp, token }) {
        return this.restService
            .post(`${this.appService.getEnvVariable('BASE_URL')}/verifyotp`, {
                phoneNo,
                otp,
                token,
                dualLogin: true
            })
            .pipe(tap((authInfo) => this.setAuthInfo(authInfo)));
    }

    verifyEmailUser(hash) {
        return this.restService.get(`${this.appService.getEnvVariable('BASE_URL')}/shorturl/verify/${hash}`);
    }

    refreshToken() {
        const authInfo = this.getAuthInfo();
        if (authInfo && authInfo.refToken) {
            return this.restService
                .post(`${this.appService.getEnvVariable('BASE_URL')}/refreshtoken`, {
                    jwt: authInfo.jwt,
                    refToken: authInfo.refToken
                })
                .pipe(
                    retry(2),
                    tap((info) => this.setAuthInfo(info)),
                    catchError((err) => {
                        this.cleanup();
                        this.eventEmitterService.emit({
                            type: APP_EVENTS.SESSION_EXPIRED,
                            data: { navigateToLogin: true }
                        });
                        return throwError(err);
                    })
                );
        } else {
            return throwError('no jwt');
        }
    }

    switch(token) {
        if (token) {
            return this.restService
                .post(`${this.appService.getEnvVariable('BASE_URL')}/login/switch`, {
                    token
                })
                .pipe(
                    tap((info) => this.setAuthInfo(info)),
                    catchError((err) => {
                        return throwError(err);
                    })
                );
        } else {
            return throwError('No token');
        }
    }

    changePassword(payload) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/changePassword`, payload);
    }

    forgotPassword(emailId) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/invokeResetPassword`, emailId);
    }

    logout(persistPage = false) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/logout`, {}).pipe(
            tap(() => {
                const isSAMLLogin = this.localStorageService.getItem('isSAMLLogin');
                this.eventEmitterService.emit({
                    type: APP_EVENTS.LOGOUT,
                    data: { navigateToLogin: !isSAMLLogin, persistPage: persistPage }
                });
                this.cleanup();
                if (isSAMLLogin && this.appService.getConfigVariable('LOGOUT_URL')) {
                    window.location.href = this.appService.getConfigVariable('LOGOUT_URL');
                }
            })
        );
    }

    doSSOLogin({
        providerName = 'saml',
        redirectRoute = '/login',
        queryParams = {
            email: null,
            bot: false,
            hostInfo: null,
            outlook: false
        },
        externalIntegrationParams = {
            google: false,
            googleRedirectURI: null
        }
    }) {
        this.localStorageService.addItem('isSAMLLogin', providerName === 'saml');
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/authorize/${providerName}?redirectUri=${
                this.getRedirectionDomain() + redirectRoute
            }${queryParams.email ? '&email=' + queryParams.email : ''}${queryParams.bot ? '&bot=true' : ''}${
                queryParams.outlook ? '&outlook=true' : ''
            }${queryParams.hostInfo ? '&_host_Info=' + queryParams.hostInfo : ''}${
                externalIntegrationParams.google ? '&google=true' : ''
            }${
                externalIntegrationParams.googleRedirectURI
                    ? '&googleRedirectURI=' + encodeURIComponent(externalIntegrationParams.googleRedirectURI)
                    : ''
            }`
        );
    }

    isDomainUser({ providerName = 'saml', redirectRoute = 'login', queryParams = { email: null, bot: false } }) {
        this.localStorageService.addItem('isSAMLLogin', providerName === 'saml');
        return this.restService.get(
            `${this.appService.getEnvVariable('BASE_URL')}/authorize/${providerName}?redirectUri=${
                window.location.href
            }/${redirectRoute}${queryParams.email ? '&email=' + queryParams.email : ''}${
                queryParams.bot ? '&bot=true' : ''
            }`
        );
    }

    getSamlAccessToken(sessionId, token) {
        return this.restService
            .get(`${this.appService.getEnvVariable('BASE_URL')}/session/${sessionId}/accesstoken?token=${token}`)
            .pipe(tap((authInfo) => this.setAuthInfo(authInfo)));
    }

    getRILAccessToken(sessionId, token) {
        return this.restService
            .get(`${this.appService.getEnvVariable('BASE_URL')}/session/${sessionId}/accesstoken?token=${token}`)
            .pipe(tap((authInfo) => this.setRILAuthInfo(authInfo)));
    }

    inviteUser(phoneNo) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/utils/invite`, {
            phoneNo
        });
    }

    isOTTUser({ phoneNo = '', email = '', code = '' }) {
        const payload: any = {
            ...(phoneNo && { phoneNo }),
            ...(email && { email }),
            ...(code && { code })
        };
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/isottuser`, payload);
    }

    isServerDown(bool) {
        this.serverDown = bool;
    }

    getRedirectionDomain() {
        return window.location.href.indexOf('/jioconf') > -1
            ? this.appService.getEnvVariable('HOST_URL') + 'jioconf'
            : this.appService.getJioEventsBaseUrl();
    }

    cleanup() {
        this.authInfo = null;
        this.isAuthenticated = false;
        this.isAuthenticated$.next(false);
        this.localStorageService.removeItem('userInfo');
        this.localStorageService.removeItem('rilUserInfo');
        this.userService.cleanup();
    }
}
