import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { AppService } from './app.service';
import { AuthService } from './auth.service';
import { BehaviorSubject, throwError } from 'rxjs';
import { map, retryWhen, tap } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { SystemService } from './system.service';
import { VideoWrapperService } from './video-wrapper.service';
import * as _ from 'lodash-es';
import { genericRetryStrategy } from '../helpers/generic-retry-strategy';
import { JioMeetApiRetryPipe } from '../helpers/jiomeet-api-retry-pipe';
import { retryBackoff } from 'backoff-rxjs';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { EventEmitterService, RtcService } from '.';
import { APP_EVENTS, MEDIA_ENGINE_EVENTS } from 'src/app/constants';
import { AppLoggerService } from './app-logger.service';

export interface PanelHighlightControl {
    chat: boolean;
    participants: boolean;
    new_message: boolean;
    popoutchat: boolean;
    qa: false;
    comment: boolean;
    ticker: boolean;
    polls: boolean;
}
@Injectable({
    providedIn: 'root'
})
export class RoomConnectionService {
    private roomConnectionStatus$: BehaviorSubject<any> = new BehaviorSubject(null);
    private controlsHighlightResolver$: BehaviorSubject<PanelHighlightControl> = new BehaviorSubject({
        chat: false,
        participants: false,
        new_message: false,
        popoutchat: false,
        qa: false,
        comment: false,
        ticker: false,
        polls: false
    });
    controlsHighlightResolver = {
        chat: false,
        participants: false,
        new_message: false,
        popoutchat: false,
        liveChat: false,
        qa: false,
        comment: false,
        ticker: false,
        games: false
    };
    private roomInfo: any = {};
    private localParticipant: any = {};
    private sortKey;
    private videoService;
    currentMeetingInfo: any;
    ignoreHardMute = false;
    ignoreHardMuteVideo = false;
    participantsList: any;
    autoJoinInProgress = false;
    constructor(
        private restService: RestService,
        private systemService: SystemService,
        private authService: AuthService,
        private videoWrapperService: VideoWrapperService,
        private appService: AppService,
        private confirmationService: ConfirmationService,
        private translateService: TranslateService,
        private toastrService: ToastrService,
        public rtcService: RtcService,
        private eventEmitterService: EventEmitterService,
        private appLoggerService: AppLoggerService
    ) {
        this.updateVideoServiceInstance();
    }

    updateVideoServiceInstance() {
        this.videoService = this.videoWrapperService.getVideoService();
    }

    updateConnectionStatus({
        jiomeetId,
        roomID,
        roomPin,
        historyId,
        status,
        participantUri,
        participantId,
        participantName,
        micStatus,
        cameraStatus,
        isHandRaise = false,
        customStreamShare = false,
        deviceId = this.systemService.getDeviceUUID(),
        deviceType = 'web',
        participantType = 'speaker'
    }) {
        const isAuthenticated = this.authService.getIsAuthenticated();
        return this.restService
            .post(
                `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus${!isAuthenticated ? '/guest' : ''}`,
                {
                    jiomeetId,
                    roomID,
                    roomPin,
                    historyId,
                    status,
                    deviceId,
                    deviceType,
                    participantUri,
                    participantId,
                    participantName,
                    micStatus,
                    cameraStatus,
                    isHandRaise,
                    participantType,
                    customStreamShare
                }
            )
            .pipe(
                tap((res: any) => {
                    if (res?.success && Object.keys(this.roomInfo).length > 2) {
                        let updatedParticipantIdx = this.roomInfo.participants.findIndex(
                            (p) => p.participantId === participantId
                        );
                        this.roomInfo.participants[updatedParticipantIdx] = {
                            ...this.roomInfo.participants[updatedParticipantIdx],
                            micStatus,
                            cameraStatus,
                            isHandRaise
                        };
                        this.updateLocalParticipantFromRCS({
                            id: this.roomInfo.participants[updatedParticipantIdx].participantId
                        });
                        this.sendRoomConnectionStatus();
                    }
                })
            );
    }

    public updateHighlightController(object) {
        this.controlsHighlightResolver = object;
        this.controlsHighlightResolver$.next(object);
    }

    public get highlightControl(): BehaviorSubject<any> {
        return this.controlsHighlightResolver$;
    }

    public getHightlightedControls() {
        return this.controlsHighlightResolver;
    }

    getMeetingParticipants(jiomeetId, roomID, roomPin, retry = true) {
        const isAuthenticated = this.authService.getIsAuthenticated();
        const payload = {
            jiomeetId,
            roomID,
            roomPin,
            ...(this.sortKey && { sortkey: this.sortKey })
        };
        return this.restService
            .post(
                `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/get${
                    !isAuthenticated ? '/guest' : ''
                }`,
                payload
            )
            .pipe(
                tap((res: any) => {
                    if (this.autoJoinInProgress) return;
                    if (!res || (res?.success === false && retry)) {
                        throw res;
                    }
                    this.checkForAudioHardMute(res?.isHardMute || this.getRcsLocalParticipant(res)?.isHardMute);
                    this.checkForVideoHardMute(
                        res?.isHardVideoMute || this.getRcsLocalParticipant(res)?.isHardVideoMute
                    );
                    this.roomInfo = res;
                    if (this.rtcService.getIsAgoraMeeting() || this.rtcService.getIsJMMeeting()) {
                        this.videoService.checkForSpotlightUpdate(res?.spotlightedParticipants);
                    }
                    this.setLocalParticipantAndSyncPrivacy();
                    this.syncParticipantsWithAgoraUsers();
                    this.sendRoomConnectionStatus();
                }),
                retryWhen(genericRetryStrategy())
            );
    }

    getRcsLocalParticipant(res) {
        return res.participants.find((p) => p.participantUri == this.videoService.getLocalParticipant().id);
    }

    syncParticipantsWithAgoraUsers() {
        let remoteParticipants = this.videoService.galleryParticipants || [];
        let audienceCount = 0;
        this.roomInfo.participants = this.roomInfo.participants.filter((participant) => {
            audienceCount += participant.participantType === 'audience' ? 1 : 0;
            if (
                participant.isHost ||
                this.localParticipant.participantId == participant.participantId ||
                participant.participantType === 'audience'
            )
                return true;
            return remoteParticipants.findIndex((p) => p.uid == participant.participantId) !== -1;
        });
        this.participantsList = this.roomInfo?.participants;
        this.roomInfo.audienceCount = audienceCount;
        this.roomInfo.speakerCount = this.roomInfo.participants.length - audienceCount;
    }

    toggleShareScreen(jiomeetId, participantUri, action: 'start' | 'stop') {
        const isAuthenticated = this.authService.getIsAuthenticated();
        return this.restService
            .post(
                `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/sharescreen${
                    !isAuthenticated ? '/guest' : ''
                }`,
                { jiomeetId, participantUri, action }
            )
            .pipe(JioMeetApiRetryPipe.addResponseBasedRetry);
    }

    setAudio(jiomeetId, roomID, participantId, audio) {
        const isAuthenticated = this.authService.getIsAuthenticated();
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/control/audio${
                !isAuthenticated ? '/guest' : ''
            }`,
            {
                jiomeetId,
                roomID,
                participantId,
                audio
            }
        );
    }

    muteUnmuteAudioAll({ jiomeetId, roomID, hard, audio }) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/control/audioall`,
            {
                jiomeetId,
                roomID,
                hard,
                audio
            }
        );
    }

    setVideo(jiomeetId, roomID, participantId, video, customStreamShare = false) {
        const isAuthenticated = this.authService.getIsAuthenticated();
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/control/video${
                !isAuthenticated ? '/guest' : ''
            }`,
            {
                jiomeetId,
                roomID,
                participantId,
                video,
                customStreamShare
            }
        );
    }

    muteUnmuteVideoAll({ jiomeetId, roomID, hard, video }) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/control/videoall`,
            {
                jiomeetId,
                roomID,
                hard,
                video
            }
        );
    }

    hostActions({
        jiomeetId,
        roomID,
        roomPin,
        allHandsDown = false,
        coHostsRemoved = [],
        coHostsAdded = [],
        handsDownParticipantIds = []
    }) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/hosts`, {
            jiomeetId,
            roomID,
            roomPin,
            allHandsDown,
            coHostsRemoved,
            coHostsAdded,
            handsDownParticipantIds
        });
    }

    setRoomInfo(info) {
        this.roomInfo = info;
    }

    addOrRemoveSpotlightees({ jiomeetId, roomID, roomPin, spotlighteesRemoved, spotlighteesAdded }) {
        const isAuthenticated = this.authService.getIsAuthenticated();
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/spotlight${
                !isAuthenticated ? '/guest' : ''
            }`,
            {
                jiomeetId,
                roomID,
                roomPin,
                spotlighteesRemoved,
                spotlighteesAdded
            }
        );
    }
    reorderSpotlight({ jiomeetId, roomID, roomPin, spotlightedParticipantIds }) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/spotlight`, {
            jiomeetId,
            roomID,
            roomPin,
            spotlightedParticipantIds
        });
    }
    removeParticipant(jiomeetId, participantID) {
        return this.restService.post(`${this.appService.getEnvVariable('BASE_URL')}/portal/removeparticipant`, {
            jiomeetId,
            participantID
        });
    }

    updateParticipantsStatus(participantsArray, source) {
        if (typeof this.roomInfo?.participants === 'undefined') return;
        (participantsArray || []).forEach((deltaParticipant) => {
            let index = this.roomInfo?.participants?.findIndex(
                (p) => p?.participantUri === deltaParticipant?.participantUri
            );
            if (index !== -1) {
                this.roomInfo.participants[index] = deltaParticipant;
                this.checkAndUpdateLocalParticipant(deltaParticipant, source);
            } else {
                this.roomInfo?.participants?.push(deltaParticipant);
            }
        });
        this.roomInfo.participants = this.sortParticipantList(this.roomInfo.participants, this.sortKey);
        const shouldSyncUserPrivacyState =
            participantsArray.length === 1 &&
            this.localParticipant.participantId === participantsArray[0].participantId &&
            source === 'host';
        this.setLocalParticipantAndSyncPrivacy(shouldSyncUserPrivacyState);
        this.syncParticipantsWithAgoraUsers();
        this.sendRoomConnectionStatus();
    }

    checkAndUpdateLocalParticipant(participant, source) {
        if (source === 'host') return;
        if (this.roomInfo?.localParticipant?.participantUri === participant?.participantUri) {
            this.roomInfo.localParticipant = participant;
            this.localParticipant = participant;
        }
    }

    getRoomConnectionStatus$() {
        return this.roomConnectionStatus$;
    }

    setSortKeys(key) {
        this.sortKey = key;
    }

    private setLocalParticipantAndSyncPrivacy(isSourceSocket = false) {
        const localVidyoParticipant = this.videoService.getLocalParticipant();
        this.updateLocalParticipantFromRCS(localVidyoParticipant);
        this.checkForAudioSoftMute();
        this.handleVideoSoftMute();
        if (this.roomInfo.localParticipant) {
            this.syncMicPrivacyStatus(isSourceSocket);
            this.syncCameraPrivacyStatus(isSourceSocket);
            this.localParticipant = this.roomInfo.localParticipant;
        }
    }

    updateLocalParticipantFromRCS(localVidyoParticipant) {
        this.roomInfo.localParticipant = (this.roomInfo?.participants || []).filter(
            (p) => (localVidyoParticipant || {}).id == p.participantUri
        )[0];
    }

    syncMicPrivacyStatus(isSourceSocket) {
        if (isSourceSocket && this.roomInfo.localParticipant.micStatus !== this.localParticipant.micStatus) {
            this.videoService.toggleMicPrivacy(!this.roomInfo.localParticipant.micStatus);
        } else if (
            this.localParticipant.micStatus &&
            (((this.roomInfo.isHardMute || this.roomInfo.localParticipant.isHardMute) && !this.ignoreHardMute) ||
                this.roomInfo.isSoftMute)
        ) {
            this.videoService.toggleMicPrivacy(true);
        } else if (this.roomInfo.localParticipant.micStatus !== this.localParticipant.micStatus) {
            this.setAudio(
                this.currentMeetingInfo.jiomeetId,
                this.currentMeetingInfo.room_id,
                this.localParticipant?.participantId,
                this.localParticipant.micStatus
            );
        }
        this.ignoreHardMuteForHosts(); // Also runs on joining call with hard mute
        // alternate: move this to 320 and also run this on first RCS response
    }

    checkForAudioHardMute(incomingHardMuteState) {
        if (!incomingHardMuteState && this.ignoreHardMute) {
            this.ignoreHardMute = false;
        }
    }

    checkForVideoHardMute(incomingHardMuteState) {
        if (!incomingHardMuteState && this.ignoreHardMuteVideo) {
            this.ignoreHardMuteVideo = false;
        }
    }

    ignoreHardMuteForHosts() {
        if (
            (this.roomInfo.localParticipant.isCoHost || this.roomInfo.localParticipant.isHost) &&
            this.roomInfo.isHardMute
        ) {
            this.ignoreHardMute = true;
        }
    }

    ignoreHardMuteVideoForHosts() {
        if (
            (this.roomInfo.localParticipant.isCoHost || this.roomInfo.localParticipant.isHost) &&
            this.roomInfo.isHardVideoMute
        ) {
            this.ignoreHardMuteVideo = true;
        }
    }

    syncCameraPrivacyStatus(isSourceSocket) {
        if (isSourceSocket && this.roomInfo.localParticipant.cameraStatus !== this.localParticipant.cameraStatus) {
            if (!this.videoService.enableStreamMixer) {
                this.videoService.toggleCameraPrivacy(!this.roomInfo.localParticipant.cameraStatus);
            }
        } else if (
            this.localParticipant.cameraStatus &&
            (((this.roomInfo.isHardVideoMute || this.roomInfo.localParticipant.isHardVideoMute) &&
                !this.ignoreHardMuteVideo) ||
                this.roomInfo.isSoftVideoMute)
        ) {
            this.videoService.toggleCameraPrivacy(true);
        } else if (this.roomInfo.localParticipant.cameraStatus !== this.localParticipant.cameraStatus) {
            this.setVideo(
                this.currentMeetingInfo.jiomeetId,
                this.currentMeetingInfo.room_id,
                this.localParticipant?.participantId,
                this.localParticipant.cameraStatus
            );
        }
        this.ignoreHardMuteVideoForHosts();
    }

    checkForAudioSoftMute() {
        if (this.roomInfo.isSoftMute) {
            this.disableAudioSoftMute();
        }
    }

    handleVideoSoftMute() {
        if (this.roomInfo.isSoftVideoMute) {
            this.disableVideoSoftMute();
        }
    }

    disableAudioSoftMute() {
        if (!this.roomInfo.localParticipant.isCoHost && !this.roomInfo.localParticipant.isHost) {
            return;
        }
        let payload = {
            jiomeetId: this.currentMeetingInfo.jiomeetId,
            roomID: this.currentMeetingInfo.room_id,
            audio: true,
            hard: false
        };
        this.muteUnmuteAudioAll(payload).subscribe(
            () => {},
            (err) => {
                this.appLoggerService.error('Error while disabling Audio Soft mute for all participants', err);
            }
        );
    }

    disableVideoSoftMute() {
        if (!this.roomInfo.localParticipant.isCoHost && !this.roomInfo.localParticipant.isHost) {
            return;
        }
        let payload = {
            jiomeetId: this.currentMeetingInfo.jiomeetId,
            roomID: this.currentMeetingInfo.room_id,
            video: true,
            hard: false
        };
        this.muteUnmuteVideoAll(payload).subscribe(
            () => {},
            (err) => {
                this.appLoggerService.error('Error while disabling Video Soft mute for all participants', err);
            }
        );
    }

    getLocalParticipant() {
        return this.localParticipant;
    }

    private sendRoomConnectionStatus() {
        this.roomConnectionStatus$.next(this.roomInfo);
    }

    resetRoomConnectionData() {
        this.roomInfo = {};
        this.participantsList = [];
        this.localParticipant = {};
        this.roomConnectionStatus$ = new BehaviorSubject(null);
    }

    changeParticipantType({ jiomeetId, roomID, roomPin, participantIds = [], participantType }) {
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/control/participantType`,
            {
                jiomeetId,
                roomID,
                roomPin,
                participantIds,
                participantType
            }
        );
    }

    updateConnectionStatusWithToken(
        {
            jiomeetId,
            roomID,
            roomPin,
            historyId,
            status,
            participantUri,
            participantId,
            participantName,
            micStatus,
            cameraStatus,
            isHandRaise = false,
            deviceId = this.systemService.getDeviceUUID(),
            deviceType = 'web'
        },
        jwt
    ) {
        const isAuthenticated = this.authService.getIsAuthenticated();
        let headers: HttpHeaders = new HttpHeaders();
        headers = headers.set('Authorization', jwt);
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/guest`,
            {
                jiomeetId,
                roomID,
                roomPin,
                historyId,
                status,
                deviceId,
                deviceType,
                participantUri,
                participantId,
                participantName,
                micStatus,
                cameraStatus,
                isHandRaise
            },
            { headers }
        );
    }

    private sortParticipantList(arr, sortkey = 'atoz') {
        if (sortkey === 'atoz') {
            return _.orderBy(
                arr,
                ['isHost', 'isHandRaise', (name) => name?.participantName?.toLowerCase()],
                ['desc', 'desc', 'asc']
            );
        }
        if (sortkey === 'ztoa') {
            return _.orderBy(
                arr,
                ['isHost', 'isHandRaise', (name) => name?.participantName?.toLowerCase()],
                ['desc', 'desc', 'desc']
            );
        }
        if (sortkey === 'camera') {
            return _.orderBy(
                arr,
                ['isHost', 'isHandRaise', 'cameraStatus', (name) => name.participantName.toLowerCase()],
                ['desc', 'desc', 'desc', 'asc']
            );
        }
        if (sortkey === 'mic') {
            return _.orderBy(
                arr,
                ['isHost', 'isHandRaise', 'micStatus', (name) => name.participantName.toLowerCase()],
                ['desc', 'desc', 'desc', 'asc']
            );
        }
        return _.orderBy(
            arr,
            ['isHost', 'isHandRaise', (name) => name.participantName.toLowerCase()],
            ['desc', 'desc', 'asc']
        );
    }

    sendAudioLevel({ jiomeetId, roomID, roomPin, participantId, audioLevel }) {
        audioLevel = Math.floor(audioLevel);
        const isAuthenticated = this.authService.getIsAuthenticated();
        return this.restService.post(
            `${this.appService.getEnvVariable('BASE_URL')}/roomconnectionstatus/audiolevel${
                !isAuthenticated ? '/guest' : ''
            }`,
            {
                jiomeetId,
                roomID,
                roomPin,
                participantId,
                audioLevel
            }
        );
    }

    spotlightUser(participantId, participantName, meetingDetails) {
        // Co host can spotlight Host
        const isSpotlighted = this.isParticipantSpotlighted(participantId);
        if (!isSpotlighted && this.checkMaxSpotlightLimit(participantId, participantName)) return;

        this.confirmationService.confirm({
            message: !isSpotlighted
                ? this.translateService.instant('tostrs.are_you_sure_you_want_to_spotlight', {
                      value: participantName,
                      meetingType: 'Event'
                  })
                : this.translateService.instant('tostrs.are_you_sure_you_want_to_remove_spotlight', {
                      value: participantName,
                      meetingType: 'Event'
                  }),
            header: !isSpotlighted
                ? this.translateService.instant('tostrs.make_spotlight')
                : this.translateService.instant('tostrs.remove_spotlight'),
            acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
            rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
            acceptLabel: this.translateService.instant('tostrs.yes'),
            rejectLabel: this.translateService.instant('tostrs.no'),
            accept: () => {
                const payload = {
                    jiomeetId: meetingDetails.jiomeetId,
                    roomID: meetingDetails.roomID,
                    roomPin: meetingDetails.roomPin,
                    spotlighteesRemoved: isSpotlighted ? [participantId] : [],
                    spotlighteesAdded: !isSpotlighted ? [participantId] : []
                };
                this.updateSpotlight(payload, participantId);
            }
        });
    }

    checkMaxSpotlightLimit(participantId, participantName) {
        if (this.roomInfo.spotlightedParticipants.length >= 6) {
            let presentParticipantIds = [];
            this.roomInfo.participants.forEach((p) => {
                presentParticipantIds.push(p.participantId);
            });
            if (_.difference(this.roomInfo.spotlightedParticipants, presentParticipantIds).length !== 0) {
                const payload = {
                    jiomeetId: this.currentMeetingInfo.jiomeetId,
                    roomID: this.currentMeetingInfo.room_id || this.currentMeetingInfo.roomID,
                    roomPin: this.currentMeetingInfo.userPIN || this.currentMeetingInfo.roomPin,
                    spotlighteesRemoved: _.difference(this.roomInfo.spotlightedParticipants, presentParticipantIds),
                    spotlighteesAdded: []
                };
                this.addOrRemoveSpotlightees(payload).subscribe(
                    (res: any) => {
                        if (res.success) return false;
                    },
                    (err) => {
                        // host is shown spotlight List with existing participants.
                        this.openSpotlighteesList(participantId, participantName);
                        return true;
                    }
                );
            } else {
                this.openSpotlighteesList(participantId, participantName);
                return true;
            }
        }
        return false;
    }

    openSpotlighteesList(participantId, participantName) {
        this.toastrService.error(
            'Only 6 participants can be spotlighted at once, please remove atleast one participant to spotlight a new participant.'
        );
        this.eventEmitterService.emit({
            type: APP_EVENTS.VIEW_SPOTLIGHT_LIST,
            data: { participantId, participantName }
        });
    }

    updateSpotlight(payload, participantId) {
        // this.videoService.spotLightUser(participantId);
        this.addOrRemoveSpotlightees(payload).subscribe(
            (res: any) => {
                if (res.success) {
                    this.getMeetingParticipants(payload.jiomeetId, payload.roomID, payload.roomPin).subscribe();
                } else {
                    this.toastrService.error(res?.message || 'Something went wrong');
                }
            },
            (err) => {
                this.toastrService.error(
                    err?.error?.errors || this.translateService.instant('tostrs.something_went_wrong_try_again_later')
                );
            }
        );
    }

    getParticipantName(participantId) {
        return this.roomInfo.participants.find(
            (participant) => parseInt(participant.participantUri) === parseInt(participantId)
        )?.participantName;
    }

    isParticipantSpotlighted(pId) {
        return this.roomInfo.spotlightedParticipants.findIndex((spId) => spId === pId) > -1;
    }

    setMeetingInfo(meetingInfo) {
        this.currentMeetingInfo = meetingInfo;
    }

    getRoomInfo() {
        return this.roomInfo;
    }

    validateHostStatus() {
        return this.roomInfo.localParticipant?.isHost || this.roomInfo.localParticipant?.isCoHost;
    }

    updateLocalParticipantType(participant, type) {
        let idx_toUpdate = this.roomInfo.participants.findIndex((p) => p.participantUri === participant.participantUri);
        this.roomInfo.participants[idx_toUpdate].participantType = type;
        this.roomInfo.audienceCount += type === 'audience' ? 1 : -1;
        this.roomInfo.speakerCount = this.roomInfo.participants?.length - this.roomInfo.audienceCount;
        this.sendRoomConnectionStatus();
    }

    getParticipantByParticipantId(uid) {
        return this.participantsList.find((p) => p.participantId === uid.toString());
    }
}
