import { AfterViewInit, ElementRef, ViewChild, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AppService,
    EventData,
    EventEmitterService,
    MeetingService,
    SessionStorageService,
    UserService,
    UtilService
} from 'src/app/core';
import { WebinarService } from 'src/app/dashboard-webinars/services/webinar.service';
import { EventUserRole } from 'src/app/constants/webinar-enum';
import { ToastrService } from 'ngx-toastr';
import { APP_EVENTS } from 'src/app/constants';
import { CallStateManagerService } from 'src/app/core/classes/call-state-manager.service';
import { SwiperOptions } from 'swiper';
import { WebinarAttendeeService } from 'src/app/webinar-attendee';
import isSameDay from 'date-fns/isSameDay';
import { EventSessionsService } from 'src/app/shared/services/event-sessions.service';
import { DomHandler } from 'primeng/dom';
import { TabView } from 'primeng/tabview';

@Component({
    selector: 'app-event-sessions',
    templateUrl: './event-sessions.component.html',
    styleUrls: ['./event-sessions.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EventSessionsComponent implements OnInit, AfterViewInit {
    // @ViewChild(TabMenu) tabMenuRef: TabMenu;
    @ViewChild('tabMenuRef') tabMenuRef: TabView;
    @Input() webinarId: string;
    @Input() isInvalidMeeting;
    @Input() sessionsInfo = [];
    @Input() isSpeaker: boolean = false;
    @Input() eventSpeakers = [];
    @Input() webinarDetails;
    @Input() brandDetails;
    @Input() replayDetails;
    @Input() sessionsToRemind;
    @Input() userHasRegistered: boolean;
    @Input() isHostCohost: boolean = false;
    @Input() isRecurring: boolean;
    occurrences: Date[] = [];
    currentUser: any = {};
    userSessions = [];
    showMySessions = false;
    loading: boolean = true;
    sessionsInfoLocal = [];
    autoJoin = false;
    showBanner: boolean = false;
    viewDate = new Date();
    viewEndDate: Date;
    showCalendar = false;
    selectedDate: Date;
    daysInWeek = 7;
    isMobileView = false;
    isMobileOS;
    isDefaultSessionView = true;
    enableMarkdown;
    sessionDates = new Set();
    selectedEventDate = null;
    public speakersSwiperConfig: SwiperOptions = {
        speed: 1200,
        loop: false,
        observer: true,
        autoplay: {
            delay: 1000,
            disableOnInteraction: false
        },
        slidesPerView: 'auto',
        pagination: false,
        breakpoints: {
            320: {
                slidesPerView: 1.1,
                spaceBetween: 2
            },
            1280: {
                slidesPerView: 3,
                spaceBetween: 2
            }
        }
    };
    isAllSessionsPreRecorded: boolean = false;
    userId: any;
    constructor(
        private activatedRoute: ActivatedRoute,
        private meetingService: MeetingService,
        private webinarService: WebinarService,
        private utilService: UtilService,
        private userService: UserService,
        private appService: AppService,
        private toasterService: ToastrService,
        private route: Router,
        private eventEmitterService: EventEmitterService,
        private callStateManagerService: CallStateManagerService,
        public webinarAttendeeService: WebinarAttendeeService,
        private elementRef: ElementRef,
        private eventSessionsService: EventSessionsService
    ) {
        if (!this.webinarId) {
            this.webinarId = this.activatedRoute.snapshot.params.shID;
        }
        this.currentUser = this.userService.getUserSync();
    }

    ngOnInit(): void {
        this.isMobileView = this.utilService.isResponsiveMobileView();
        this.showBanner = this.webinarDetails?.customization?.sessionsAsLanding;
        if (this.sessionsInfo.length === 0) {
            this.eventEmitterService.emit({
                type: 'GET_SESSIONS',
                data: null
            });
            this.loading = false;
        } else {
            this.loading = false;
        }
        this.loading = this.utilService.getAutoJoinInfo()?.autoJoin ? true : false;

        if (this.userSessions?.length) {
            this.showMySessions = true;
        }
        this.checkIfRecurringEvent();
        const webinarStarTime = this.webinarDetails?.startTime || this.webinarDetails?.scheduledStartTime;
        if (webinarStarTime) {
            this.handleDaySelection({ day: { date: webinarStarTime } }); // default day selection
        }
        this.isMobileOS = this.utilService.isResponsiveMobileView(1024) || this.utilService.isIpad();

        this.handleAppEvents();
        this.enableMarkdown = this.appService.getConfigVariable('ENABLE_MARKDOWN_FOR_FORM') || false;
        if (this.isRecurring) {
            this.userId = this.userService.getUserSync()._id;
            this.eventSessionsService.getSessionsInfo(this.webinarId, this.userId);
            this.occurrences = this.eventSessionsService.getRecurringDates(this.webinarDetails);
        }
    }

    ngOnChanges(change) {
        this.sessionsInfoLocal = this.sessionsInfo;
        if (change?.eventSpeakers || this.sessionsInfo.length) {
            this.setUserSessions();
        }
        this.checkIfRecurringEvent();
    }

    ngAfterViewInit() {
        this.hideTabviewNavBtn();
    }

    onTabChange(index) {
        this.selectedDate = new Date(this.eventSessionsService.occurrences[index]);
    }

    updateButtonState() {
        const content = this.tabMenuRef.content?.nativeElement;
        const { scrollLeft, scrollWidth } = content;
        const width: number = DomHandler.getWidth(content);

        this.tabMenuRef.backwardIsDisabled = scrollLeft === 0;
        this.tabMenuRef.forwardIsDisabled = Math.ceil(scrollLeft) === scrollWidth - width;
        if (this.tabMenuRef.forwardIsDisabled) {
            this.tabMenuRef.nextBtn.nativeElement.setAttribute('style', 'display:none !important');
        }
    }

    hideTabviewNavBtn() {
        const elements = document.getElementsByClassName('p-tabview-nav-btn');
        if (this.occurrences.length < 9) {
            for (let i = 0; i < elements.length; i++) {
                const element = elements[i] as HTMLElement;
                element.style.display = 'none';
            }
        }
    }

    async setUserSessions() {
        await this.getSessionDatesForMultiDayEvent(this.sessionsInfo);

        this.scrollToSelectedIndex();

        const meAsSpeakerInfo = this.webinarDetails?.speakers?.find(
            (speaker) => speaker.userId && speaker.userId === this.currentUser._id
        );
        if (meAsSpeakerInfo) {
            this.userSessions = this.sessionsInfo.filter((session) => {
                return session.invites?.indexOf(meAsSpeakerInfo._id) !== -1;
            });

            if (this.userSessions?.length && this.isDefaultSessionView) {
                this.showMySessions = true;
            }
            this.isAllSessionsPreRecorded = this.sessionsInfo.every((session) => {
                return session?.preRecordedVideo?.enabled;
            });
            if (this.isAllSessionsPreRecorded) {
                this.showMySessions = false;
            }
        }
        this.handleAutoJoinSession();
    }

    getSessionDatesForMultiDayEvent(sessionsInfo) {
        return new Promise((resolve) => {
            sessionsInfo.forEach((session) => {
                const sessionStartDate = this.getSessionDate(new Date(session.startTime));
                this.sessionDates.add(sessionStartDate);
            });
            if (!this.selectedEventDate) {
                let it = this.sessionDates.values();
                this.selectedEventDate = it.next()?.value;
                this.sessionDates.forEach((date: Date) => {
                    if (isSameDay(new Date(date), new Date())) {
                        this.selectedEventDate = date;
                        return;
                    }
                });
            }
            resolve(true);
        });
    }

    getSessionDate(date: Date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate()).toDateString();
    }

    handleAutoJoinSession(session = null) {
        // check if sessionId is stored in session to auto join and auto join info is there then auto join a session
        const sessionIdToAutoJoinViaQueryParam = this.meetingService.getSessionToAutoJoin();
        const autoJoinInfo = this.utilService.getAutoJoinInfo();
        const sessionIdToAutoJoin = autoJoinInfo?.sessionIdToAutoJoin || sessionIdToAutoJoinViaQueryParam;
        const sessionInfo = this.sessionsInfo.find((session) => session.meetingId == sessionIdToAutoJoin);
        if (session || (sessionInfo && (autoJoinInfo?.autoJoin || sessionIdToAutoJoinViaQueryParam))) {
            this.navigateToWebinarSession(session || sessionInfo);
            //reset after navigation
            this.meetingService.setSessionToAutoJoin();
        }
    }

    scrollToSelectedIndex() {
        const scrollTab = document.getElementById('session-tabs');
        if (!scrollTab) {
            return;
        }
        const tabs = scrollTab.querySelectorAll('.sessions-date-tab');
        const allTabs = Array.from(this.sessionDates);
        const tabIndex = allTabs.indexOf(this.selectedEventDate) || allTabs.indexOf(new Date());
        const tabAtIndex = tabs[tabIndex];
        if (tabAtIndex instanceof HTMLElement) {
            const scrollOffset = tabAtIndex.offsetLeft - scrollTab.offsetLeft;
            scrollTab.scrollLeft = scrollOffset;
        }
    }
    sanitizeText(message) {
        return this.utilService.sanitizeAndConvertEncodedHTML(message);
    }

    getSessionsInfo() {
        this.webinarService.getWebinarSessionsInfo(this.webinarId, !this.currentUser._id).subscribe(
            (res) => {
                if (res?.sessions?.length) {
                    this.sessionsInfo = this.webinarDetails?.customization?.hideCompletedSessions
                        ? this.isHostCohost
                            ? res?.sessions || []
                            : res?.sessions.filter((session) => {
                                  return session?.status == 'active';
                              }) || []
                        : res?.sessions || [];
                    // get host info of this webinar
                    const hostDetail = res.speakers?.find((speaker) => speaker.role === EventUserRole.HOST);
                    this.webinarDetails = { ...res.meetingDetails, hostInfo: hostDetail, speakers: res.speakers };
                    this.prepareSpeakersDetails();
                    this.checkIfRecurringEvent();
                    // filter my sessions
                    // first check if i m invited as speaker
                    const meAsSpeakerInfo = this.webinarDetails?.speakers?.find(
                        (speaker) => speaker.userId && speaker.userId === this.currentUser._id
                    );
                    if (meAsSpeakerInfo) {
                        this.userSessions = this.sessionsInfo.filter((session) => {
                            return session.invites?.indexOf(meAsSpeakerInfo._id) !== -1;
                        });

                        if (this.userSessions?.length) {
                            this.handleToggle();
                        }
                    }

                    res?.sessions.forEach((session, index) => {
                        // add speakers info to the session
                        session.speakers = [];
                        session.parentMeetingUrl = `${this.appService.getBaseUrl()}event/${
                            session.parentMeetingId
                        }/sessions`;
                        if (this.eventSpeakers.length && session.invites.length) {
                            session.invites.forEach((speakerId) => {
                                const speakerDetail = this.eventSpeakers.find(
                                    (speaker) =>
                                        speaker._id === speakerId &&
                                        speaker.role &&
                                        speaker.role?.indexOf(EventUserRole.SPEAKER) !== -1
                                );
                                if (speakerDetail) {
                                    session.speakers.push(speakerDetail);
                                }
                            });
                        }
                    });
                }
                this.loading = false;
            },
            (err) => {
                this.toasterService.error(err?.error?.errors || err?.error?.message || 'Something went wrong');
                this.route.navigate(['dashboard'], {});
                console.log(err);
            }
        );
    }

    prepareSpeakersDetails() {
        this.webinarDetails?.speakers?.forEach((speaker) => {
            if (speaker.role && speaker.role?.indexOf(EventUserRole.SPEAKER) !== -1) {
                this.eventSpeakers.push(speaker);
            }
        });
    }

    checkIfRecurringEvent() {
        this.showCalendar = ['daily', 'weekly', 'monthly'].includes(this.webinarDetails?.repeatOptions?.repeat);
    }

    handleToggle(e = null) {
        this.showMySessions = !this.showMySessions;
        this.isDefaultSessionView = false;
    }

    handleDaySelection(ev) {
        console.log(ev);
        this.selectedDate = new Date(ev.day.date);
    }

    async navigateToWebinarSession(sessionInfo) {
        this.eventEmitterService.emit({ type: APP_EVENTS.NAVIGATE_TO_MAINSTAGE_ON_SESSION_SELECT, data: sessionInfo });
    }

    handleAppEvents() {
        this.eventEmitterService.subscribe((event: EventData) => {
            switch (event.type) {
                case APP_EVENTS.AUTO_JOIN: {
                    const data = event.data;
                    if (data && data.autoJoin && data.nextSession) {
                        // auto join next session
                        this.handleAutoJoinSession(data.nextSession);
                    }
                    break;
                }

                default:
                    break;
            }
        });
    }

    trackByFunction(index, item) {
        return item._id;
    }
}
