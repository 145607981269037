import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { APP_EVENTS } from 'src/app/constants';
import {
    AppService,
    AuthService,
    EventEmitterService,
    GuestAuthService,
    RoomConnectionService,
    UserService,
    UtilService
} from 'src/app/core';
import { LiveChatService } from 'src/app/core/services/live-chat.service';
import { ChatMarkdownComponent } from '../chat-markdown/chat-markdown.component';
import { Datasource, IDatasource, SizeStrategy } from 'ngx-ui-scroll';
declare const TurndownService: any;
@Component({
    selector: 'app-live-chat',
    templateUrl: './live-chat.component.html',
    styleUrls: ['./live-chat.component.scss']
})
export class LiveChatComponent implements OnInit, AfterViewInit {
    @Input() meetingObj: any = {};
    @Input() sessionId: any = '';
    @Output() close: EventEmitter<any> = new EventEmitter();
    @ViewChild(ChatMarkdownComponent) private chatMarkdownComponent: ChatMarkdownComponent;
    @ViewChild('livechatContainer') liveChatContainer: ElementRef;
    turnDownService = new TurndownService();
    liveMessages: any = [];

    pinedMessages: any = [];
    userInfo: any;
    showMarkdown: boolean = false;
    selectedEmoji: boolean = false;
    enableMarkdown: boolean = true;
    showEmojiBar: boolean = true;
    roomStatus: any;
    roomParticipants: any = [];
    unreadChatParticipantIds: any[] = [];
    currentParticipant: any;
    guestUser: any;
    messagePolling: any;
    liveMessagesCount = 0;
    showPinedArea: boolean = false;
    lastMessageId: any;
    lastApiSeqId: any;
    currentScrollPosition: any = -1;
    scrollPercentage: any = new BehaviorSubject(0);
    showFloatNewMessage: boolean = false;
    showLastUnreadLabel: boolean = false;
    mimimumMessgaeCount: any = 10;
    ignoreNextScrollEvent: boolean = false;
    timeIntervalArray: any = [];
    showNewMessageId: any = '';
    maxLength: any = 200;
    addTextvallidationinterval: any;
    liveChatPollingTime: any = 10000;
    isMobileOS: boolean = false;
    deletedMessages: any = {};
    longPressedChatId: any = '';
    userOwnMsg: any = [];
    isSafari: boolean = false;
    MIN = 0;
    MAX = 20;
    addIndx = 0;
    public datasource: any;
    isDataSourceEnabled: boolean = false;
    dataSourceThershold: any = 0;
    dataSourceArray: any = [];
    startOldApi = false;
    dataSourceFirstSeq;
    dataSourceFirstItem;
    datasourceEndSeq;
    datasourceEndItem;
    $listenToScroll: any;
    prependStop: any = 0;
    loadingOldMessages: boolean = false;
    loadingNewMessages: boolean = false;
    lastVisibleObs$: any;
    isChatPollingIsInProcess: boolean = false;
    constructor(
        private liveChatService: LiveChatService,
        private userService: UserService,
        private utilService: UtilService,
        private roomConnectionService: RoomConnectionService,
        private zone: NgZone,
        private guestAuthService: GuestAuthService,
        private authService: AuthService,
        private eventEmitterService: EventEmitterService,
        private toastService: ToastrService,
        private elementRef: ElementRef,
        private appService: AppService
    ) {
        this.mimimumMessgaeCount = this.liveChatService.getminMessageToShow()
            ? this.liveChatService.getminMessageToShow()
            : 10;
        this.liveChatPollingTime = this.liveChatService.getMessagePollinTime()
            ? this.liveChatService.getMessagePollinTime()
            : 10000;
    }

    listenToLastVisbleElement() {
        if (this.lastVisibleObs$) {
            this.lastVisibleObs$?.unsubscribe();
        }
        this.lastVisibleObs$ = this.datasource.adapter.lastVisible$.pipe(debounceTime(200)).subscribe((data) => {
            if (
                data.$index == this.datasource.adapter.bufferInfo.lastIndex &&
                this.datasource.adapter.eof &&
                data.data['_id'] == this.datasourceEndItem?._id
            ) {
                if (this.showFloatNewMessage) {
                    this.newMessageFloatingBtnClick();
                }
            }
        });
    }

    loadOldmessages() {
        if (this.loadingNewMessages) {
            this.toastService.info('New Messages Loading');
            return;
        }
        if (this.loadingOldMessages) {
            return;
        }
        this.loadingOldMessages = true;
        if (this.liveChatService.isSeqAvailable(this.dataSourceFirstSeq - 1)) {
            let messages = this.liveChatService.getSeqMessages(this.dataSourceFirstSeq - 1);
            this.liveMessages = [...messages, ...this.liveMessages];
            this.setLiveFirstMsgInfo(this.dataSourceFirstSeq - 1);
            this.prependElement(messages);
        } else {
            if (this.liveChatService.messageContainerStartSeq < this.dataSourceFirstSeq - 1) {
                let seqId = this.dataSourceFirstSeq - 1;
                if (seqId > 0) this.getAllMessages(seqId, 'backword', true);
            } else {
                this.handleOldMessgaeScroll(true);
            }
        }
    }

    initData(scrollToEnd = true) {
        if (this.liveMessages.length > this.dataSourceThershold && !this.isDataSourceEnabled) {
            this.MIN = 1;
            let startinindx = Math.max(this.liveMessages.length, 0);
            this.datasourceEndItem = this.liveChatService.getArrayLastElements(this.liveMessages);
            this.datasource = new Datasource({
                get: (index, count, success) => {
                    const data = this.getData(index, count);
                    success(data);
                },
                settings: {
                    startIndex: startinindx,
                    sizeStrategy: SizeStrategy.Average,
                    bufferSize: 10
                }
            });
            this.startOldApi = false;
            this.isDataSourceEnabled = true;
            if (scrollToEnd) {
                this.scrollToLastMessage(true);
            }
        } else {
            this.scrollToLastMessage();
        }
    }

    getData(index: number, count: number) {
        const data = [];
        const start = index;
        const end = start + count - 1;
        for (let i = start; i <= end; i++) {
            const _index = i - this.MIN;
            if (_index >= 0 && _index < this.liveMessages.length) {
                let previous = _index - 1 > 0 ? this.liveMessages[_index - 1] : null;
                let currentMsg = this.liveMessages[_index];
                let isTop = previous == null || previous?.createdBy != currentMsg.createdBy;
                currentMsg['isTop'] = isTop;
                data.push(currentMsg);
            }
        }
        return data;
    }

    ngOnInit(): void {
        this.userInfo = this.userService.getUserSync();
        this.isMobileOS = this.utilService.isMobileBrowser();
        this.isSafari = this.utilService.isSafariBrowser() || this.utilService.getOS() == 'iOS';
        this.guestUser = this.guestAuthService.getAuthInfo();
        if (this.userInfo?._id) {
            this.currentParticipant = {
                userId: this.userInfo._id
            };
        }
        if (this.guestUser?.jwt) {
            let decodeUser = this.decodeJWT(this.guestUser.jwt);
            let name;
            if (this.userInfo?.firstName) {
                name = `${this.userInfo.firstName} ${this.userInfo.lastName}`;
            } else {
                name = `${this.guestUser.firstName} ${this.guestUser.lastName}`;
            }
            this.currentParticipant = {
                userId: decodeUser.userId,
                isHost: false,
                participantName: name
            };
        }
        let latestSeqInfo = this.liveChatService.getLatestMessagesForContainer();
        if (latestSeqInfo.currentSeq) {
            this.liveMessages = latestSeqInfo.messages;
            this.setLiveLastMsgInfo(latestSeqInfo.currentSeq);
            this.setLiveFirstMsgInfo(latestSeqInfo.oldMessageSeq);
        }
        this.dataSourceThershold = this.isMobileOS
            ? 5
            : this.appService.getConfigVariable('dataSourceThresholdForLiveChat')
            ? this.appService.getConfigVariable('dataSourceThresholdForLiveChat')
            : 15;
        if (this.liveMessages.length) {
            this.lastMessageId = this.liveChatService.getArrayLastElements(this.liveMessages)._id;
        }
        if (!this.isDataSourceEnabled) {
            this.initData();
        }
        this.getRoomParticipants();
        this.getAllMessages(null, 'forward', null);
        this.pollingChatMessgae();
    }

    ngAfterViewInit() {
        this.maxstringlengthValidator();
    }

    ngAfterViewChecked() {}

    maxstringlengthValidator() {
        this.addTextvallidationinterval = setInterval(() => {
            this.chatMarkdownComponent?.quill?.on('text-change', (olddata, newdata) => {
                let length = this.chatMarkdownComponent?.quill?.getText().replace('\n', '').length;
                if (this.maxLength < length) {
                    this.chatMarkdownComponent?.quill.setContents(newdata);
                }
            });
            if (this.chatMarkdownComponent?.quill) {
                clearInterval(this.addTextvallidationinterval);
            }
        }, 2000);
    }

    async loadDataToview() {
        let latestSeqInfo = this.liveChatService.getLatestMessagesForContainer();
        if (latestSeqInfo.currentSeq) {
            this.liveMessages = latestSeqInfo.messages;
            this.setLiveLastMsgInfo(latestSeqInfo.currentSeq);
            this.setLiveFirstMsgInfo(latestSeqInfo.oldMessageSeq);
        }
        this.isDataSourceEnabled = false;
        this.loadingNewMessages = false;
        if (this.datasource?.adapter) {
            await this.datasource.adapter.reload(this.liveMessages.length);
        }
        this.scrollToLastMessage();
    }
    handleNewIncomingMessage() {
        if (!this.liveMessages.length) {
            return;
        }
        let checkScrollPos = this.checkScrollPositon();
        if (
            this.lastMessageId &&
            this.lastMessageId != this.liveChatService.getArrayLastElements(this.liveMessages)._id
        ) {
            if (checkScrollPos < 0.95) {
                if (!this.showLastUnreadLabel) {
                    this.showNewMessageId = this.lastMessageId;
                }
                this.showLastUnreadLabel = true;
            }
            if (checkScrollPos < 0.95 && !this.showPinedArea) {
                this.showFloatNewMessage = true;
            } else {
                let scrolltimeOut = setTimeout(() => {
                    this.scrollToLastMessage();
                }, 200);
                this.timeIntervalArray.push(scrolltimeOut);
            }
        } else {
            this.lastMessageId = this.liveMessages[this.liveMessages.length - 1]._id;
            return;
        }
    }

    handleOldMessgaeScroll(scrollTochat = false) {
        let seqId = this.liveChatService.messageContainerStartSeq - 1;
        if (seqId < 1) {
            return;
        }
        this.startOldApi = false;
        this.getAllMessages(seqId, 'backword', scrollTochat);
    }

    listScroll(event) {
        if (this.ignoreNextScrollEvent) {
            this.ignoreNextScrollEvent = false;
        }
        let percentage = this.checkScrollPositon();
        if (percentage == 1) {
            let clearNewMessageLine = setTimeout(() => {
                this.showLastUnreadLabel = false;
            }, 10000);
            this.timeIntervalArray.push(clearNewMessageLine);
            this.showFloatNewMessage = false;
        }
        this.scrollPercentage.next(percentage);
    }

    checkScrollPositon() {
        if (!this.liveChatContainer?.nativeElement) {
            return 1;
        }
        let maxScrollLength =
            this.liveChatContainer.nativeElement.scrollHeight - this.liveChatContainer.nativeElement.offsetHeight;
        let sp =
            Math.ceil(
                this.liveChatContainer.nativeElement.offsetHeight + this.liveChatContainer.nativeElement.scrollTop
            ) - this.liveChatContainer.nativeElement.scrollHeight;
        return (sp + maxScrollLength) / maxScrollLength;
    }

    getRoomParticipants() {
        this.roomConnectionService.getRoomConnectionStatus$().subscribe((res) => {
            this.zone.run(() => {
                if (res) {
                    this.roomStatus = res;
                    this.setRoomParticipats(this.roomStatus.participants);
                }
            });
        });
    }

    pollingChatMessgae() {
        this.messagePolling = setInterval(() => {
            this.getAllMessages('', 'forward', null);
        }, this.liveChatPollingTime);
    }

    decodeJWT(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    }

    setRoomParticipats(participants) {
        let obj = {};
        for (let i = 0; i < participants.length; i++) {
            obj[participants[i].userId] = participants[i];
        }
        const usersArray = [];
        for (var key in obj) {
            usersArray.push(obj[key]);
        }
        this.roomParticipants = this.utilService.clone(usersArray);
        this.roomParticipants.sort((p1, p2) => {
            const p1UnreadSender = this.unreadChatParticipantIds.includes(p1.userId);
            const p2UnreadSender = this.unreadChatParticipantIds.includes(p2.userId);
            return p1UnreadSender === p2UnreadSender ? 0 : p1UnreadSender ? -1 : 1;
        });
        let userId = this.authService.getIsAuthenticated() ? this.userInfo._id : this.guestUser.guestUserId;
        this.currentParticipant = this.roomParticipants.find((p) => p.userId === userId);
    }

    async getAllMessages(seqId = null, type = 'forward', scrollToChat) {
        if (seqId === '' && type === 'forward' && this.isChatPollingIsInProcess) return;
        this.isChatPollingIsInProcess = seqId === '' && type === 'forward';
        let seqid = seqId ? seqId : this.liveChatService.latestSeqId;
        let id = this.sessionId ? this.sessionId : this.meetingObj.meetingId;
        try {
            var data = await this.liveChatService.getAllMessages(id, seqid).toPromise();
            if (data?.messages) {
                if (type == 'forward') {
                    this.handleLatestMessageResponse(data, data.currSeqId, id);
                } else {
                    this.addOldMessages(data.messages, data.currSeqId, scrollToChat);
                }
            }
            this.isChatPollingIsInProcess = false;
        } catch (err) {
            this.isChatPollingIsInProcess = false;
            if (type !== 'forward') {
                this.toastService.error('Error While loading old messages');
            }
        }
        // this.liveChatService.getAllMessages(id, seqid).subscribe(
        //     (data) => {
        //         if (data?.messages) {
        //             if (type == 'forward') {
        //                 this.handleLatestMessageResponse(data, data.currSeqId, id);
        //             } else {
        //                 this.addOldMessages(data.messages, data.currSeqId, scrollToChat);
        //             }
        //         }
        //         this.isChatPollingIsInProcess = false;
        //     },
        //     (err) => {
        //         this.isChatPollingIsInProcess = false;
        //         if (type !== 'forward') {
        //             this.toastService.error('Error While loading old messages');
        //         }
        //     }
        // );
    }

    handleLatestMessageResponse(msgarray, seqId, id) {
        let newMessgaes = this.liveChatService.setNewMessages(msgarray.messages, seqId, id);
        if (msgarray?.pinnedMsg) {
            this.pinedMessages = msgarray.pinnedMsg;
            this.liveChatService.pinedmessages = msgarray.pinnedMsg.map((el) => el._id);
            if (!this.pinedMessages.length) {
                this.showPinedArea = false;
            }
        }
        if (msgarray.deletedMessages) {
            this.deletedMessages = msgarray.deletedMessages;
        }
        if (msgarray.messages.length) {
            this.removeUserSentMesaages(msgarray);
        }
        if (newMessgaes.length) {
            this.processNewIncomingMessages(newMessgaes, seqId);
        }
    }

    removeUserSentMesaages(msgarray) {
        let lastMessage = this.liveChatService.getArrayLastElements(msgarray.messages);
        let ownMsg = this.userOwnMsg.filter((msg) => {
            return new Date(msg.cOn).getTime() > new Date(lastMessage.cOn).getTime() + 1000;
        });
        this.userOwnMsg = ownMsg;
    }

    processNewIncomingMessages(newMessgaes, seqId) {
        if (!this.isDataSourceEnabled) {
            if (newMessgaes.length + this.liveMessages.length > this.dataSourceThershold) {
                this.liveMessages.push(...newMessgaes);
                this.setLiveLastMsgInfo(seqId);
                this.initData();
            } else {
                this.liveMessages.push(...newMessgaes);
                this.handleNewMessageWithoutDataSource(seqId);
            }
        } else {
            let { adapter } = this.datasource;
            let lastVisibleItem: any = adapter.lastVisible.data;
            if (adapter.eof && lastVisibleItem._id == this.datasourceEndItem?._id) {
                this.liveMessages.push(...newMessgaes);
                this.setLiveLastMsgInfo(seqId);
                this.appendElement(newMessgaes);
            } else {
                this.enableFloatingButtonAndLabel(this.datasourceEndItem._id);
            }
        }
    }

    setLiveLastMsgInfo(seq) {
        this.datasourceEndSeq = seq;
        this.datasourceEndItem = this.liveChatService.getArrayLastElements(this.liveMessages);
    }

    setLiveFirstMsgInfo(seq) {
        this.dataSourceFirstSeq = seq;
        this.dataSourceFirstItem = this.liveMessages[0];
    }

    handleNewMessageWithoutDataSource(seqId) {
        let scrollposition = this.checkScrollPositon();
        if (Number.isNaN(scrollposition)) {
            return;
        }
        if (scrollposition == 1) {
            this.scrollToLastMessage();
        } else {
            this.enableFloatingButtonAndLabel(this.datasourceEndItem._id);
        }
        this.setLiveLastMsgInfo(seqId);
        this.loadingNewMessages = false;
    }

    enableFloatingButtonAndLabel(msgId) {
        if (!this.showLastUnreadLabel) {
            this.showNewMessageId = msgId;
        }
        this.showLastUnreadLabel = true;
        if (!this.showPinedArea) {
            this.showFloatNewMessage = true;
        }
    }

    newMessageFloatingBtnClick() {
        if (this.loadingOldMessages) {
            this.toastService.info('Old Messages are loading');
            return;
        }
        if (this.loadingNewMessages) {
            return;
        }
        this.loadingNewMessages = true;
        const { adapter } = this.datasource;
        /** when click newmessage btn or scroll to end of the container need to check last message is from lates sequence*/
        if (
            this.datasourceEndSeq == this.liveChatService.latestMessageSeq &&
            adapter.lastVisible.data['_id'] == this.datasourceEndItem._id
        ) {
            /** if the messages is from latest sequence need just append the messages at the end */
            let seqMsgs = JSON.parse(JSON.stringify(this.liveChatService.getSeqMessages(this.datasourceEndSeq)));
            let idx = seqMsgs.findIndex((msg) => msg._id == this.datasourceEndItem._id);
            let needToaddMsgs = seqMsgs.slice(idx + 1);
            this.liveMessages.push(...needToaddMsgs);
            this.setLiveLastMsgInfo(this.datasourceEndSeq);
            this.appendElement(needToaddMsgs);
        } else {
            /** if it is not then we need load latest 20 or 30 messages to the dom reset all pointer*/
            this.loadDataToview();
        }
        this.showFloatNewMessage = false;
    }

    async appendElement(data) {
        if (!this.isDataSourceEnabled) return;
        this.MAX = this.MAX + data.length;
        let { adapter } = this.datasource;
        await adapter.relax();
        adapter.append({
            items: data,
            eof: true,
            decrease: false
        });
        this.scrollToLastMessage();
        this.loadingNewMessages = false;
    }

    async prependElement(data) {
        if (!this.isDataSourceEnabled) {
            this.initData(false);
            this.loadingOldMessages = false;
            return;
        }
        this.MIN = this.MIN - data.length;
        let { adapter } = this.datasource;
        let copArray = JSON.parse(JSON.stringify(data));
        copArray.reverse();
        await adapter.reload();
        this.startOldApi = true;
        await adapter.clip({ forwardOnly: true });
        this.loadingOldMessages = false;
    }

    addOldMessages(data, seqId, scrollToChat) {
        this.liveChatService.lastPrevSeqId = seqId;
        this.liveMessages = [...data, ...this.liveMessages];
        this.liveChatService.updateOldMessagesInSequenceInfo(seqId, data);
        this.addIndx += data.length;
        this.setLiveFirstMsgInfo(Number(seqId));
        this.prependElement(data);
        if (!this.isDataSourceEnabled) {
            this.initData();
        }
        if (!scrollToChat) {
            let scrolltimeOut = setTimeout(() => {
                this.scrollToLastMessage();
            }, 500);
            this.timeIntervalArray.push(scrolltimeOut);
        }
    }

    async scrollToLastMessage(initListener = false) {
        this.showFloatNewMessage = false;
        if (this.isDataSourceEnabled) {
            let { adapter } = this.datasource;
            if (adapter?.fix) {
                await adapter.relax();
                adapter.fix({ scrollPosition: +Infinity });
                setTimeout(() => {
                    adapter.fix({ scrollPosition: +Infinity });
                    if (this.liveChatContainer?.nativeElement) {
                        this.liveChatContainer.nativeElement.scrollTop =
                            this.liveChatContainer.nativeElement.scrollHeight;
                        if (adapter.itemsCount > 40) {
                            adapter.clip({ backwardOnly: true });
                        }
                        this.loadingNewMessages = false;
                    }
                    if (initListener) {
                        this.listenToLastVisbleElement();
                    }
                }, 500);
            }
        } else {
            setTimeout(() => {
                if (this.liveChatContainer?.nativeElement) {
                    this.liveChatContainer.nativeElement.scrollTop = this.liveChatContainer.nativeElement.scrollHeight;
                    this.loadingNewMessages = false;
                }
            }, 200);
        }
    }

    checkLastMessage(local, server) {
        if (local.length && server.length) {
            return local[local.length - 1]._id !== server[server.length - 1]._id;
        }
    }
    sendMessage() {
        let message = '';
        if (this.chatMarkdownComponent?.quill?.container?.firstChild?.innerText?.trim()) {
            message = this.turnDownService.turndown(
                this.chatMarkdownComponent?.quill?.container?.firstChild?.innerHTML
            );
            message = this.utilService.sanitizeHTML(message);
            message = message.replace('[](about:blank)', '').trim();
            message = message.replace(/\\_/g, '_');
            message = message.replace('\\>', '>');
            message = message.replace('&amp;', '&');
        }
        if (message.trim().length) {
            let id = this.sessionId ? this.sessionId : this.meetingObj.meetingId;
            let type =
                this.currentParticipant?.isHost || this.currentParticipant?.isCoHost
                    ? this.currentParticipant.isHost
                        ? 'Host'
                        : 'Co-Host'
                    : this.currentParticipant?.participantType
                    ? this.currentParticipant?.participantType
                    : '';
            type == 'attendee' ? '' : type;
            let data = {
                messageComponent: {
                    text: this.utilService.encodeHtmlEntities(message.trim())
                },
                meetingId: id,
                userType: type
            };
            let tempSendTime = new Date().toISOString();
            this.chatMarkdownComponent.clearMeessage();
            this.liveChatService.sendMessage(data).subscribe(
                (data: any) => {
                    if (data.success) {
                        this.selectedEmoji = false;
                        let sendMessageTxt = JSON.parse(JSON.stringify(`Message sent successfully`));
                        this.toastService.success(sendMessageTxt);

                        if (data?.messageDetails) {
                            let tempMsg = Object.assign({}, data?.messageDetails);
                            tempMsg.cOn = tempSendTime;
                            this.userOwnMsg.push(tempMsg);
                        }
                        let scrollTimeOut;
                        if (this.isDataSourceEnabled) {
                            let { adapter } = this.datasource;
                            let lastVisibleItem: any = adapter.lastVisible.data;
                            if (adapter.eof && lastVisibleItem._id == this.datasourceEndItem?._id) {
                                scrollTimeOut = setTimeout(() => {
                                    this.scrollToLastMessage();
                                }, 500);
                            } else {
                                this.loadDataToview();
                            }
                        } else {
                            scrollTimeOut = setTimeout(() => {
                                this.scrollToLastMessage();
                            }, 500);
                        }

                        if (scrollTimeOut) {
                            this.timeIntervalArray.push(scrollTimeOut);
                        }
                        this.getAllMessages('', 'forward', false);
                    } else {
                        this.toastService.error(`can't send message. Please try again later`);
                    }
                },
                (err) => {
                    this.toastService.error(`can't send message. Please try again later`);
                }
            );
        } else {
            this.toastService.error('Please type  a message to continue');
            this.chatMarkdownComponent.clearMeessage();
        }
    }
    toggleMarkdown() {
        this.showMarkdown = !this.showMarkdown;
    }
    toggleEmojis(ev) {
        this.selectedEmoji = !this.selectedEmoji;
        ev.preventDefault();
    }

    moreOptionAction(event) {
        switch (event.type) {
            case 'Pin Message':
                this.getAllMessages(null, 'forward', false);
                break;
            case 'showall Pined Message':
                this.showPinedArea = true;
                break;
            case 'LongPressChat':
                if (this.isMobileOS) {
                    if (!this.longPressedChatId && this.isSafari) {
                        let el = document.getElementById(`chat-${event.data}`);
                        if (el) {
                            let boundaries = el.getBoundingClientRect();
                            let height = boundaries.height > 98 ? boundaries.height : 98;
                            this.elementRef.nativeElement.style.setProperty(
                                '--selectMsgTop',
                                `${boundaries.top - height + 'px'}`
                            );
                            this.elementRef.nativeElement.style.setProperty(
                                '--selectMsgWidth',
                                `${boundaries.width + 'px'}`
                            );
                            this.elementRef.nativeElement.style.setProperty(
                                '--selectMsgright',
                                `${boundaries.left + 'px'}`
                            );
                            this.elementRef.nativeElement.style.setProperty('--selectMsgHeight', `${height}px`);
                        }
                    }
                    this.longPressedChatId = event.data;
                }
        }
    }

    updateMessage(data) {
        // this.getAllMessages('modified');
    }

    clearDeleteSelection(id) {
        if (id === this.longPressedChatId) {
            this.longPressedChatId = null;
        }
    }

    reportMessgae(message) {
        let user = {
            name: this.currentParticipant.participantName,
            userId: this.currentParticipant?.userId
        };
        message.reportedBy.push(user);
        this.liveChatService.updateMessage(this.meetingObj.meetingId, message).subscribe((res) => {});
    }

    hideAllPinedMessages() {
        this.showPinedArea = false;
    }

    showAllPinedMessages() {
        if (this.pinedMessages.length < 2) this.showPinedArea = true;
    }

    insertEmoji(event) {
        this.chatMarkdownComponent?.quill?.focus();
        const caretPosition = this.chatMarkdownComponent?.quill.getSelection(true);
        this.chatMarkdownComponent?.quill?.insertText(caretPosition, event.char);
    }

    closeLiveChat() {
        this.close.emit();
    }

    trackByFn(index, item) {
        return item._id;
    }

    emojiOutsideClick() {
        if (this.selectedEmoji) {
            this.selectedEmoji = false;
        }
    }

    hideEmojiPanel() {
        if (this.selectedEmoji) {
            this.selectedEmoji = false;
        }
    }

    ngOnDestroy() {
        this.liveChatService.finalMessageCountOnClose = 0;
        this.liveChatService.newMessageSCount = 0;
        if (this.messagePolling) {
            clearInterval(this.messagePolling);
        }
        if (this.addTextvallidationinterval) {
            clearInterval(this.addTextvallidationinterval);
        }
        this.timeIntervalArray.forEach((el) => {
            if (el) {
                clearTimeout(el);
            }
        });
        if (this.lastVisibleObs$) {
            this.lastVisibleObs$?.unsubscribe();
        }
        if (document.getElementById('callOptionsConfimDialog')) {
            let clickelm: any = document
                .getElementById('callOptionsConfimDialog')
                .querySelector('.p-dialog-header-close');
            if (clickelm) clickelm.click();
        }
    }
}
