export const homePage = {
    GET_STARTED: {
        event: 'get_started',
        current_page: 'home_page',
        outcome: 'succeeded',
        clicked_text: ''
    },
    CUSTOMISE: {
        event: 'button_click',
        current_page: 'home_page',
        clicked_text: 'customise',
        outcome: 'succeeded'
    },
    INTERACT: {
        event: 'button_click',
        current_page: 'home_page',
        clicked_text: 'interact',
        outcome: 'succeeded'
    },
    ANALYSE: {
        event: 'button_click',
        current_page: 'home_page',
        clicked_text: 'analyse',
        outcome: 'succeeded'
    },
    GET_STARTED_BOTTOM: {
        event: 'get_started_bottom',
        current_page: 'home_page',
        outcome: 'succeeded',
        clicked_text: ''
    },
    EXPLORE_NOW: {
        event: 'explore_now',
        current_page: 'home_page',
        outcome: 'succeeded',
        clicked_text: ''
    }
};
export const HEADER = {
    PRODUCTS: {
        event: 'jioevents',
        event_category: 'jioevents_header',
        event_action: 'Products',
        event_label: 'Success'
    },
    SOLUTIONS: {
        event: 'button_click',
        current_page: 'jioevents_header',
        clicked_text: 'solutions',
        outcome: 'succeeded'
    },
    CUSTOMERS: {
        event: 'button_click',
        current_page: 'jioevents_header',
        clicked_text: 'customers',
        outcome: 'succeeded'
    },
    PRICING: {
        event: 'button_click',
        current_page: 'jioevents_header',
        clicked_text: 'pricing',
        outcome: 'succeeded'
    },
    SUPPORT: {
        event: 'button_click',
        current_page: 'jioevents_header',
        clicked_text: 'support',
        outcome: 'succeeded'
    },
    SIGN_IN: {
        event: 'button_click',
        current_page: 'jioevents_header',
        clicked_text: 'signin',
        outcome: 'succeeded'
    },
    SIGN_OUT: {
        event: 'jioevents',
        event_category: 'jioevents_header',
        event_action: 'SignOut',
        event_label: 'Success'
    },
    WEBINARS: {
        event: 'button_click',
        current_page: 'jioevents_header',
        clicked_text: 'products_webinars',
        outcome: 'succeeded'
    },
    VIRTUAL_EVENTS: {
        event: 'button_click',
        current_page: 'jioevents_header',
        clicked_text: 'products_virtualevents',
        outcome: 'succeeded'
    },
    WHITEGLOVE: {
        event: 'button_click',
        current_page: 'jioevents_header',
        clicked_text: 'products_whiteglove',
        outcome: 'succeeded'
    },
    DASHBOARD: {
        event: 'jioevents',
        event_category: 'jioevents_header',
        event_action: 'MyDashboard',
        event_label: 'Success'
    },
    GOTODASHBOARD: {
        event: 'button_click',
        current_page: 'jioevents_header',
        clicked_text: 'go_to_dashboard',
        outcome: 'succeeded'
    },
    PROFILE: {
        event: 'jioevents',
        event_category: 'jioevents_header',
        event_action: 'MyProfile',
        event_label: 'Success'
    },
    EXPLORE_EVENT: {
        event: 'button_click',
        current_page: 'jioevents_header',
        clicked_text: 'explore_events',
        outcome: 'succeeded'
    }
};
export const DASHBOARD_HEADER = {
    JIOEVENTS: {
        event: 'button_click',
        current_page: 'jioevents_dashboard_header',
        clicked_text: 'jioevents',
        outcome: 'succeeded'
    },
    CREATE_EVENT: {
        event: 'button_click',
        current_page: 'jioevents_dashboard_header',
        clicked_text: 'create_event',
        outcome: 'succeeded'
    },
    VIEW_PROFILE: {
        event: 'button_click',
        current_page: 'jioevents_dashboard_header',
        clicked_text: 'view_profile',
        outcome: 'succeeded'
    },
    SIGN_OUT: {
        event: 'sign_out',
        current_page: 'jioevents_dashboard_header',
        outcome: 'succeeded',
        clicked_text: ''
    },
    EDIT_PROFILE: {
        event: 'button_click',
        current_page: 'jioevents_dashboard_header',
        clicked_text: 'edit_profile',
        outcome: 'succeeded'
    },
    ADD_INTERESTS: {
        event: 'add_interests',
        current_page: 'jioevents_dashboard_header',
        clicked_text: 'add_interests',
        outcome: 'select'
    },
    SHARE_EVENT: {
        event: 'button_click',
        current_page: 'jioevents_dashboard_header',
        clicked_text: 'share_event',
        outcome: 'succeeded'
    },
    START_EVENT: {
        event: 'button_click',
        current_page: 'jioevents_dashboard_header',
        clicked_text: 'start_event',
        outcome: 'succeeded'
    },
    MY_PROFILE: {
        event: 'button_click',
        current_page: 'jioevents_dashboard_header',
        clicked_text: 'my_profile',
        outcome: 'succeeded'
    },
    MY_INTERESTS: {
        event: 'button_click',
        current_page: 'jioevents_dashboard_header',
        clicked_text: 'my_interests',
        outcome: 'succeeded'
    },
    CREATE_VIRTUAL: {
        event: 'button_click',
        current_page: 'jioevents_dashboard_header',
        clicked_text: 'virtual_event',
        outcome: 'succeeded'
    },
    CREATE_PRERECORD: {
        event: 'button_click',
        current_page: 'jioevents_dashboard_header',
        clicked_text: 'prerecorded_virtual_event',
        outcome: 'succeeded'
    }
};
export const SIGNIN = {
    PROCEED: {
        event: 'button_click',
        clicked_text: 'proceed',
        current_page: 'sign_in',
        outcome: 'succeeded'
    },
    PRIVACY_POLICY: {
        event: 'button_click',
        clicked_text: 'privacy_policy',
        current_page: 'sign_in',
        outcome: 'succeeded'
    },
    TERMS_OF_USE: {
        event: 'button_click',
        clicked_text: 'terms_of_use',
        current_page: 'sign_in',
        outcome: 'succeeded'
    },
    GOOGLE_LOGO: {
        event: 'sign_in_with_google',
        current_page: 'sign_in',
        outcome: 'succeeded',
        clicked_text: ''
    },
    FACEBOOK_LOGO: {
        event: 'sign_in_with_facebook',
        current_page: 'sign_in',
        outcome: 'succeeded',
        clicked_text: ''
    },
    RESEND_OTP: {
        event: 'sign_in_with_mobile',
        clicked_text: 'resend_otp',
        current_page: 'sign_in',
        outcome: 'succeeded'
    },
    VERIFY_OTP: {
        event: 'sign_in_with_mobile',
        clicked_text: 'verify_otp',
        current_page: 'sign_in',
        outcome: 'succeeded'
    }
};
export const VIRTUALEVENTS = {
    GET_STARTED: {
        event: 'get_started',
        current_page: 'products_virtualevents',
        outcome: 'succeeded',
        clicked_text: ''
    },
    EXPLORE_NOW: {
        event: 'explore_now',
        current_page: 'products_virtualevents',
        outcome: 'succeeded',
        clicked_text: ''
    },
    REGISTRATIONS: {
        event: 'button_click',
        current_page: 'products_virtualevents',
        clicked_text: 'registrations',
        outcome: 'succeeded'
    },
    TICKETING: {
        event: 'button_click',
        current_page: 'products_virtualevents',
        clicked_text: 'ticketing',
        outcome: 'succeeded'
    },
    MEDIASHARING: {
        event: 'button_click',
        current_page: 'products_virtualevents',
        clicked_text: 'hd_mediasharing',
        outcome: 'succeeded'
    },
    POLLS: {
        event: 'button_click',
        current_page: 'products_virtualevents',
        clicked_text: 'polls',
        outcome: 'succeeded'
    },
    QANDA: {
        event: 'button_click',
        current_page: 'products_virtualevents',
        clicked_text: 'qna',
        outcome: 'succeeded'
    },
    CHAT: {
        event: 'button_click',
        current_page: 'products_virtualevents',
        clicked_text: 'chat',
        outcome: 'succeeded'
    },
    COMMENTS: {
        event: 'button_click',
        current_page: 'products_virtualevents',
        clicked_text: 'comments',
        outcome: 'succeeded'
    },
    REACTIONS: {
        event: 'button_click',
        current_page: 'products_virtualevents',
        clicked_text: 'reactions',
        outcome: 'succeeded'
    },
    APPS: {
        event: 'button_click',
        current_page: 'products_virtualevents',
        clicked_text: 'apps',
        outcome: 'succeeded'
    },
    BOOTHS: {
        event: 'button_click',
        current_page: 'products_virtualevents',
        clicked_text: 'booths',
        outcome: 'succeeded'
    },
    LOUNGE: {
        event: 'button_click',
        current_page: 'products_virtualevents',
        clicked_text: 'virtual_lounge',
        outcome: 'succeeded'
    },
    SECURITY: {
        event: 'button_click',
        current_page: 'products_virtualevents',
        clicked_text: 'security',
        outcome: 'succeeded'
    },
    ANALYTICS: {
        event: 'button_click',
        current_page: 'products_virtualevents',
        clicked_text: 'analytics',
        outcome: 'succeeded'
    }
};
export const WEBINARS = {
    GET_STARTED: {
        event: 'get_started',
        current_page: 'products_webinars',
        outcome: 'succeeded',
        clicked_text: ''
    },
    EXPLORE_NOW: {
        event: 'explore_now',
        current_page: 'products_webinars',
        outcome: 'succeeded',
        clicked_text: ''
    }
};
export const WHITEGLOVE = {
    CONTACT_US: {
        event: 'contact_us',
        current_page: 'products_whiteglove',
        outcome: 'succeeded',
        clicked_text: ''
    },
    SUPPORT: {
        event: 'button_click',
        current_page: 'products_whiteglove',
        clicked_text: 'support_large_attendees',
        outcome: 'succeeded'
    },
    LOCKED: {
        event: 'button_click',
        current_page: 'products_whiteglove',
        clicked_text: 'locked_events',
        outcome: 'succeeded'
    },
    ANALYTICS_REPORT: {
        event: 'button_click',
        current_page: 'products_whiteglove',
        clicked_text: 'download_analytics_reports',
        outcome: 'succeeded'
    }
};
export const CUSTOMERS = {
    GET_STARTED: {
        event: 'get_started',
        current_page: 'customers',
        outcome: 'succeeded',
        clicked_text: ''
    },
    GET_STARTED_BOTTOM: {
        event: 'get_started_bottom',
        current_page: 'customers',
        outcome: 'succeeded',
        clicked_text: ''
    },
    TRY_FOR_FREE: {
        event: 'get_started_banner',
        current_page: 'customers',
        outcome: 'succeeded',
        clicked_text: ''
    }
};
export const SOLUTIONS = {
    TRY_FOR_FREE: {
        event: 'get_started',
        current_page: 'solutions',
        outcome: 'succeeded',
        clicked_text: ''
    },
    UNLIMITED: {
        event: 'button_click',
        current_page: 'solutions',
        clicked_text: 'unlimited',
        outcome: 'succeeded'
    },
    POLLS_AND_QA: {
        event: 'button_click',
        current_page: 'solutions',
        clicked_text: 'polls_and_q&a',
        outcome: 'succeeded'
    },
    COMMENTS_AND_REACTIONS: {
        event: 'button_click',
        current_page: 'solutions',
        clicked_text: 'comments_&_custom_reactions',
        outcome: 'succeeded'
    },
    AUTO_RECORDING: {
        event: 'button_click',
        current_page: 'solutions',
        clicked_text: 'auto_recording_of_the_event',
        outcome: 'succeeded'
    },
    RTMP: {
        event: 'button_click',
        current_page: 'solutions',
        clicked_text: 'broadcasting_rtmp_feed',
        outcome: 'succeeded'
    },
    SECURITY: {
        event: 'button_click',
        current_page: 'solutions',
        clicked_text: 'security',
        outcome: 'succeeded'
    },
    BOOTHS: {
        event: 'button_click',
        current_page: 'solutions',
        clicked_text: 'Booths',
        outcome: 'succeeded'
    },
    TICKER: {
        event: 'button_click',
        current_page: 'solutions',
        clicked_text: 'live_ticker_with_hyperlink',
        outcome: 'succeeded'
    },
    CUSTOMISATION: {
        event: 'button_click',
        current_page: 'solutions',
        clicked_text: 'customisation',
        outcome: 'succeeded'
    },
    REGISTRATION: {
        event: 'button_click',
        current_page: 'solutions',
        clicked_text: 'registration',
        outcome: 'succeeded'
    },
    TICKETING: {
        event: 'button_click',
        current_page: 'solutions',
        clicked_text: 'ticketing_and_payments',
        outcome: 'succeeded'
    },
    SPOTLIGHT: {
        event: 'button_click',
        current_page: 'solutions',
        clicked_text: 'spotlight',
        outcome: 'succeeded'
    },
    BRANDING: {
        event: 'button_click',
        current_page: 'solutions',
        clicked_text: 'sponsor_branding_report',
        outcome: 'succeeded'
    },
    BOOTHS_REPORTS: {
        event: 'button_click',
        current_page: 'solutions',
        clicked_text: 'booth_reports',
        outcome: 'succeeded'
    },
    ANALYTICS_REPORTS: {
        event: 'button_click',
        current_page: 'solutions',
        clicked_text: 'analytics_and_reports',
        outcome: 'succeeded'
    },
    EXPLORE_JIOEVENTS: {
        event: 'explore_now',
        current_page: 'solutions',
        outcome: 'succeeded',
        clicked_text: ''
    }
};
export const PRICING = {
    LITE_PLAN: {
        event: '',
        current_page: 'pricing',
        clicked_text: 'lite_contact_sales',
        outcome: 'succeeded'
    },
    STANDARD_PLAN: {
        event: '',
        current_page: 'pricing',
        clicked_text: 'standard_contact_sales',
        outcome: 'succeeded'
    },
    PREMIUM_PLAN: {
        event: '',
        current_page: 'pricing',
        clicked_text: 'premium_contact_sales',
        outcome: 'succeeded'
    },
    CONTACT_SALES: {
        event: 'button_click',
        current_page: 'pricing',
        clicked_text: 'contact_sales',
        outcome: 'succeeded'
    },
    GET_STARTED_FREE: {
        event: 'get_started_bottom',
        current_page: 'pricing',
        outcome: 'succeeded',
        clicked_text: ''
    },
    EXPLORE_NOW: {
        event: 'explore_now',
        current_page: 'pricing',
        outcome: 'succeeded',
        clicked_text: ''
    }
};
export const CONTACTUS = {
    COMPANY_SIZE: {
        event: 'company_size',
        current_page: 'contact_us',
        clicked_text: '',
        outcome: 'succeeded'
    },
    SECTOR: {
        event: 'sector',
        current_page: 'contact_us',
        clicked_text: '',
        outcome: 'succeeded'
    },
    SEND_MESSAGE: {
        event: 'send_message',
        current_page: 'contact_us',
        outcome: 'succeeded',
        clicked_text: ''
    }
};
export const SUPPORT = {
    SUPPORT_CENTRE: {
        event: 'button_click',
        current_page: 'support',
        clicked_text: 'search_our_support_centre',
        outcome: 'succeeded'
    },
    GETTING_STARTED: {
        event: 'button_click',
        current_page: 'support',
        clicked_text: 'getting_started',
        outcome: 'succeeded'
    },
    WHITE_GLOVE_SUPPORT: {
        event: 'button_click',
        current_page: 'support',
        clicked_text: 'white_glove_support',
        outcome: 'succeeded'
    },
    CHAT_WITH_US: {
        event: 'button_click',
        current_page: 'support',
        clicked_text: 'chat_with_us',
        outcome: 'succeeded'
    },
    FAQ: {
        event: 'button_click',
        current_page: 'support',
        clicked_text: 'faq',
        outcome: 'succeeded'
    },
    PRICE_SUBSCRIPTION: {
        event: 'button_click',
        current_page: 'support',
        clicked_text: 'price_&_subscription',
        outcome: 'succeeded'
    },
    TALK_TO_US: {
        event: 'button_click',
        current_page: 'support',
        clicked_text: 'talk_to_us',
        outcome: 'succeeded'
    }
};
export const FOOTER = {
    FOOTER_LOGO: {
        event: 'button_click',
        current_page: 'jioevents_footer',
        clicked_text: 'jioevents',
        outcome: 'succeeded'
    },
    PRODUCTS: {
        event: 'button_click',
        current_page: 'jioevents_footer',
        clicked_text: 'Products',
        outcome: 'succeeded'
    },
    PRICING: {
        event: 'button_click',
        current_page: 'jioevents_footer',
        clicked_text: 'pricing',
        outcome: 'succeeded'
    },
    WHATS_NEW: {
        event: 'button_click',
        current_page: 'jioevents_footer',
        clicked_text: 'whats_new',
        outcome: 'succeeded'
    },
    BLOGS: {
        event: 'button_click',
        current_page: 'jioevents_footer',
        clicked_text: 'blogs',
        outcome: 'succeeded'
    },
    SUPPORT: {
        event: 'button_click',
        current_page: 'jioevents_footer',
        clicked_text: 'support',
        outcome: 'succeeded'
    },
    ABOUT_US: {
        event: 'button_click',
        current_page: 'jioevents_footer',
        clicked_text: 'about_us',
        outcome: 'succeeded'
    },
    PRESS_MEDIA_KIT: {
        event: 'button_click',
        current_page: 'jioevents_footer',
        clicked_text: 'pressnmedia_kit',
        outcome: 'succeeded'
    },
    CONTACT_US: {
        event: 'button_click',
        current_page: 'jioevents_footer',
        clicked_text: 'contact_us',
        outcome: 'succeeded'
    },
    FACEBOOK: {
        event: 'button_click',
        current_page: 'jioevents_footer',
        clicked_text: 'facebook',
        outcome: 'succeeded'
    },
    TWITTER: {
        event: 'button_click',
        current_page: 'jioevents_footer',
        clicked_text: 'twitter',
        outcome: 'succeeded'
    },
    INSTAGRAM: {
        event: 'button_click',
        current_page: 'jioevents_footer',
        clicked_text: 'instagram',
        outcome: 'succeeded'
    },
    YOUTUBE: {
        event: 'button_click',
        current_page: 'jioevents_footer',
        clicked_text: 'youtube',
        outcome: 'succeeded'
    },
    LINKEDIN: {
        event: 'button_click',
        current_page: 'jioevents_footer',
        clicked_text: 'linkedIn',
        outcome: 'succeeded'
    },
    GOOGLE_PLAY: {
        event: 'button_click',
        current_page: 'jioevents_footer',
        clicked_text: 'googleplay',
        outcome: 'succeeded'
    },
    APPLE_STORE: {
        event: 'button_click',
        current_page: 'jioevents_footer',
        clicked_text: 'appstore',
        outcome: 'succeeded'
    },
    REGULATORY: {
        event: 'button_click',
        current_page: 'jioevents_footer',
        clicked_text: 'regulatory',
        outcome: 'succeeded'
    },
    POLICIES: {
        event: 'button_click',
        current_page: 'jioevents_footer',
        clicked_text: 'policies',
        outcome: 'succeeded'
    },
    TERMS_CONDITIONS: {
        event: 'button_click',
        current_page: 'jioevents_footer',
        clicked_text: 'tnc',
        outcome: 'succeeded'
    }
};

export const DASHBOARD = {
    MY_EVENTS: {
        event: 'button_click',
        current_page: 'dashboard',
        clicked_text: 'my_events',
        outcome: 'succeeded'
    },
    EXPLORE_EVENTS: {
        event: 'button_click',
        current_page: 'dashboard',
        clicked_text: 'explore_events',
        outcome: 'succeeded'
    },
    EDIT_EVENT: {
        event: 'button_click',
        current_page: 'dashboard',
        clicked_text: 'edit_event',
        outcome: 'succeeded'
    },
    SHARE_EVENT: {
        event: 'button_click',
        current_page: 'dashboard',
        clicked_text: 'share_event',
        outcome: 'succeeded'
    },
    DELETE_EVENT: {
        event: 'delete_event',
        current_page: 'dashboard',
        outcome: 'succeeded',
        clicked_text: ''
    },
    START_EVENT: {
        event: 'start_event',
        current_page: 'dashboard',
        outcome: 'succeeded',
        clicked_text: ''
    },
    JOIN_EVENT: {
        event: 'join_event',
        current_page: 'dashboard',
        outcome: 'succeeded',
        clicked_text: ''
    },
    PREVIEW_EVENT: {
        event: 'button_click',
        current_page: 'dashboard',
        clicked_text: 'preview_event',
        outcome: 'succeeded'
    },
    PAST_EVENTS: {
        event: 'button_click',
        current_page: 'dashboard',
        clicked_text: 'past_events',
        outcome: 'succeeded'
    },
    DOWNLOAD_QANDA: {
        event: 'button_click',
        current_page: 'dashboard',
        clicked_text: 'download_q&a',
        outcome: 'succeeded'
    },
    DOWNLOAD_COMMENTS: {
        event: 'button_click',
        current_page: 'dashboard',
        clicked_text: 'download_comments',
        outcome: 'succeeded'
    },
    DOWNLOAD_ATTENDANCE_LIST: {
        event: 'button_click',
        current_page: 'dashboard',
        clicked_text: 'download_attendance_list',
        outcome: 'succeeded'
    },
    DOWNLOAD_REGISTRED_ATTENDANCE_LIST: {
        event: 'button_click',
        current_page: 'dashboard',
        clicked_text: 'download_registered_attendees',
        outcome: 'succeeded'
    },
    DOWNLOAD_RECORDINGS: {
        event: 'button_click',
        current_page: 'dashboard',
        clicked_text: 'download_recordings',
        outcome: 'succeeded'
    },
    DOWNLOAD_POLLS_REPORTS: {
        event: 'button_click',
        current_page: 'dashboard',
        clicked_text: 'download_poll_reports',
        outcome: 'succeeded'
    },
    DOWNLOAD_NETWORKING_LOUNGE_REPORTS: {
        event: 'button_click',
        current_page: 'dashboard',
        clicked_text: 'download_networking_lounge_reports',
        outcome: 'succeeded'
    },
    POPUP_SHARE_EVENT: {
        event: 'button_click',
        current_page: 'schedule_event_pop-up',
        clicked_text: 'share_event',
        outcome: 'succeeded'
    },
    POPUP_CUSTOMISE_EVENT: {
        event: 'button_click',
        current_page: 'schedule_event_pop-up',
        clicked_text: 'proceed_to_customise_event',
        outcome: 'succeeded'
    },
    POPUP_CLOSE_EVENT: {
        event: 'button_click',
        current_page: 'schedule_event_pop-up',
        clicked_text: 'Close',
        outcome: 'succeeded'
    },
    SCHEDULE_VIRTUAL: {
        event: 'schedule',
        current_page: 'create_event_pop-up',
        clicked_text: 'virtual_event',
        outcome: 'succeeded'
    },
    SCHEDULE_PRERECORD: {
        event: 'schedule',
        current_page: 'create_event_pop-up',
        clicked_text: 'prerecorded_virtual_event',
        outcome: 'succeeded'
    },
    PRERECORDED_UPLOAD: {
        event: 'button_click',
        current_page: 'create_event_pop-up',
        clicked_text: 'upload_video_for_prerecorded_event',
        outcome: 'succeeded'
    },
    PRERECORDED_VIDEO_SELECT: {
        event: 'button_click',
        current_page: 'create_event_pop-up',
        clicked_text: 'select_past_event_recordings',
        outcome: 'succeeded'
    },
    PRERECORDED_NEXT_BTN: {
        event: 'button_click',
        current_page: 'create_event_pop-up',
        clicked_text: 'next_prerecorded_event',
        outcome: 'succeeded'
    }
};
export const EVENT_SETTINGS = {
    NEXT_BUTTON: {
        event: 'jioevents',
        event_category: 'basic_details',
        event_action: 'Next',
        event_label: 'Success'
    },
    EDIT_EVENTS: {
        event: 'jioevents',
        event_category: 'basic_details',
        event_action: 'edit_event_details',
        event_label: 'Success'
    },
    ADD_MEMBERS: {
        event: 'jioevents',
        event_category: 'teams_and_roles',
        event_action: 'add_members',
        event_label: 'Success'
    },
    DOWNLOAD_LIST: {
        event: 'jioevents',
        event_category: 'teams_and_roles',
        event_action: 'download_list',
        event_label: 'Success'
    },
    SEARCH_MEMBERS: {
        event: 'jioevents',
        event_category: 'teams_and_roles',
        event_action: 'search_team_member',
        event_label: 'Success'
    },
    TEAM_NEXT: {
        event: 'jioevents',
        event_category: 'teams_and_roles',
        event_action: 'Next',
        event_label: 'Success'
    },
    DESCRIPTION_CHECKBOX: {
        event: 'jioevents',
        event_category: 'teams_and_roles',
        event_action: 'description_checkbox',
        event_label: ''
    },
    DELETE_MEMBERS: {
        event: 'jioevents',
        event_category: 'teams_and_roles',
        event_action: 'delete_users',
        event_label: 'Success'
    },
    SELECT_ROLE: {
        event: 'jioevents',
        event_category: 'teams_and_roles',
        event_action: 'select_role',
        event_label: ''
    },
    ADD_MEMBER: {
        event: 'jioevents',
        event_category: 'teams_and_roles',
        event_action: 'add_member',
        event_label: 'Success'
    },
    CANCEL_MEMBER: {
        event: 'jioevents',
        event_category: 'teams_and_roles',
        event_action: 'Cancel',
        event_label: 'Success'
    },
    CLOSE_MEMBER: {
        event: 'jioevents',
        event_category: 'teams_and_roles',
        event_action: 'Close',
        event_label: 'Success'
    },
    PHOTO_UPLOAD: {
        event: 'jioevents',
        event_category: 'teams_and_roles',
        event_action: 'speaker_photo_upload',
        event_label: 'Success'
    },
    REGISTRATION_NEXT: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'Next',
        event_label: 'Success'
    },
    ANYONE_CAN_JOIN: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'anyone_can_join',
        event_label: 'Success'
    },
    ONLY_INVITED_ATTENDEES: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'only_invited_attendees',
        event_label: 'Success'
    },
    ONLY_LOGGEDIN_USE_CAN_JOIN: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'only_loggedin_user_can_join',
        event_label: 'Success'
    },
    ENABLE_REGISTRATION: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'enable_registration_required',
        event_label: 'Success'
    },
    DISABLE_REGISTRATION: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'disable_registration_required',
        event_label: 'Success'
    },
    ENABLE_AUTOMATIC_APPROVAL: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'enable_automatic_approval',
        event_label: 'Success'
    },
    ENABLE_MANUAL_APPROVAL: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'enable_manual_approval',
        event_label: 'Success'
    },
    ENABLE_EMAIL_REQUIRED: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'enable_email_required',
        event_label: 'Success'
    },
    DISABLE_EMAIL_REQUIRED: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'disable_email_required',
        event_label: 'Success'
    },
    ENABLE_MOBILE_NUMBER_REQUIRED: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'enable_mobile_number_required',
        event_label: 'Success'
    },
    DISABLE_MOBILE_NUMBER_REQUIRED: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'disable_mobile_number_required',
        event_label: 'Success'
    },
    ADD_NEW_FIELD: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'add_new_field',
        event_label: 'Success'
    },
    CUSTOM_FIELD: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'create_custom_field',
        event_label: 'Success'
    },
    SAVE: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'save_custom_field',
        event_label: 'Success'
    },
    EDIT_REGISTRATION_FIELD: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'Edit',
        event_label: ''
    },
    REGISTRATION_ADD_ATTENDEES_CLOSE: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'add_attendees',
        event_label: 'Close'
    },
    REGISTRATION_ADD_ATTENDEES_DOWNLOAD_TEMPLATE: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'add_attendees',
        event_label: 'download_template'
    },
    REGISTRATION_ADD_ATTENDEES_UPLOAD: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'add_attendees',
        event_label: 'upload_attendee_list'
    },
    REGISTRATION_ADD_ATTENDEES_INVITE: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'add_attendees',
        event_label: 'save_and_invite'
    },
    ADD_NEW_FIELDS: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'add_new_field',
        event_label: 'Success'
    },
    PREVIEW: {
        event: 'jioevents',
        event_category: 'Registration',
        event_action: 'preview_custom_field',
        event_label: 'Success'
    },
    IN_EVENT_NEXT_BUTTON: {
        event: 'jioevents',
        event_category: 'insession_settings',
        event_action: 'Next',
        event_label: 'Success'
    },
    ENABLE_PEOPLE_TAB: {
        event: 'jioevents',
        event_category: 'additional_settings',
        event_action: 'enable_people_tab',
        event_label: ''
    },
    ENABLE_PAYMENTS: {
        event: 'jioevents',
        event_category: 'additional_settings',
        event_action: 'enable_payments',
        event_label: ''
    },
    ENABLE_SOCIAL_FEED: {
        event: 'jioevents',
        event_category: 'additional_settings',
        event_action: 'enable_social_feed'
    },
    ENABLE_CONTESTS: {
        event: 'jioevents',
        event_category: 'additional_settings',
        event_action: 'enable_contests',
        event_label: ''
    },
    ENABLE_BOOTHS: {
        event: 'jioevents',
        event_category: 'enable_booths_setting',
        event_action: 'enable_booths',
        event_label: ''
    },
    ENABLE_TRANSLATION: {
        event: 'jioevents',
        event_category: 'enable_translation_setting',
        event_action: 'enable_translation',
        event_label: ''
    },
    ENABLE_GAMES_TAB: {
        event: 'jioevents',
        event_category: 'additional_settings',
        event_action: 'enable_games_tab',
        event_label: ''
    },
    ENABLE_COMMENTS: {
        event: 'jioevents',
        event_category: 'insession_settings',
        event_action: 'enable_comments',
        event_label: ''
    },
    ENABLE_LIVECHAT: {
        event: 'jioevents',
        event_category: 'insession_settings',
        event_action: 'enable_livechat',
        event_label: ''
    },
    ENABLE_QANDA: {
        event: 'jioevents',
        event_category: 'insession_settings',
        event_action: 'enable_Q&A',
        event_label: ''
    },
    ENABLE_POLLS: {
        event: 'jioevents',
        event_category: 'insession_settings',
        event_action: 'enable_Polls',
        event_label: ''
    },
    ENABLE_WAITING_ROOM: {
        event: 'jioevents',
        event_category: 'insession_settings',
        event_action: 'enable_waiting_room_for_speaker',
        event_label: ''
    },
    ENABLE_REACTIONS: {
        event: 'jioevents',
        event_category: 'insession_settings',
        event_action: 'enable_reactions',
        event_label: ''
    },
    ENABLE_FLOATING_REACTIONS: {
        event: 'jioevents',
        event_category: 'insession_settings',
        event_action: 'enable_floating_reactions',
        event_label: ''
    },
    ENABLE_REACTIONS_COUNT: {
        event: 'jioevents',
        event_category: 'insession_settings',
        event_action: 'enable_reactions_count',
        event_label: ''
    },
    ENABLE_VIEW_COUNT: {
        event: 'jioevents',
        event_category: 'insession_settings',
        event_action: 'enable_views_count',
        event_label: ''
    },
    ENABLE_TICKER_TEXT: {
        event: 'jioevents',
        event_category: 'insession_settings',
        event_action: 'enable_ticker_text',
        event_label: ''
    },
    HIDE_WATERMARK_SETTING: {
        event: 'jioevents',
        event_category: 'insession_settings',
        event_action: 'hide_watermark',
        event_label: ''
    },
    ENABLE_FLASH_CARD: {
        event: 'jioevents',
        event_category: 'insession_settings',
        event_action: 'enable_flash_card',
        event_label: ''
    },
    ENABLE_EXTERNAL_RECORDING: {
        event: 'jioevents',
        event_category: 'insession_settings',
        event_action: 'enable_external_recording',
        event_label: ''
    },
    ENABLE_HIDE_PAST_SESSIONS: {
        event: 'jioevents',
        event_category: 'insession_settings',
        event_action: 'enable_hide_past_session',
        event_label: ''
    },
    DISABLE_HAND_RAISE: {
        event: 'jioevents',
        event_category: 'insession_settings',
        event_action: 'disable_hand_raise',
        event_label: ''
    },
    VIEW_EDIT: {
        event: 'jioevents',
        event_category: 'insession_settings',
        event_action: 'view_and_edit',
        event_label: 'Success'
    },
    LIVE_STREAMING_NEXT_BUTTON: {
        event: 'jioevents',
        event_category: 'live_streaming',
        event_action: 'Next',
        event_label: 'Success'
    },
    RTMP_YOUTUBE: {
        event: 'jioevents',
        event_category: 'live_streaming',
        event_action: 'Youtube',
        event_label: ''
    },
    RTMP_FACEBOOK: {
        event: 'jioevents',
        event_category: 'live_streaming',
        event_action: 'Facebook',
        event_label: ''
    },
    START: 'started',
    STOP: 'stopped',
    STREAM_IN: {
        event: 'jioevents',
        event_category: 'stream_in',
        event_action: 'generate',
        event_label: ''
    },
    STREAM_IN_REGENERATE: {
        event: 'jioevents',
        event_category: 'stream_in',
        event_action: 'regenerate',
        event_label: ''
    },
    PRESENT_STREAM_IN: {
        event: 'jioevents',
        event_category: 'stream_in',
        event_action: 'present_stream',
        event_label: ''
    },
    STOP_PRESENTING_STREAM_IN: {
        event: 'jioevents',
        event_category: 'stream_in',
        event_action: 'stop_presenting',
        event_label: ''
    },
    CUSTOM_RTMP: {
        event: 'jioevents',
        event_category: 'live_streaming',
        event_action: 'custom_RTMP',
        event_label: 'success'
    },
    EDIT_YOUTUBE: {
        event: 'jioevents',
        event_category: 'live_streaming',
        event_action: 'edit_youtube_live_stream',
        event_label: 'Success'
    },
    DELETE_YOUTUBE: {
        event: 'jioevents',
        event_category: 'live_streaming',
        event_action: 'delete_youtube_live_stream',
        event_label: 'Success'
    },
    EDIT_FACEBOOK: {
        event: 'jioevents',
        event_category: 'live_streaming',
        event_action: 'edit_facebook_live_stream',
        event_label: 'Success'
    },
    DELETE_FACEBOOK: {
        event: 'jioevents',
        event_category: 'live_streaming',
        event_action: 'delete_facebook_live_stream',
        event_label: 'Success'
    },
    EDIT_CUSTOM: {
        event: 'jioevents',
        event_category: 'live_streaming',
        event_action: 'edit_custom_live_stream',
        event_label: 'Success'
    },
    DELETE_CUSTOM: {
        event: 'jioevents',
        event_category: 'live_streaming',
        event_action: 'delete_custom_live_stream',
        event_label: 'Success'
    },
    ADDITIONAL_SETTING_NEXT_BUTTON: {
        event: 'jioevents',
        event_category: 'additional_settings',
        event_action: 'Next',
        event_label: 'Success'
    },
    SPEAKERS_ENTRY_SETTINGS: {
        event: 'jioevents',
        event_category: 'additional_settings',
        event_action: 'speaker_can_join_after_signin',
        event_label: 'Success'
    },
    SPEAKERS_JOIN_WITHOUT_SIGNIN: {
        event: 'jioevents',
        event_category: 'additional_settings',
        event_action: 'speaker_can_join_without_signin',
        event_label: 'Success'
    },
    PERFERRED_RECORDING_LAYOUT: {
        event: 'jioevents',
        event_category: 'additional_settings',
        event_action: 'select_preferred_recording_layout',
        event_label: ''
    },
    WELCOME_PAGE_NEXT_BUTTON: {
        event: 'jioevents',
        event_category: 'welcome_page',
        event_action: 'Next',
        event_label: 'Success'
    },
    BANNER_UPLOAD: {
        event: 'jioevents',
        event_category: 'welcome_page',
        event_action: 'banner_upload',
        event_label: 'Success'
    },
    LOGO_UPLOAD: {
        event: 'jioevents',
        event_category: 'welcome_page',
        event_action: 'logo_upload',
        event_label: 'Success'
    },
    VIEW_IN_BROWSER: {
        event: 'jioevents',
        event_category: 'welcome_page',
        event_action: 'open_view_in_browser',
        event_label: 'Success'
    },
    WELCOME_PAGE_THEME: {
        event: 'jioevents',
        event_category: 'welcome_page',
        event_action: 'Theme',
        event_label: ''
    },
    IN_SESSION_SCREEN_NEXT_BUTTON: {
        event: 'jioevents',
        event_category: 'in-session_screen',
        event_action: 'Next',
        event_label: 'Success'
    },
    WELCOME_SCREEN_UPLOAD: {
        event: 'jioevents',
        event_category: 'in-session_screen',
        event_action: 'welcome_screen_upload',
        event_label: 'Success'
    },
    WATERMARK_UPLOAD: {
        event: 'jioevents',
        event_category: 'in-session_screen',
        event_action: 'watermark_upload',
        event_label: 'Success'
    },
    BACKGROUND_IMAGE_UPLOAD: {
        event: 'jioevents',
        event_category: 'in-session_screen',
        event_action: 'background_image_upload',
        event_label: 'Success'
    },
    OPEN_VIEW_IN_BROWSER: {
        event: 'jioevents',
        event_category: 'in-session_screen',
        event_action: 'open_view_in_browser',
        event_label: 'Success'
    },
    BACKDROP_IMAGE: {
        event: 'jioevents',
        event_category: 'in-session_screen',
        event_action: 'Backdrop',
        event_label: 'None'
    },
    POST_SESSION_SCREEN_NEXT_BUTTON: {
        event: 'jioevents',
        event_category: 'post-session_screen',
        event_action: 'Next',
        event_label: 'Success'
    },
    THANKYOU_BANNER_UPLOAD: {
        event: 'jioevents',
        event_category: 'post-session_screen',
        event_action: 'thankyou_banner_upload',
        event_label: 'Success'
    },
    THANKYOU_BACKGROUND_UPLOAD: {
        event: 'jioevents',
        event_category: 'post-session_screen',
        event_action: 'thankyou_background_upload',
        event_label: 'Success'
    },
    POST_SESSION_OPEN_VIEW_IN_BROWSER: {
        event: 'jioevents',
        event_category: 'post-session_screen',
        event_action: 'open_view_in_browser',
        event_label: 'Success'
    },
    CONTENT_UPLOAD: {
        event: 'jioevents',
        event_category: 'Content',
        event_action: 'Upload',
        event_label: 'Success'
    }
};
export const PREPARATORY = {
    SETTINGS: {
        event: 'settings',
        current_page: 'join_event_preparatory_screen',
        clicked_text: '',
        outcome: 'succeeded'
    },
    VIRTUAL_BACKGROUND: {
        event: 'virtual_background',
        current_page: 'join_event_preparatory_screen',
        clicked_text: '',
        outcome: 'succeeded'
    },
    START_VIDEO: {
        event: 'camera',
        current_page: 'join_event_preparatory_screen',
        clicked_text: 'start_video',
        outcome: 'succeeded'
    },
    STOP_VIDEO: {
        event: 'camera',
        current_page: 'join_event_preparatory_screen',
        clicked_text: 'stop_video',
        outcome: 'succeeded'
    },
    MUTE: {
        event: 'mic',
        current_page: 'join_event_preparatory_screen',
        clicked_text: 'mute',
        outcome: 'succeeded'
    },
    UNMUTE: {
        event: 'mic',
        current_page: 'join_event_preparatory_screen',
        clicked_text: 'unmute',
        outcome: 'succeeded'
    },
    JOIN: {
        event: 'jioevents',
        event_category: 'join_event_preparatory_screen',
        event_action: 'join',
        event_label: 'Success'
    },
    START: {
        event: 'start',
        current_page: 'join_event_preparatory_screen',
        clicked_text: '',
        outcome: 'succeeded'
    },
    TAB_CHANGED: {
        event: 'jioevents',
        event_category: 'join_event_preparatory_screen',
        event_action: '',
        event_label: 'Success'
    },
    MIC_SELECT: {
        event: 'microphone_selected',
        current_page: 'join_event_preparatory_screen',
        clicked_text: 'microphone selected by the user',
        outcome: 'succeeded'
    },
    CAMERA_SELECT: {
        event: 'camera_selected',
        current_page: 'join_event_preparatory_screen',
        clicked_text: 'camera selected by the user',
        outcome: 'succeeded'
    },
    CHANGE_NAME: {
        event: 'change_name',
        current_page: 'join_event_preparatory_screen',
        clicked_text: '',
        outcome: 'succeeded'
    }
};
export const POPUP = {
    SHARE_EVENT: {
        event: 'button_click',
        current_page: 'schedule_event_pop-up',
        clicked_text: 'share_event',
        outcome: 'succeeded'
    },
    PROCEED_TO_CUSTOMERIZE: {
        event: 'button_click',
        current_page: 'schedule_event_pop-up',
        clicked_text: 'proceed_to_customise_event',
        outcome: 'succeeded'
    },
    CLOSE: {
        event: 'button_click',
        current_page: 'schedule_event_pop-up',
        clicked_text: 'close',
        outcome: 'succeeded'
    },
    EVENT_CATEGORY: {
        event: 'event_category',
        current_page: 'create_event_pop-up',
        clicked_text: '',
        outcome: 'succeeded'
    },
    ATTENDEE_COUNT: {
        event: 'expected_attendee_count',
        current_page: 'create_event_pop-up',
        clicked_text: '',
        outcome: 'succeeded'
    },
    SET_START_TIME: {
        event: 'set_start_time',
        current_page: 'create_event_pop-up',
        outcome: 'succeeded',
        clicked_text: ''
    },
    SET_DURATION: {
        event: 'set_duration',
        current_page: 'create_event_pop-up',
        outcome: 'succeeded',
        clicked_text: ''
    },
    SCHEDULE_EVENT: {
        event: 'schedule',
        current_page: 'create_event_pop-up',
        outcome: 'succeeded',
        clicked_text: ''
    },
    COPY_ATTENDEE_LINK: {
        event: 'button_click',
        current_page: 'share_event_popup',
        clicked_text: 'copy_attendee_link',
        outcome: 'succeeded'
    },
    COPY_SPEAKER_LINK: {
        event: 'button_click',
        current_page: 'share_event_popup',
        clicked_text: 'copy_speaker_link',
        outcome: 'succeeded'
    },
    FACEBOOK: {
        event: 'button_click',
        current_page: 'share_event_popup',
        clicked_text: 'share_on_facebook',
        outcome: 'succeeded'
    },
    TWITTER: {
        event: 'button_click',
        current_page: 'share_event_popup',
        clicked_text: 'share_on_twitter',
        outcome: 'succeeded'
    },
    LINKEDIN: {
        event: 'button_click',
        current_page: 'share_event_popup',
        clicked_text: 'share_on_linkedin',
        outcome: 'succeeded'
    },
    WHATSAPP: {
        event: 'button_click',
        current_page: 'share_event_popup',
        clicked_text: 'share_on_whatsapp',
        outcome: 'succeeded'
    },
    EMAIL: {
        event: 'button_click',
        current_page: 'share_event_popup',
        clicked_text: 'share_on_email',
        outcome: 'succeeded'
    }
};
export const FEEDBACK = {
    CLOSE: {
        event: 'button_click',
        current_page: 'feedback',
        clicked_text: 'close',
        outcome: 'succeeded'
    },
    SKIP: {
        event: 'button_click',
        current_page: 'feedback',
        clicked_text: 'skip',
        outcome: 'succeeded'
    },
    SUBMIT: {
        event: 'submit',
        current_page: 'feedback',
        clicked_text: '',
        outcome: 'succeeded'
    },
    FEEDBACK_REASON: {
        event: 'button_click',
        current_page: 'feedback',
        clicked_text: '',
        outcome: ''
    }
};

export const INSIDE_EVENT = {
    MEETING_INFO: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'meeting_information_icon',
        outcome: 'succeeded'
    },
    VIEWS_AND_REACTIONS: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'views_and_reactions',
        outcome: 'succeeded'
    },
    BROADCAST: {
        event: 'broadcast',
        current_page: 'inside_event',
        outcome: 'succeeded',
        clicked_text: ''
    },
    SPEAKER_LINK: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'copy_speaker_link',
        outcome: 'succeeded'
    },
    SPEAKER_INVITE: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'copy_speaker_invitation',
        outcome: 'succeeded'
    },
    ATTENDEE_LINK: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'copy_attendee_link',
        outcome: 'succeeded'
    },
    ATTENDEE_INVITE: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'copy_attendee_invitation',
        outcome: 'succeeded'
    },
    FULLSCREEN_ON: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'full_screen_mode',
        outcome: 'succeeded'
    },
    FULLSCREEN_OFF: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'exit_full_screen',
        outcome: 'succeeded'
    },
    AUDIO_MUTE: {
        event: 'mic',
        current_page: 'inside_event',
        clicked_text: 'mute',
        outcome: 'succeeded'
    },
    AUDIO_UNMUTE: {
        event: 'mic',
        current_page: 'inside_event',
        clicked_text: 'unmute',
        outcome: 'succeeded'
    },
    AUDIO_SETTINGS: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'audio_settings',
        outcome: 'succeeded'
    },
    VIDEO_ON: {
        event: 'camera',
        current_page: 'inside_event',
        clicked_text: 'start_video',
        outcome: 'succeeded'
    },
    VIDEO_OFF: {
        event: 'camera',
        current_page: 'inside_event',
        clicked_text: 'stop_video',
        outcome: 'succeeded'
    },
    CHANGE_VB: {
        event: 'virtual_background',
        current_page: 'inside_event',
        outcome: 'succeeded',
        clicked_text: ''
    },
    VIDEO_SETTINGS: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'video_settings',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL: {
        event: 'participant_panel',
        current_page: 'inside_event',
        clicked_text: 'participant_panel',
        outcome: 'succeeded'
    },
    CHAT: {
        event: 'chat',
        current_page: 'inside_event',
        clicked_text: 'chat',
        outcome: 'succeeded'
    },
    COMMENTS: {
        event: 'comments',
        current_page: 'inside_event',
        clicked_text: 'comments',
        outcome: 'succeeded'
    },
    Q_AND_A: {
        event: 'qna',
        current_page: 'inside_event',
        clicked_text: 'qna',
        outcome: 'succeeded'
    },
    CLOSE_CHAT: {
        event: 'chat',
        current_page: 'inside_event',
        clicked_text: 'close_chat',
        outcome: 'succeeded'
    },
    POP_OUT_CHAT: {
        event: 'chat',
        current_page: 'inside_event',
        clicked_text: 'pop_out_chat',
        outcome: 'succeeded'
    },
    FORMAT_CHAT_TEXT: {
        event: 'chat',
        current_page: 'inside_event',
        clicked_text: 'format_chat_text',
        outcome: 'succeeded'
    },
    EMOJI: {
        event: 'chat',
        current_page: 'inside_event',
        clicked_text: 'emoji',
        outcome: 'succeeded'
    },
    SEND_MESSAGE_CHAT: {
        event: 'chat',
        current_page: 'inside_event',
        clicked_text: 'send_message_in_chat',
        outcome: 'succeeded'
    },
    OPEN_QNA: {
        event: 'qna',
        current_page: 'inside_event',
        clicked_text: 'open_qna',
        outcome: 'succeeded'
    },
    ANSWERED_QNA: {
        event: 'qna',
        current_page: 'inside_event',
        clicked_text: 'answered_qna',
        outcome: 'succeeded'
    },
    DISMISSED_QNA: {
        event: 'qna',
        current_page: 'inside_event',
        clicked_text: 'dismissed_qna',
        outcome: 'succeeded'
    },
    TIME_TO_ANSWER_QNS: {
        event: 'qna',
        current_page: 'inside_event',
        clicked_text: 'time_limit_for_questions_asked',
        outcome: 'succeeded'
    },
    SHARE_SCREEN_START: {
        event: 'screenshare',
        current_page: 'inside_event',
        clicked_text: 'start_screen_share',
        outcome: 'succeeded'
    },
    SHARE_CLOUD_PLAYER_START: {
        event: 'screenshare',
        current_page: 'inside_event',
        clicked_text: 'start_cloud_player_share',
        outcome: 'succeeded'
    },
    SHARE_SCREEN_STOP: {
        event: 'screenshare',
        current_page: 'inside_event',
        clicked_text: 'stop_screen_share',
        outcome: 'succeeded'
    },
    SHARE_CLOUD_PLAYER_STOP: {
        event: 'screenshare',
        current_page: 'inside_event',
        clicked_text: 'stop_cloud_player_share',
        outcome: 'succeeded'
    },
    SHARE_SYSTEM_AUDIO: {
        event: 'screenshare',
        current_page: 'inside_event',
        clicked_text: 'share_system_audio',
        outcome: 'succeeded'
    },
    REACTIONS: {
        event: 'reactions',
        current_page: 'inside_event',
        clicked_text: 'reactions',
        outcome: 'succeeded'
    },
    RAISE_HAND: {
        event: 'handraise',
        current_page: 'inside_event',
        clicked_text: 'raise_hand',
        outcome: 'succeeded'
    },
    LOWER_HAND: {
        event: 'handraise',
        current_page: 'inside_event',
        clicked_text: 'lower_hand',
        outcome: 'succeeded'
    },
    MORE_OPTIONS: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'more',
        outcome: 'succeeded'
    },
    POLLS: {
        event: 'polls',
        current_page: 'inside_event',
        clicked_text: 'polls',
        outcome: 'succeeded'
    },
    SET_TIMER: {
        event: 'timer',
        current_page: 'inside_event',
        clicked_text: 'set_timer',
        outcome: 'succeeded'
    },
    MANAGE_TICKER: {
        event: 'ticker',
        current_page: 'inside_event',
        clicked_text: 'manage_ticker',
        outcome: 'succeeded'
    },
    LIVE_ON_YOUTUBE: {
        event: 'live_stream',
        current_page: 'inside_event',
        clicked_text: 'live_on_youtube',
        outcome: 'succeeded'
    },
    LIVE_ON_FACEBOOK: {
        event: 'live_stream',
        current_page: 'inside_event',
        clicked_text: 'live_on_facebook',
        outcome: 'succeeded'
    },
    LIVE_ON_CUSTOM_RTMP: {
        event: 'live_stream',
        current_page: 'inside_event',
        clicked_text: 'live_on_custom_rtmp',
        outcome: 'succeeded'
    },
    LEAVE_EVENT: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'leave_event',
        outcome: 'succeeded'
    },
    END_EVENT: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'end_event',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_TAB: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'participant_panel',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_TAB_ON_STAGE: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'on_stage',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_TAB_OFF_STAGE: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'off_stage',
        outcome: 'succeeded'
    },
    ATTENDEES_TAB: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'attendees',
        outcome: 'succeeded'
    },
    ADD_PARTICIPANTS: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'add_participants',
        outcome: 'succeeded'
    },
    FORCE_MUTE_ALL: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'force_mute_all',
        outcome: 'succeeded'
    },
    MUTE_ALL: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'mute_all',
        outcome: 'succeeded'
    },
    UNDO_FORCE_MUTE: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'undo_force_mute',
        outcome: 'succeeded'
    },
    PLAY_ENTRY_EXIT_CHIME: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'play_entry-exit_chime',
        outcome: 'succeeded'
    },
    LOWER_ALL_HANDS: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'lower_all_hands',
        outcome: 'succeeded'
    },
    LOCK_EVENT: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'lock_event',
        outcome: 'succeeded'
    },
    UNLOCK_EVENT: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'unlock_event',
        outcome: 'succeeded'
    },
    SHARE_EVENT: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'share_event',
        outcome: 'succeeded'
    },
    VIEW_SPOTLIGHT: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'view_spotlight_participants',
        outcome: 'succeeded'
    },
    FILTER_BY_NAME: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'filter_by_name',
        outcome: 'succeeded'
    },
    PARTICIPANT_SORT_BY_NAME_ASC: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'participant_sort_by_name(a-z)',
        outcome: 'succeeded'
    },
    PARTICIPANT_SORT_BY_NAME_DSC: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'participant_sort_by_name(z-a)',
        outcome: 'succeeded'
    },
    SORT_BY_CAMERA_STATUS: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'sort_by_camera_status',
        outcome: 'succeeded'
    },
    SORT_BY_MIC_STATUS: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'sort_by_mic_status',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_MUTE: {
        event: 'mic',
        current_page: 'participant_panel',
        clicked_text: 'mute',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_UNMUTE: {
        event: 'mic',
        current_page: 'participant_panel',
        clicked_text: 'unmute',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_START_VIDEO: {
        event: 'camera',
        current_page: 'participant_panel',
        clicked_text: 'start_video',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_STOP_VIDEO: {
        event: 'camera',
        current_page: 'participant_panel',
        clicked_text: 'stop_video',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_RAISE_HAND: {
        event: 'handraise',
        current_page: 'participant_panel',
        clicked_text: 'raise_hand',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_LOWER_HAND: {
        event: 'handraise',
        current_page: 'participant_panel',
        clicked_text: 'lower_hand',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_SPOTLIGHT: {
        event: 'participant_spotlight',
        current_page: 'participant_panel',
        clicked_text: 'spotlight',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_SPOTLIGHT_REMOVE: {
        event: 'participant_spotlight',
        current_page: 'participant_panel',
        clicked_text: 'remove_spotlight',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_ASK_FOR_UNMUTE: {
        event: 'mic',
        current_page: 'participant_panel',
        clicked_text: 'ask_for_unmute',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_ASK_FOR_VIDEO: {
        event: 'camera',
        current_page: 'participant_panel',
        clicked_text: 'ask_for_video',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_MAKE_CO_HOST: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'make_co-host',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_REMOVE_CO_HOST: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'remove_co_host',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_MOVE_TO_AUDIENCE: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'move_off_stage',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_REMOVE_PARTICIPANT: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'remove_from_on_stage',
        outcome: 'succeeded'
    },
    PARTICIPANT_SPOTLIGHT_ONSCREEN: {
        event: 'participant_spotlight',
        current_page: 'participant_panel',
        clicked_text: 'spotlight_onscreen',
        outcome: 'succeeded'
    },
    PARTICIPANT_SPOTLIGHT_ONSCREEN_REMOVE: {
        event: 'participant_spotlight',
        current_page: 'participant_panel',
        clicked_text: 'remove_spotlight_onscreen',
        outcome: 'succeeded'
    },
    PARTICIPANT_ASK_FOR_UNMUTE_ONSCREEN: {
        event: 'mic',
        current_page: 'participant_panel',
        clicked_text: 'ask_for_unmute_onscreen',
        outcome: 'succeeded'
    },
    PARTICIPANT_MUTE_ONSCREEN: {
        event: 'mic',
        current_page: 'participant_panel',
        clicked_text: 'mute_onscreen',
        outcome: 'succeeded'
    },
    VIEW_ALL_AUDIENCE: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'view_all_audience',
        outcome: 'succeeded'
    },
    SEARCH_AUDIENCE: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'search_off_stage',
        outcome: 'succeeded'
    },
    COLLAPSE: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'collapse',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_AUDIENCE_MOVE_TO_PARTICIPANT: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'move_on_stage',
        outcome: 'succeeded'
    },
    PARTICIPANT_PANEL_AUDIENCE_REMOVE: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'remove_from_off_stage',
        outcome: 'succeeded'
    },
    SEARCH_ATTENDEES: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'search_attendees',
        outcome: 'succeeded'
    },
    ATTENDEES_SORT_BY_NAME_ASC: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'attendees_sort_by_name(a-z)',
        outcome: 'succeeded'
    },
    ATTENDEES_SORT_BY_NAME_DSC: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'attendees_sort_by_name(z-a)',
        outcome: 'succeeded'
    },
    DOWNLOAD_ATTENDEES_LIST: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'download_attendees_list',
        outcome: 'succeeded'
    },
    VIEWS_AND_REACTIONS_ATTENDEE: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'views_and_reactions',
        outcome: 'succeeded'
    },
    FULL_SCREEN_MODE: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'full_screen_mode',
        outcome: 'succeeded'
    },
    EXIT_FULL_SCREEN: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'exit_full_screen',
        outcome: 'succeeded'
    },
    ATTENDEES_REACTIONS: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'reactions',
        outcome: 'succeeded'
    },
    ATTENDEES_COMMENT: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'comment',
        outcome: 'succeeded'
    },
    ATTENDEES_QNA: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'qna',
        outcome: 'succeeded'
    },
    ATTENDEES_POLLS: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'polls',
        outcome: 'succeeded'
    },
    ATTENDEES_LEAVE_EVENT: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'leave_event',
        outcome: 'succeeded'
    },
    ATTENDEES_SEND_COMMENT: {
        event: 'comments',
        current_page: 'inside_event',
        clicked_text: 'send_comment',
        outcome: 'succeeded'
    },
    ATTENDEES_ASK_QUESTION: {
        event: 'qna',
        current_page: 'inside_event',
        clicked_text: 'ask_question',
        outcome: 'succeeded'
    },
    ENABLAE_TICKER: {
        event: 'ticker',
        current_page: 'inside_event',
        clicked_text: 'enable_ticker',
        outcome: 'succeeded'
    },
    DISABLE_TICKER: {
        event: 'ticker',
        current_page: 'inside_event',
        clicked_text: 'disable_ticker',
        outcome: 'succeeded'
    },
    PUBLISH_TICKER: {
        event: 'ticker',
        current_page: 'inside_event',
        clicked_text: 'publish_ticker',
        outcome: 'succeeded'
    },
    REMOVE_TICKER: {
        event: 'ticker',
        current_page: 'inside_event',
        clicked_text: 'remove_ticker',
        outcome: 'succeeded'
    },
    LIVE_ON_YOUTUBE_POPUP: {
        event: 'live_stream_youtube',
        current_page: 'inside_event',
        clicked_text: 'youtube_live_stream',
        outcome: 'succeeded'
    },
    LIVE_ON_FACEBOOK_POPUP: {
        event: 'live_stream_facebook',
        current_page: 'inside_event',
        clicked_text: 'facebook_live_stream',
        outcome: 'succeeded'
    },
    LIVE_ON_CUSTOM_RTMP_POPUP: {
        event: 'live_stream_custom',
        current_page: 'inside_event',
        clicked_text: 'custom_rtmp_live_stream',
        outcome: 'succeeded'
    },
    CREATE_POLL: {
        event: 'polls',
        current_page: 'inside_event',
        clicked_text: 'create_a_poll',
        outcome: 'succeeded'
    },
    POLL_SINGLE_CHOICE: {
        event: 'polls',
        current_page: 'inside_event',
        clicked_text: 'question_type_single_choice',
        outcome: 'succeeded'
    },
    POLL_MULTIPE_CHOICE: {
        event: 'polls',
        current_page: 'inside_event',
        clicked_text: 'question_type_multipe_choice',
        outcome: 'succeeded'
    },
    POLL_ADD_OPTION: {
        event: 'polls',
        current_page: 'inside_event',
        clicked_text: 'add_an_option',
        outcome: 'succeeded'
    },
    POLL_DELETE_OPTION: {
        event: 'polls',
        current_page: 'inside_event',
        clicked_text: 'delete_option',
        outcome: 'succeeded'
    },
    POLL_RESULT_IN_REALTIME: {
        event: 'polls',
        current_page: 'inside_event',
        clicked_text: 'show_poll_results_realtime',
        outcome: 'succeeded'
    },
    POLL_RESULT_IN_END: {
        event: 'polls',
        current_page: 'inside_event',
        clicked_text: 'show_results_after_poll_ended',
        outcome: 'succeeded'
    },
    POLL_RESULT_NOT_SHOW: {
        event: 'polls',
        current_page: 'inside_event',
        clicked_text: 'dont_show_poll_results',
        outcome: 'succeeded'
    },
    POLL_SAVE_AS_DRAFT: {
        event: 'polls',
        current_page: 'inside_event',
        clicked_text: 'save_as_draft',
        outcome: 'succeeded'
    },
    POLL_PUBLISH: {
        event: 'polls',
        current_page: 'inside_event',
        clicked_text: 'publish',
        outcome: 'succeeded'
    },
    TIMER_SET: {
        event: 'timer',
        current_page: 'inside_event',
        clicked_text: 'set',
        outcome: 'succeeded'
    },
    TIMER_STOP: {
        event: 'timer',
        current_page: 'inside_event',
        clicked_text: 'stop',
        outcome: 'succeeded'
    },
    TIMER_CLOSE: {
        event: 'timer',
        current_page: 'inside_event',
        clicked_text: 'close',
        outcome: 'succeeded'
    },
    CALL_AGAIN: {
        event: 'button_click',
        current_page: 'participant_panel',
        clicked_text: 'call_again',
        outcome: 'succeeded'
    },
    SET_TIMER_CLOSE: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'close',
        outcome: 'succeeded'
    },
    SET_TIMER_SET: {
        event: 'button_click',
        current_page: 'inside_event',
        clicked_text: 'set',
        outcome: 'succeeded'
    },
    SET_TIMER_STOP: {
        event: 'timer',
        current_page: 'inside_event',
        clicked_text: 'stop',
        outcome: 'succeeded'
    },
    POLLS_SORT: {
        event: 'polls',
        current_page: 'inside_event',
        clicked_text: 'sort_polls',
        outcome: 'succeeded'
    },
    SUBMIT_POLL: {
        event: 'polls',
        current_page: 'inside_event',
        clicked_text: 'submit_poll',
        outcome: 'succeeded'
    },
    END_POLL: {
        event: 'polls',
        current_page: 'inside_event',
        clicked_text: 'end_poll',
        outcome: 'succeeded'
    },
    DELETE_POLL: {
        event: 'polls',
        current_page: 'inside_event',
        clicked_text: 'delete_poll',
        outcome: 'succeeded'
    },
    EDIT_POLL: {
        event: 'polls',
        current_page: 'inside_event',
        clicked_text: 'edit_poll',
        outcome: 'succeeded'
    },
    PUBLISH_DRAFT_POLL: {
        event: 'polls',
        current_page: 'inside_event',
        clicked_text: 'publish_poll_through_draft',
        outcome: 'succeeded'
    },
    DELET_DRAFT_POLL: {
        event: 'polls',
        current_page: 'inside_event',
        clicked_text: 'delete_poll_through_draft',
        outcome: 'succeeded'
    },
    LIVE_STREAMING_POP_UP: {
        event: 'live_stream',
        current_page: 'inside_event',
        clicked_text: 'delete_poll_through_draft',
        outcome: 'succeeded'
    }
};

export const ATTENDEE_LANDING = {
    JOIN_BUTTON: {
        event: 'join',
        current_page: 'attendee_landing_page',
        clicked_text: '',
        outcome: 'succeeded',
        meeting_id: ''
    },
    ADD_TO_CALENDER: {
        event: 'button_click',
        current_page: 'attendee_landing_page',
        clicked_text: 'add_to_calendar',
        outcome: 'succeeded'
    },
    SHARE_ON_FACEBOOK: {
        event: 'button_click',
        current_page: 'attendee_landing_page',
        clicked_text: 'share_on_facebook',
        outcome: 'succeeded'
    },
    SHARE_ON_TWITTER: {
        event: 'button_click',
        current_page: 'attendee_landing_page',
        clicked_text: 'share_on_twitter',
        outcome: 'succeeded'
    },
    SHARE_ON_LINKEDIN: {
        event: 'button_click',
        current_page: 'attendee_landing_page',
        clicked_text: 'share_on_linkedin',
        outcome: 'succeeded'
    },
    ENTER_EVENT: {
        event: 'enter_event',
        clicked_text: '',
        current_page: 'attendee_landing_page',
        outcome: 'succeeded',
        meeting_id: ''
    },
    HEADER_TABS: {
        event: 'button_click',
        clicked_text: '',
        current_page: 'join_event_header_page',
        outcome: 'succeeded',
        meeting_id: ''
    }
};

export const KNOW_YOUR_COLLEAGUE = {
    VIEW_PROFILE: {
        event: 'button_click',
        clicked_text: 'view_profile',
        current_page: 'know_your_colleague_page',
        outcome: 'succeeded',
        meeting_id: '',
        colleague_id: ''
    },
    PROFILE_LOADED: {
        event: 'pageload',
        current_page: 'view_colleague_profile_page',
        outcome: 'succeeded',
        meeting_id: '',
        colleague_id: ''
    },
    LINKEDIN: {
        event: 'button_click',
        clicked_text: 'share_linkedin',
        current_page: 'view_colleague_profile_page',
        outcome: 'succeeded',
        meeting_id: '',
        colleague_id: ''
    }
};

export const SESSIONS = {
    ENABLE_SESSION: {
        event: 'jioevents',
        event_category: 'sessions',
        event_action: 'enable_session',
        event_label: 'success'
    },
    NEW_SESSION: {
        event: 'jioevents',
        event_category: 'sessions',
        event_action: 'new_session',
        event_label: ''
    },
    NEXT: {
        event: 'jioevents',
        event_category: 'sessions',
        event_action: 'next',
        event_label: 'success'
    },
    NEW_SESSION_CALENDAR: {
        event: 'jioevents',
        event_category: 'sessions',
        event_action: 'new_session_calendar',
        event_label: 'success'
    },
    ASSIGN_CO_HOST: {
        event: 'jioevents',
        event_category: 'sessions',
        event_action: 'assign_co-host',
        event_label: 'success'
    },
    ASSIGN_SPEAKER: {
        event: 'jioevents',
        event_category: 'sessions',
        event_action: 'assign_speaker',
        event_label: 'success'
    },
    REMOVE_CO_HOST: {
        event: 'jioevents',
        event_category: 'sessions',
        event_action: 'remove_co-host',
        event_label: 'success'
    },
    REMOVE_SPEAKER: {
        event: 'jioevents',
        event_category: 'sessions',
        event_action: 'remove_speaker',
        event_label: 'success'
    },
    ADD_MEMBERS: {
        event: 'jioevents',
        event_category: 'session',
        event_action: 'add_members',
        event_label: 'success'
    },
    VIEW_DESCRIPTION: {
        event: 'jioevents',
        event_category: 'sessions',
        event_action: 'view_description',
        event_label: 'success'
    },
    VIEW_SPEAKERS: {
        event: 'jioevents',
        event_category: 'sessions',
        event_action: 'view_speakers',
        event_label: 'success'
    },
    VIEW_CO_HOSTS: {
        event: 'jioevents',
        event_category: 'sessions',
        event_action: 'view_co-hosts',
        event_label: 'success'
    },
    EDIT_SESSION: {
        event: 'jioevents',
        event_category: 'sessions',
        event_action: 'edit_session',
        event_label: ''
    },
    NEW_BREAK: {
        event: 'jioevents',
        event_category: 'sessions',
        event_action: 'new_break',
        event_label: ''
    },
    NEW_BREAK_CALENDAR: {
        event: 'jioevents',
        event_category: 'sessions',
        event_action: 'new_break_calendar',
        event_label: 'success'
    },
    BREAK_TEMPLATES: {
        event: 'jioevents',
        event_category: 'sessions',
        event_action: 'break_templates',
        event_label: ''
    },
    OPEN_VIEW_IN_BROWSER: {
        event: 'jioevents',
        event_category: 'sessions',
        event_action: 'open_view_in_browser',
        event_label: 'success'
    },
    EDIT_BREAK: {
        event: 'jioevents',
        event_category: 'sessions',
        event_action: 'edit_break',
        event_label: ''
    },
    REMOVE_BREAK: {
        event: 'jioevents',
        event_category: 'sessions',
        event_action: 'remove_break',
        event_label: ''
    },
    REMOVE_SESSION: {
        event: 'jioevents',
        event_category: 'sessions',
        event_action: 'remove_session',
        event_label: ''
    }
};
export const SPONSORSHIPS = {
    NEXT: {
        event: 'jioevents',
        event_category: 'sponsorships',
        event_action: 'next',
        event_label: 'success'
    },
    PREVIEW_DESKTOP: {
        event: 'jioevents',
        event_category: 'sponsorships',
        event_action: 'preview_desktop',
        event_label: 'success'
    },
    PREVIEW_MOBILE: {
        event: 'jioevents',
        event_category: 'sponsorships',
        event_action: 'preview_mobile',
        event_label: 'success'
    },
    ADD_SPONSOR: {
        event: 'jioevents',
        event_category: 'sponsorships',
        event_action: 'add_sponsor',
        event_label: 'success'
    },
    EDIT_SPONSOR: {
        event: 'jioevents',
        event_category: 'sponsorships',
        event_action: 'edit_sponsor',
        event_label: 'success'
    },
    DELETE_SPONSOR: {
        event: 'jioevents',
        event_category: 'sponsorships',
        event_action: 'delete_sponsor',
        event_label: 'success'
    },
    EDIT_TITLE1: {
        event: 'jioevents',
        event_category: 'sponsorships',
        event_action: 'edit_title_tab1',
        event_label: 'success'
    },
    EDIT_TITLE2: {
        event: 'jioevents',
        event_category: 'sponsorships',
        event_action: 'edit_title_tab2',
        event_label: 'success'
    },
    EDIT_TITLE3: {
        event: 'jioevents',
        event_category: 'sponsorships',
        event_action: 'edit_title_tab3',
        event_label: 'success'
    },
    CLOSE: {
        event: 'jioevents',
        event_category: 'sponsorships',
        event_action: 'close',
        event_label: 'success'
    },
    UPLOAD: {
        event: 'jioevents',
        event_category: 'sponsorships',
        event_action: 'upload_sponsor_logo',
        event_label: 'success'
    },
    DELETE_LOGO: {
        event: 'jioevents',
        event_category: 'sponsorships',
        event_action: 'delete_logo',
        event_label: 'success'
    },
    CONFIRM_ADD_SPONSORS: {
        event: 'jioevents',
        event_category: 'sponsorships',
        event_action: 'confirm_add_sponsor',
        event_label: 'success'
    }
};
export const NETWORKING_LOUNGE = {
    NEXT: {
        event: 'jioevents',
        event_category: 'networking_lounge',
        event_action: 'next',
        event_label: 'success'
    },
    ENABLE_NETWORKING_ROOM: {
        event: 'jioevents',
        event_category: 'networking_lounge',
        event_action: 'enable_networking_lounge',
        event_label: 'success'
    },
    DISABLE_NETWORKING_LOUNGE: {
        event: 'jioevents',
        event_category: 'networking_lounge',
        event_action: 'disable_networking_lounge',
        event_label: 'success'
    },
    CREATE_ROOM: {
        event: 'button_click',
        current_page: 'preparatory_screen_networking_lounge',
        clicked_text: 'create_room',
        outcome: 'succeeded'
    },
    UPLOAD_DISPLAY_IMAGE: {
        event: 'button_click',
        current_page: 'preparatory_screen_networking_lounge',
        clicked_text: 'upload_display_image',
        outcome: 'succeeded'
    },
    CONFIRM_CREATE_ROOM: {
        event: 'create_room',
        current_page: 'preparatory_screen_networking_lounge',
        clicked_text: '',
        outcome: 'succeeded'
    },
    DELETE_ALL_ROOMS: {
        event: 'jioevents',
        event_category: 'networking_lounge',
        event_action: 'delete_all_rooms',
        event_label: ''
    },
    EDIT_ROOM: {
        event: 'button_click',
        current_page: 'preparatory_screen_networking_lounge',
        clicked_text: 'edit_room',
        outcome: 'save_changes'
    },
    DELETE_ROOM: {
        event: 'delete_room',
        current_page: 'preparatory_screen_networking_lounge',
        clicked_text: '',
        outcome: 'delete'
    },
    DUPLICATE_ROOM: {
        event: 'duplicate_room',
        current_page: 'preparatory_screen_networking_lounge',
        clicked_text: 'duplicate_room',
        outcome: 'succeeded'
    },
    FILTER_ALL_ROOMS: {
        event: 'button_click',
        clicked_text: 'filter_all_rooms',
        current_page: 'preparatory_screen_networking_lounge',
        outcome: 'succeeded'
    },
    RESPECTIVE_ALL_ROOMS: {
        event: 'button_click',
        clicked_text: 'respective_all_rooms',
        current_page: 'preparatory_screen_networking_lounge',
        outcome: 'succeeded'
    },
    CONFIRM_CLOSE_ALL_ROOMS: {
        event: 'close_all_rooms',
        clicked_text: '',
        current_page: 'preparatory_screen_networking_lounge',
        outcome: 'confirm'
    },
    CANCEL_CLOSE_ALL_ROOMS: {
        event: 'close_all_rooms',
        clicked_text: '',
        current_page: 'preparatory_screen_networking_lounge',
        outcome: 'cancel'
    },
    ACCEPT_OPEN_ALL_ROOMS: {
        event: 'open_all_rooms',
        clicked_text: '',
        current_page: 'preparatory_screen_networking_lounge',
        outcome: 'accept'
    },
    DECLINE_OPEN_ALL_ROOMS: {
        event: 'open_all_rooms',
        clicked_text: '',
        current_page: 'preparatory_screen_networking_lounge',
        outcome: 'decline'
    },
    ADD_PARTICIPANTS: {
        event: 'button_click',
        clicked_text: 'add_participants',
        current_page: 'preparatory_screen_networking_lounge',
        outcome: 'succeeded'
    },
    CONFIRM_ADD_PARTICIPANTS: {
        event: 'add_participants',
        clicked_text: '',
        current_page: 'preparatory_screen_networking_lounge',
        outcome: 'succeeded'
    },
    SEARCH_PARTICIPANTS: {
        event: 'button_click',
        clicked_text: 'search_speaker',
        current_page: 'preparatory_screen_networking_lounge',
        outcome: 'succeeded'
    },
    ENTER_ROOM: {
        event: 'enter_room',
        clicked_text: '',
        current_page: 'preparatory_screen_networking_lounge',
        outcome: 'succeeded'
    },
    MUTE: {
        event: 'mic',
        current_page: 'networking_room_preparatory_screen',
        clicked_text: 'mute',
        outcome: 'succeeded'
    },
    UNMUTE: {
        event: 'mic',
        current_page: 'networking_room_preparatory_screen',
        clicked_text: 'unmute',
        outcome: 'succeeded'
    },
    START_VIDEO: {
        event: 'camera',
        current_page: 'networking_room_preparatory_screen',
        clicked_text: 'start_video',
        outcome: 'succeeded'
    },
    STOP_VIDEO: {
        event: 'camera',
        current_page: 'networking_room_preparatory_screen',
        clicked_text: 'stop_video',
        outcome: 'succeeded'
    },
    SETTINGS: {
        event: 'settings',
        current_page: 'networking_room_preparatory_screen',
        clicked_text: '',
        outcome: 'succeeded'
    },
    VIRTUAL_BACKGROUND: {
        event: 'virtual_background',
        current_page: 'networking_room_preparatory_screen',
        clicked_text: '',
        outcome: 'succeeded'
    },
    JOIN: {
        event: 'enter',
        current_page: 'networking_room_preparatory_screen',
        clicked_text: '',
        outcome: 'succeeded'
    },
    START: {
        event: 'start',
        current_page: 'networking_room_preparatory_screen',
        clicked_text: '',
        outcome: 'succeeded'
    },
    MIC_SELECT: {
        event: 'microphone_selected',
        current_page: 'networking_room_preparatory_screen',
        clicked_text: 'microphone selected by the user',
        outcome: 'succeeded'
    },
    CAMERA_SELECT: {
        event: 'camera_selected',
        current_page: 'networking_room_preparatory_screen',
        clicked_text: 'camera selected by the user',
        outcome: 'succeeded'
    }
};
export const EXPLORE_EVENTS = {
    TRENDING_EVENT_CLICK: {
        event: 'trending_event_visit',
        position_id: '',
        clicked_text: '',
        current_page: 'explore_events_page',
        outcome: 'succeeded'
    },
    TRENDING_VIEWALL_CLICK: {
        event: 'button_click',
        clicked_text: 'view_all_trending_events',
        current_page: 'explore_events_page',
        outcome: 'succeeded'
    },
    RECOMMENDED_EVENT_CLICK: {
        event: 'recommended_event_visit',
        position_id: '',
        clicked_text: '',
        current_page: 'explore_events_page',
        outcome: 'succeeded'
    },
    RECOMMENDED_VIEWALL_CLICK: {
        event: 'button_click',
        clicked_text: 'view_all_recommended_events',
        current_page: 'explore_events_page',
        outcome: 'succeeded'
    },
    UPCOMING_EVENT_CLICK: {
        event: 'upcoming_event_visit',
        position_id: '',
        clicked_text: '',
        current_page: 'explore_events_page',
        outcome: 'succeeded'
    },
    UPCOMING_VIEWALL_CLICK: {
        event: 'button_click',
        clicked_text: 'view_all_upcoming_events',
        current_page: 'explore_events_page',
        outcome: 'succeeded'
    },
    ONGOING_EVENT_CLICK: {
        event: 'ongoing_event_visit',
        position_id: '',
        clicked_text: '',
        current_page: 'explore_events_page',
        outcome: 'succeeded'
    },
    ONGOING_VIEWALL_CLICK: {
        event: 'button_click',
        clicked_text: 'view_all_ongoing_events',
        current_page: 'explore_events_page',
        outcome: 'succeeded'
    },
    PUBLIC_VIEWALL_CLICK: {
        event: 'button_click',
        clicked_text: 'view_all_public_events',
        current_page: 'dashbaord_events_page',
        outcome: 'succeeded'
    },
    ALL_VIEWALL_CLICK: {
        event: 'button_click',
        clicked_text: 'view_all_public_events',
        current_page: 'dashbaord_events_page',
        outcome: 'succeeded'
    },
    ADD_CALINDER: {
        event: 'button_click',
        clicked_text: 'add_to_calendar',
        current_page: 'explore_events_page',
        outcome: 'succeeded'
    },
    ADD_FAVORITES: {
        event: 'button_click',
        clicked_text: 'add_event_to_favorites',
        current_page: 'explore_events_page',
        outcome: 'succeeded'
    },
    EXPLORE_FILTER1: {
        event: 'button_click',
        clicked_text: '',
        current_page: 'explore_events_page',
        outcome: 'succeeded'
    },
    EXPLORE_FILTER2: {
        event: 'button_click',
        clicked_text: '',
        current_page: 'explore_events_page',
        outcome: 'succeeded'
    },
    EXPLORE_SEARCH: {
        event: 'button_click',
        clicked_text: 'search_event',
        current_page: 'explore_events_page',
        outcome: 'succeeded'
    }
};
export const PREPARATORY_SETTINGS = {
    AUDIO: {
        event: 'button_click',
        current_page: 'settings',
        clicked_text: 'audio',
        outcome: 'succeeded'
    },
    TEST_SPEAKER: {
        event: 'button_click',
        current_page: 'settings',
        clicked_text: 'audio',
        outcome: 'succeeded'
    },
    TEST_MIC: {
        event: 'button_click',
        current_page: 'settings',
        clicked_text: 'test_mic',
        outcome: 'succeeded'
    },
    MIC_OFF_INCOMING_CALL: {
        event: 'button_click',
        current_page: 'settings',
        clicked_text: 'turn_off_my_microphone_when_accepting_incoming_call',
        outcome: 'enabled'
    },
    MIC_SELECT: {
        event: 'microphone_selected',
        current_page: 'settings',
        clicked_text: 'microphone selected by the user',
        outcome: 'succeeded'
    },
    SPEAKER_SELECT: {
        event: 'speaker_selected',
        current_page: 'settings',
        clicked_text: 'speaker selected by the user',
        outcome: 'succeeded'
    },
    CAMERA_SELECT: {
        event: 'camera_selected',
        current_page: 'settings',
        clicked_text: 'camera selected by the user',
        outcome: 'succeeded'
    },
    VIDEO: {
        event: 'button_click',
        current_page: 'settings',
        clicked_text: 'video',
        outcome: 'succeeded'
    },
    VIRTUAL_BACKGROUND: {
        event: 'virtual_background',
        current_page: 'settings',
        clicked_text: '',
        outcome: 'succeeded'
    },
    SELECT_BACKGROUND: {
        event: 'virtual_background',
        current_page: 'settings',
        clicked_text: 'background selected by the user',
        outcome: 'succeeded'
    }
};
export const HOME_PAGE_REVAMP = {
    CLOSE_RIBBON: {
        event: 'button_click',
        current_page: 'home_page',
        clicked_text: 'close_ribbon',
        outcome: 'succeeded'
    },
    TRY_NEW_LOOK: {
        event: 'button_click',
        current_page: 'home_page_revamp',
        clicked_text: 'try_new_look',
        outcome: 'succeeded'
    },
    HOME_BUTTON_CLICK: {
        event: 'button_click',
        current_page: 'home_page_revamp',
        clicked_text: 'home_button_click',
        outcome: 'succeeded'
    },
    REGISTER_NOW: {
        event: 'button_click',
        current_page: 'home_page_revamp',
        clicked_text: 'register_now',
        outcome: 'succeeded'
    },
    VIEW_DETAILS: {
        event: 'button_click',
        current_page: 'home_page_revamp',
        clicked_text: 'view_details',
        outcome: 'succeeded'
    },
    LOGIN: {
        event: 'button_click',
        current_page: 'Jioevents_header',
        clicked_text: 'login',
        outcome: 'succeeded'
    },
    SCHEDULE_DEMO_HEADER: {
        event: 'button_click',
        current_page: 'contact_us',
        clicked_text: 'header_schedule_demo',
        source_page: '',
        outcome: 'succeeded'
    },
    BANNER_SCHEDULE_DEMO: {
        event: 'button_click',
        current_page: 'home_page_revamp',
        clicked_text: 'banner_schedule_demo',
        outcome: 'succeeded'
    },
    SHOW_EVENTS: {
        event: 'button_click',
        current_page: '',
        clicked_text: '',
        outcome: 'succeeded'
    },
    VIEW_MORE: {
        event: 'button_click',
        current_page: 'explore-events',
        clicked_text: '',
        outcome: 'succeeded'
    },
    CONTACT_SALES: {
        event: 'button_click',
        current_page: 'contact_us',
        clicked_text: '',
        outcome: 'succeeded'
    },
    PRICING_CONTACT_US: {
        event: 'button_click',
        current_page: 'contact_us',
        clicked_text: 'custom_plan',
        outcome: 'succeeded'
    },
    EVENTS_CARD_CLICK: {
        event: 'button_click',
        current_page: 'attendee_landing_page',
        clicked_text: 'events_card_click',
        outcome: 'succeeded'
    },
    CREATE_EVENT: {
        event: 'button_click',
        current_page: 'home_page_revamp',
        clicked_text: 'create_event',
        outcome: 'succeeded'
    },
    SEND_MESSAGE: {
        event: 'button_click',
        current_page: 'contact_us',
        clicked_text: 'send_message',
        outcome: 'succeeded'
    },
    DROP_DOWN_CLICK: {
        event: 'button_click',
        current_page: 'Jioevents_header',
        clicked_text: '',
        outcome: 'succeeded'
    }
};
