import { Injectable } from '@angular/core';
import {
    CUSTOM_LAYOUT,
    CUSTOM_MEDIA_TYPE,
    MEDIA_LAYER_RANGE,
    Preview,
    Room,
    IConnectionState,
    MicrophoneAudioTrack
} from '@jiomeet/jm-media-web';
import { BehaviorSubject, from, Subject } from 'rxjs';
import { APP_CONSTANT, APP_EVENTS, MEDIA_ENGINE_EVENTS } from 'src/app/constants';
import { RtcService } from './rtc.service';
import { UtilService } from './util.service';
import { UserService } from './user.service';
import { ExternalInterfaceService } from './external-interface.service';
import { VirtualBackgroundService } from './virtual-background.service';
import { AppService, ParticipantsControlsService } from '.';
import { AppLoggerService } from './app-logger.service';
import { EventEmitterService } from './event-emitter.service';
import * as _ from 'lodash';
import { CheckJoinAsSpeaker } from '../helpers/meeting-check-util';
import { MultiStreamsMixer } from '../classes/multi-stream-mixer';
import { EventsPlusService } from './events-plus.service';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { JmTranslateService } from './jm-translate.service';
import { AttendeeViewStateService } from 'src/app/webinar-attendee/attendee-view-state.service';

declare var VC: any;
declare const window;

enum ConnectionState {
    DISCONNECTED = 'DISCONNECTED',
    CONNECTING = 'CONNECTING',
    RECONNECTING = 'RECONNECTING',
    CONNECTED = 'CONNECTED',
    DISCONNECTING = 'DISCONNECTING'
}
export enum SignalStatus {
    NONE = 'NONE',
    VERYBAD = 'VERYBAD',
    GOOD = 'GOOD',
    BAD = 'BAD'
}

@Injectable({
    providedIn: 'root'
})
export class JmMediaService {
    jmMediaRTC = null;
    isAudioPublished = false;
    isVideoPublished = false;
    currentUser;
    localParticipant;
    joinData;
    reconnecting = false;
    isUserOnline = true;
    users = {};
    vcs = {};
    galleryParticipants = [];
    nonGalleryParticipants = [];
    microphoneMutedAll = false;
    roomLocked = false;
    recording = false;
    screenSharing = false;
    waitingUsers = {};
    cameras = [];
    networkQuality;
    signalStrengthData = {
        signal: SignalStatus.GOOD
    };
    counterForGoodSignal = 0;
    counterForBadSignal = 0;
    counterForVeryBadSignal = 0;
    microphones = [];
    speakers = [];
    selectedLocalCamera = 0;
    selectedLocalMicrophone = 0;
    selectedLocalSpeaker = 0;
    typeOfVideoShare = '';
    cloudVideoStream: any;
    oldSelectedLocalCamera = 0;
    oldSelectedLocalMicrophone = 0;
    oldSelectedLocalSpeaker = 0;
    private disconneting = false;
    private alreadySharingScreen = false;
    private whiteboardShared;
    private meeting$: BehaviorSubject<any> = new BehaviorSubject(null);
    private chatMessages$: BehaviorSubject<any> = new BehaviorSubject(null);
    private deviceChanged$: BehaviorSubject<any> = new BehaviorSubject(null);
    private participantsStatus$: BehaviorSubject<any> = new BehaviorSubject(null);

    private rtmMessages$: BehaviorSubject<any> = new BehaviorSubject(null);

    reConnectInterval;
    localVolumeIndicator;

    MAX_VIDEO_TILES = 9;
    MAX_VIDEO_TILES_IN_SCREEN_SHARING = 4;

    SPEAKER_LIMIT = 25;
    MAX_HOST_LIMIT = 1;

    isVideoOn = new Subject<boolean>();

    videoDevicesUpdated = new Subject<boolean>();
    audioDevicesUpdated = new Subject<boolean>();

    localParticipantUpdated = new Subject<boolean>();
    participantAdded = new Subject<any>();
    participantRemoved = new Subject<any>();

    public participantStatusUpdated = new Subject<any>();
    public studioUpdates = new Subject<any>();

    errors = new Subject<any>();

    meetingObj;

    roomData;
    isHandRaise = false;

    groupId = '';
    callOptionsInternal = {
        audioOptions: {
            isMuted: true,
            audioOutputDeviceId: '',
            audioInputDeviceId: ''
        },
        videoOptions: {
            isVideoOn: false,
            videoDeviceId: ''
        }
    };
    displayName = '';
    streams: any = [];
    streamsCollection = [];
    remoteParticipants = [];
    localVideoStream;
    customCollection = [];

    visibleParticipants = [];
    inVisibleParticipants = [];
    //agora variables
    jmMediaClient;
    conferenceInfo;
    joiningRoom: boolean = false;
    public joinedInRoom: boolean = false;
    screenSharingStream: any;

    roomInfo;

    currentSenderId = '';

    currentUid = 0;
    currentRoomID = '';
    currentRtmToken = '';

    localScreenTrack;
    localScreenAudioTrack;

    isBreakoutRoom = false;

    noMediaPermission = false;
    noAudioPermission = false;
    noCameraPermission = false;

    leaveBreakoutRoom = false;

    isPostionChecked = false;

    activeSpeakerList = [];

    breakoutRoomInfo;

    localCameraOn = true;
    localMicOn = true;
    isScreenShareAvaiable = false;

    isFitToScreen = true;
    currentConnectionState;
    previousConnectionState;

    reconnectPopupTimer;

    canJoinAsSpeaker = false;

    currentSpeakerCount = 0;

    showedLimitReached = true;

    showHDScreenShareOption = false;
    isEnableHDshare = false;
    attendeeInfo: any = {};
    isAttendee: boolean = false; // Depends on the type of room they are joining.

    rtcSync = false;
    isVirtualBackgroundApplied = false;
    audioLevel = new Subject();

    spotlightedUserIds = [];
    isSpotlighted: boolean;
    noAudioPermissionBrowser: boolean;
    noAudioPermissionDevice: boolean;
    processingToggleMic: boolean = false;
    processingToggleCamera: boolean = false;
    virtualBackgroundExtension;
    processor = null;
    micState: boolean = false;
    cameraState: boolean = false;
    subscriptionTimeOuts = [];
    screenShareData: any;
    isPaused: boolean;
    unprocessedVideoPublishEvents = [];
    streamsMixer: MultiStreamsMixer;
    enableStreamMixer: boolean = false;
    pptSlideImages = [];
    streamMixerType: 'ppt' | 'screen' = 'screen';
    canUserCustomStream: boolean = false;
    localScreenShareStream: any;
    localScreenShareStreamBackUp: any;
    greenScreenImage = 'assets/images/green-screen.jpg';
    oldPeerId: any;
    localPeerId: string;
    localParticipantId: string;
    customScreenTracks = [];
    previewInstance;
    virtualBackgroundInstance;
    clonedScreenStream: MediaStream;
    jmCallDisconnected = false;
    unPublishMicrophoneTrack: MicrophoneAudioTrack;
    isAutoJoinRecordingAttendee: any;
    isLivestreamAttendee: any;
    currentWebinarId: any;
    clonedStreams: any = [];
    localCameraStream: any;
    videoConfig;
    cloudVideoEvents: any = [];
    isStudioEvent: boolean = false;
    isEventPreviewViewer: boolean;

    constructor(
        private rtcService: RtcService,
        private utilService: UtilService,
        private externalInterfaceService: ExternalInterfaceService,
        private webinarService: WebinarService,
        private userService: UserService,
        private vbService: VirtualBackgroundService,
        private appService: AppService,
        private appLoggerService: AppLoggerService,
        private eventEmitterService: EventEmitterService,
        private jmTranslateService: JmTranslateService,
        private participantControlService: ParticipantsControlsService,
        private attendeeViewStateService: AttendeeViewStateService
    ) {
        this.createVbInstances();
    }

    getMeetingObs() {
        return this.meeting$;
    }

    getChatMessages() {
        return this.chatMessages$;
    }

    getDeviceCahnge() {
        return this.deviceChanged$;
    }

    getParticipantStatus() {
        return this.participantsStatus$;
    }

    getRtmMessages() {
        return this.rtmMessages$;
    }

    setAttendeeInfo(info) {
        this.attendeeInfo = info;
        this.isAttendee = true;
        this.isAutoJoinRecordingAttendee = this.attendeeInfo.isRecordingAttendee;
        this.isLivestreamAttendee = this.attendeeInfo.isLivestreamAttendee;
        this.currentWebinarId = this.attendeeInfo.currentWebinarId;
    }
    changeLayout(layoutType) {
        this.jmMediaClient.changeLayout(layoutType);
    }
    get localHasAudio() {
        return this.jmMediaClient?.localUser?.hasAudio;
    }

    get localHasVideo() {
        return this.jmMediaClient?.localUser?.hasVideo;
    }

    get localMicrophoneTrack() {
        return this.jmMediaClient?.localUser?.audioTrack;
    }

    get localCameraTrack() {
        return this.jmMediaClient?.localUser?.videoTrack;
    }

    get localHasScreenVideo() {
        return this.jmMediaClient?.localUser?.hasScreenShareVideo;
    }

    intializeValue = () => {
        this.users = {};
        this.cameras = [];
        this.microphones = [];
        this.speakers = [];
        this.selectedLocalCamera = 0;
        this.selectedLocalMicrophone = 0;
        this.selectedLocalSpeaker = 0;
        this.disconneting = false;
        this.reconnecting = false;
    };

    isBehindProxy() {
        var proxyHeader = 'via';
        var req = new XMLHttpRequest();
        // req.open('GET', '/api/roomstatus', false); // for local
        req.open('GET', window.location, false); // for production
        req.send();
        var header = req.getResponseHeader(proxyHeader);
        if (header) {
            console.log('is behind proxy');
            // we are on a proxy
            return true;
        }
        return false;
    }

    async initializeVidyoConnector(dest = null) {
        if (this.joiningRoom || this.joinedInRoom) {
            let err = new Error('error while joining into the room');
            err.message = `jm-media:`;
            console.error('Duplicate call to JoinRoom', err);
            return;
        }
        if (this.jmMediaClient) return;
        //this.joinedInRoom = true;
        if (dest === 'attendeestage') {
            this.jmMediaClient = null;
            this.isAttendee = dest === 'attendeestage';
        }

        this.currentUser = this.userService.getUserSync();
        if (this.isAutoJoinRecordingAttendee) {
            this.currentUser.firstName = 'recorder';
            this.currentUser.lastName = 'attendee';
        }
        let meetingDomain = this.joinData?.host ? this.joinData?.host : this.appService.getConfigVariable('room_url');
        if (!meetingDomain) meetingDomain = 'mediaengine-demo.jiomeetcloud.com';
        if (!meetingDomain) {
            let err = new Error('error while joining into the room');
            err.message = `jm-media:`;
            this.appLoggerService.debug('Meeting Url not found', err);
            return;
        }
        console.log('room url', meetingDomain);
        this.jmMediaClient = this.createRoom(CUSTOM_LAYOUT.GRID, MEDIA_LAYER_RANGE.high, meetingDomain);
        window.jmmediaclient = this.jmMediaClient;
        //did not call any log enable method here(check if it is needed for jm-media)
        // Set the log output level as INFO
        this.jmMediaClient.setLogLevel(this.appService.isJmStatsEnable() ? 1 : 0); //confirm the log level is same as agora
        // const isJmLogEnabled = this.appService.getConfigVariable('JM_LOCAL_LOGS_ENABLED')?.enable;
        // const isJmLogLevel =
        //     this.appService.getConfigVariable('JM_LOCAL_LOGS_ENABLED')?.level !== undefined
        //         ? this.appService.getConfigVariable('JM_LOCAL_LOGS_ENABLED')?.level
        //         : 1;
        // if (this.appService.isJmStatsEnable() || isJmLogEnabled) {
        //     this.jmMediaClient.setLogLevel(isJmLogLevel);
        // }
        this.changeLayout(CUSTOM_LAYOUT.GRID);
        if (!this.isAttendee) {
            await this.updateAllDevices();
            await this.onDeviceChangeListener();
        }
        if ((this.rtcService.getPreparatorySetupDone() || this.isAttendee) && !!dest) {
            this.setPrivacy(dest);
            await this.joinRoom(dest);
        }
        this.meeting$.next({ type: 'SHOW_PREVIEW_CNTROLS', data: null });
    }

    private setPrivacy(dest = null) {
        if (this.isAttendee) return;
        this.toggleMicPrivacy(!this.joinData.micPrivacy);
        if (this.joinData.cameraPrivacy) {
            this.toggleCameraPrivacy(false);
        }
    }

    flipCamera() {
        const selectedCamera = this.selectedLocalCamera;
        let newCameraId;
        if (this.cameras.length > 1) {
            for (let i = 0; i < this.cameras.length; i++) {
                if (this.cameras[i].id === selectedCamera) {
                    if (i + 1 >= this.cameras.length) newCameraId = this.cameras[0];
                    else newCameraId = this.cameras[i + 1];
                }
            }

            if (newCameraId) {
                this.manuallyChangeCamera(newCameraId);
            } else {
                this.manuallyChangeCamera(this.cameras[0]);
            }
        }
    }

    setJoinData({
        host,
        displayName,
        roomKey,
        roomPin,
        micPrivacy,
        cameraPrivacy,
        isInitiater = false,
        viewId = 'localVideo',
        isEmbedInMobile = false,
        unlockAndJoin = false,
        meetingObj = null,
        userId = ''
    }) {
        this.joinData = {
            host,
            displayName,
            roomKey,
            roomPin,
            micPrivacy,
            cameraPrivacy,
            isInitiater,
            viewId,
            isEmbedInMobile,
            unlockAndJoin,
            meetingObj,
            userId
        };
    }

    userTalkListener(callback): void {}

    updateAllDevices() {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.jmMediaClient.getCameras().catch((errorInfo) => {
                    return {
                        hasError: true,
                        error: errorInfo
                    };
                }),
                this.jmMediaClient.getMicrophones().catch((errorInfo) => {
                    return {
                        hasError: true,
                        error: errorInfo
                    };
                }),
                this.jmMediaClient.getPlaybackDevices().catch((errorInfo) => {
                    return {
                        hasError: true,
                        error: errorInfo
                    };
                })
            ])
                .then((data) => {
                    if (data[0]?.hasError) {
                        this.appLoggerService.error('Unable to load Camera', data[0]?.error);
                    } else {
                        this.cameras = data[0];
                    }
                    this.videoDevicesUpdated.next(true);
                    if (data[1]?.hasError) {
                        this.appLoggerService.error('Unable to load Microphone', data[1]?.error);
                    } else {
                        this.microphones = data[1];
                    }
                    if (data[2]?.hasError) {
                        this.appLoggerService.error('Unable to load Playback Devices', data[2]?.error);
                    } else {
                        this.speakers = data[2];
                    }
                    this.audioDevicesUpdated.next(true);
                    this.noMediaPermission = false;
                    this.cameras = this.mapDeviceCollection(this.cameras);
                    this.microphones = this.mapDeviceCollection(this.microphones);
                    this.speakers = this.mapDeviceCollection(this.speakers);
                    this.sendControlStatus();
                    this.selectDevices();
                })
                .catch((error) => {
                    console.log('media-error', error);
                    this.noMediaPermission = true;
                    resolve();
                })

                .finally(() => {
                    resolve();
                });
        });
    }

    async onDeviceChangeListener() {
        this.jmMediaClient.on('camera-device-changed', async (info) => {
            try {
                console.log(info.state);
                this.cameras = this.mapDeviceCollection(await this.jmMediaClient.getCameras());

                if (info.state === 'INACTIVE') {
                    if (this.cameras.length > 0) {
                        if (String(this.selectedLocalCamera) === info.device.deviceId) {
                            this.oldSelectedLocalCamera = this.selectedLocalCamera;
                            this.selectedLocalCamera = this.cameras[0].id;
                            await this.jmMediaClient.setVideoSettings({ deviceId: String(this.selectedLocalCamera) });
                        }
                    } else {
                        this.oldSelectedLocalCamera = this.selectedLocalCamera;
                        this.toggleCameraPrivacy(true);
                    }
                }

                if (info.state === 'ACTIVE') {
                    this.selectedLocalCamera = info.device.deviceId;
                    await this.jmMediaClient.setVideoSettings({ deviceId: String(this.selectedLocalCamera) });
                }

                this.sendControlStatus();
            } catch (err) {
                console.log('Camera Device Changed Error', err);
            }
        });

        this.jmMediaClient.on('microphone-device-changed', async (info) => {
            try {
                console.log(info?.state);
                this.microphones = this.mapDeviceCollection(await this.jmMediaClient.getMicrophones());

                if (info.state === 'INACTIVE') {
                    if (this.microphones.length > 0) {
                        if (String(this.selectedLocalMicrophone) === info.device.deviceId) {
                            this.oldSelectedLocalMicrophone = this.selectedLocalMicrophone;
                            this.selectedLocalMicrophone = this.microphones[0].id;
                            // await this.localMicrophoneTrack.setDevice(String(this.selectedLocalMicrophone));
                        }
                        if (this.unPublishMicrophoneTrack) {
                            await this.unPublishMicrophoneTrack.setDevice(String(this.selectedLocalMicrophone));
                        }
                        await this.jmMediaClient.setAudioSettings({
                            deviceId: String(this.selectedLocalMicrophone)
                        });
                    } else {
                        this.oldSelectedLocalMicrophone = this.selectedLocalMicrophone;
                        this.toggleMicPrivacy(true);
                    }
                }

                if (info.state === 'ACTIVE' && !this.isVirtualDevice(info.device.label)) {
                    this.selectedLocalMicrophone = info.device.deviceId;
                    if (this.unPublishMicrophoneTrack) {
                        await this.unPublishMicrophoneTrack.setDevice(String(this.selectedLocalMicrophone));
                    }
                    await this.jmMediaClient.setAudioSettings({
                        deviceId: String(this.selectedLocalMicrophone)
                    });
                }

                this.sendControlStatus();
            } catch (err) {
                console.log('Microphone Device Changed Error', err);
            }
        });

        this.jmMediaClient.on('playback-device-changed', async (info) => {
            try {
                console.log(info);
                this.speakers = this.mapDeviceCollection(await this.jmMediaClient.getPlaybackDevices());
                if (info.state === 'INACTIVE') {
                    if (this.speakers.length > 0) {
                        if (String(this.selectedLocalSpeaker) === info.device.deviceId) {
                            this.oldSelectedLocalSpeaker = this.selectedLocalSpeaker;
                            this.selectedLocalSpeaker = this.speakers[0].id;
                        }
                        await this.updateJmClientSpeakerOut(String(this.selectedLocalSpeaker));
                    } else {
                        this.oldSelectedLocalSpeaker = this.selectedLocalSpeaker;
                        //this.toggleMicPrivacy(true);
                    }
                }

                if (info.state === 'ACTIVE' && !this.isVirtualDevice(info.device.label)) {
                    this.selectedLocalSpeaker = info.device.deviceId;
                    await this.updateJmClientSpeakerOut(String(this.selectedLocalSpeaker));
                }
                this.sendControlStatus();
            } catch (err) {
                console.log('Playback Device Changed Error', err);
            }
        });
    }

    isVirtualDevice(deviceName) {
        return deviceName?.toLowerCase().endsWith('(virtual)');
    }

    mapDeviceCollection(arr) {
        return arr.map((device) => {
            return { ...device, id: device.deviceId, name: device.label };
        });
    }

    notifyReady() {
        if (typeof window.navigator.notifyReady === 'function') {
            window.navigator.notifyReady();
        }
    }

    reConnect() {
        clearInterval(this.reConnectInterval);
        this.joinData.micPrivacy = !this.getLocalParticipant().microphoneMute;
        this.joinData.cameraPrivacy = !this.getLocalParticipant().cameraMute;
        this.joinRoom();

        this.reConnectInterval = setInterval(() => {
            this.joinRoom();
        }, 5 * 1000);
    }

    reConnectOnConnectionOnline() {
        this.joinData.micPrivacy = !this.getLocalParticipant().microphoneMute;
        this.joinData.cameraPrivacy = !this.getLocalParticipant().cameraMute;
        this.joinRoom();
    }

    clearAllTimeOuts() {
        this.subscriptionTimeOuts.forEach((timeoutId) => clearTimeout(timeoutId));
        this.subscriptionTimeOuts = [];
    }

    createRoom(layout: CUSTOM_LAYOUT, highestRecvResolution: MEDIA_LAYER_RANGE, meetingUrl: string) {
        return new Room({ layout, highestRecvResolution, meetingUrl });
    }

    async joinRoom(dest = null) {
        if (!this.jmMediaClient) {
            let err = new Error('error while joining into the room');
            err.message = `jm-media:`;
            console.error(err);
            return;
        }
        // TODO: add check for conference Info
        this.clearAllTimeOuts();
        this.isAttendee = dest === 'attendeestage';
        if (this.joiningRoom || this.joinedInRoom) {
            // Removing isAttendee to join networking room.
            // TODO: Make sure attendee cannot join main room
            let err = new Error('error while joining into the room');
            this.appLoggerService.debug('Duplicate call to JoinRoom', err);
            return;
        }

        // reset publish flag
        this.isAudioPublished = false;
        this.isVideoPublished = false;
        // change made here, removed dest flag
        this.conferenceInfo = !this.isAttendee ? this.rtcService.getConferenceInfo() : this.attendeeInfo;

        const roomInfo = this.rtcService.getRoomInfo();
        this.videoConfig =
            this.appService?.getConfigVariable('jmmediaBandwidthConfig')[
                this.conferenceInfo.webinarOptions?.videoQuality ?? 'sd'
            ];

        let config: any = {
            maxVideoResolution: this.videoConfig.resolution,
            maxFps: this.videoConfig.fps,
            maxBitrate: this.videoConfig.bitrate * 1000
        };

        if (!isNaN(Number(this.conferenceInfo.jmMediaMaxParticipants || this.conferenceInfo.agoraMaxParticipants))) {
            this.setMaxSpeakersCount();
        }
        let roomID;
        let roomPIN;
        this.isBreakoutRoom = false;
        roomID = !this.isAttendee ? this.conferenceInfo?.room?.jiomeetId : this.conferenceInfo?.jiomeetId;
        roomPIN = !this.isAttendee ? this.conferenceInfo?.room?.roomPIN : this.conferenceInfo?.roomPIN;

        if (
            this.rtcService.isVirtualBackgroundEnabledForRoom() &&
            !this.rtcService.disableVirtualBackgroundSupport()
            // removing isAttendee. Instead we need to check if they have access to join
        ) {
            this.vbService.initializeVirtualBackgroundService();
        }
        this.joiningRoom = true;
        this.registerForEventListeners();

        try {
            let joinInfo: {
                roomExtension: string;
                roomPin: string;
                userName: string;
                userType: 'human' | 'recorder' | 'livestream';
                userRole: string;
                historyId?: string;
                oldPeerId?: string;
                metaData?: string;
                token?: string;
                jioApiBackendUrl?: string;
                config?: any;
                attendee?: boolean;
            } = {
                userName: !this.isAttendee
                    ? this.joinData?.displayName
                    : (this.currentUser?.firstName || this.currentUser?.name) +
                      ' ' +
                      (this.currentUser.lastName || this.currentUser?.lname),
                roomExtension: roomID,
                roomPin: roomPIN,
                userType: this.utilService.recorderToken || this.isAutoJoinRecordingAttendee ? 'recorder' : 'human',
                userRole:
                    this.utilService.recorderToken || this.isAutoJoinRecordingAttendee
                        ? 'recorder'
                        : !this.isAttendee && this.setJoinAsSpeaker()
                        ? 'host'
                        : 'audience'
            };
            if (this.isAttendee) {
                joinInfo = {
                    ...joinInfo,
                    attendee: true
                };
            }
            if (this.utilService.isLiveStreaming || this.isLivestreamAttendee) joinInfo.userType = 'livestream';
            if (this.appService.historyId) {
                joinInfo = {
                    ...joinInfo,
                    historyId: this.appService.historyId
                };
            }
            if (this.oldPeerId) {
                joinInfo = {
                    ...joinInfo,
                    oldPeerId: this.oldPeerId
                };
            }
            if (!this.appService.getEnvVariable('BASE_URL').includes('localhost')) {
                joinInfo = {
                    ...joinInfo,
                    jioApiBackendUrl: this.appService.getEnvVariable('BASE_URL')
                };
            } else if (this.appService.getEnvVariable('BASE_URL').includes('localhost')) {
                joinInfo = {
                    ...joinInfo,
                    jioApiBackendUrl: `${this.appService.getConfigVariable('JIO_EVENTS_BASE_URL')}api`
                };
            }

            joinInfo = {
                ...joinInfo,
                config: {
                    ...config
                }
            };

            joinInfo = {
                ...joinInfo,
                metaData: JSON.stringify({ isMainStageAttendee: this.utilService.isMainStageAttendee })
            };

            await this.jmMediaClient.join(joinInfo);
            //this.jmMediaClient.enableNetworkQuality();
            this.localPeerId = String(this.jmMediaClient.localUser.uid);
            this.oldPeerId = '';
            this.localParticipantId = String(this.jmMediaClient.localUser.uid);
            let participant = {
                id: String(this.jmMediaClient.localUser.uid),
                userId: String(this.jmMediaClient.localUser.uid),
                name: this.joinData?.displayName,
                isLocal: true,
                isHost: this.joinData?.isInitiater ? true : false,
                microphoneMute: !this.isAttendee ? !this.joinData?.micPrivacy : true,
                cameraMute: !this.isAttendee ? !this.joinData?.cameraPrivacy : true,
                participantType: this.joinData?.isInitiater || dest === 'networking' ? 'speaker' : 'audience'
            };
            this.remoteParticipants = this.jmMediaClient.peers?.filter((peer) => peer?.role !== 'recorder');
            this.localParticipant = participant;
            // added callback to update timeout for webpage recording for recording
            this.notifyReady();

            this.meeting$.next({ type: 'VC_CREATED', data: null });

            this.meeting$.next({ type: 'ACTIVE', data: null });

            setTimeout(
                () =>
                    this.meeting$.next({
                        type: 'LOCAL_PARTICIPANT_CONNECTED',
                        data: null
                    }),
                200
            );

            //this.listenToRTCMessages();
            setTimeout(() => {
                this.jmCallDisconnected = false;
            }, 2000);

            this.isUserOnline = true;

            this.joinedInRoom = true;
            this.joiningRoom = false;
            const recorderErrorId = this.appService.getConfigVariable('recorderErrorId');
            if (roomID == recorderErrorId && this.isAutoJoinRecordingAttendee)
                setTimeout(() => {
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.JM_MEDIA_RECORDER_JOIN_FAILED,
                        data: {}
                    });
                }, 15000);

            if (
                this.breakoutRoomInfo?.breakoutRoomId ||
                (this.checkJoinAsSpeaker() && !this.externalInterfaceService.isEmbibe)
            ) {
                participant.participantType = 'speaker';
                this.externalInterfaceService.sendUserStatus(false);
            }

            if (participant.participantType === 'audience') {
                participant.cameraMute = true;
                participant.microphoneMute = true;
                this.externalInterfaceService.sendUserStatus(true);
            }
        } catch (error) {
            this.appLoggerService.error(`Error at joinroom`, error);
            if (error.statusCode === 423) {
                this.eventEmitterService.emit({ type: APP_EVENTS.JM_MAX_LIMIT_REACHED, data: null });
            } else {
                this.eventEmitterService.emit({
                    type: this.isAutoJoinRecordingAttendee
                        ? APP_EVENTS.JM_MEDIA_RECORDER_JOIN_FAILED
                        : APP_EVENTS.JM_MEDIA_JOIN_FAILED,
                    data: {}
                });
            }
            return Promise.reject(error);
        }
        if (this.localParticipant.isHost || this.breakoutRoomInfo?.breakoutRoomId || this.setJoinAsSpeaker()) {
            this.publishTracks();
        }
    }

    async listenNetworkQuality(networkData) {
        console.log('network quality hit');
        this.networkQuality = networkData;
        const networkQuality = this.convertSignalToQuality(networkData);
        if (this.counterForGoodSignal === 0 && this.counterForBadSignal === 0 && this.counterForVeryBadSignal === 0) {
            this.signalStrengthData.signal = SignalStatus.GOOD;
        }
        this.updateCounterInfo(networkQuality);
        let checkValue = this.checkUpgradeDowngrade(networkQuality);
        if (this.counterForGoodSignal === checkValue) {
            this.signalStrengthData.signal = SignalStatus.GOOD;
        } else if (this.counterForBadSignal === checkValue) {
            this.signalStrengthData.signal = SignalStatus.BAD;
        } else if (this.counterForVeryBadSignal === checkValue) {
            this.signalStrengthData.signal = SignalStatus.VERYBAD;
        }
    }

    convertSignalToQuality(signal): SignalStatus {
        let outPutSignal = SignalStatus.NONE;
        switch (signal) {
            case 0:
                outPutSignal = SignalStatus.NONE;
                break;
            case 1:
            case 2:
                outPutSignal = SignalStatus.GOOD;
                break;
            case 3:
            case 4:
                outPutSignal = SignalStatus.BAD;
                break;
            case 5:
                outPutSignal = SignalStatus.VERYBAD;
                break;
        }
        return outPutSignal;
    }

    updateCounterInfo(networkQuality) {
        if (networkQuality === SignalStatus.GOOD) {
            this.counterForGoodSignal++;
            this.counterForBadSignal = 0;
            this.counterForVeryBadSignal = 0;
        } else if (networkQuality === SignalStatus.BAD) {
            this.counterForBadSignal++;
            this.counterForGoodSignal = 0;
            this.counterForVeryBadSignal = 0;
        } else if (networkQuality === SignalStatus.VERYBAD) {
            this.counterForVeryBadSignal++;
            this.counterForGoodSignal = 0;
            this.counterForBadSignal = 0;
        }
    }

    checkUpgradeDowngrade(networkQuality) {
        let checkValue = 5;
        if (this.signalStrengthData.signal === SignalStatus.GOOD) {
            if (networkQuality === SignalStatus.BAD || networkQuality === SignalStatus.VERYBAD) {
                checkValue = 5;
            }
        }
        if (this.signalStrengthData.signal === SignalStatus.BAD) {
            if (networkQuality === SignalStatus.GOOD) {
                checkValue = 10;
            } else if (networkQuality === SignalStatus.VERYBAD) {
                checkValue = 5;
            }
        }
        if (this.signalStrengthData.signal === SignalStatus.VERYBAD) {
            if (networkQuality === SignalStatus.GOOD || networkQuality === SignalStatus.BAD) {
                checkValue = 10;
            }
        }
        return checkValue;
    }

    checkForCloudVideoOnUserPublish(user, mediaType) {
        if (!this.isCloudVideoUid(user)) return;
        this.typeOfVideoShare = 'cloud';
        this.cloudVideoEvents.push({ user, mediaType });
        this.participantStatusUpdated.next({ type: 'cloudPlayerJoined', data: { user, mediaType } });
    }

    isAttendeeWithPausedBroadcast(user, mediaType) {
        if (this.isAttendee && this.isPaused) {
            if (mediaType === 'cameraVideo' || mediaType === 'screenVideo') {
                this.unprocessedVideoPublishEvents.push({ user, mediaType });
            }
            return true;
        }
        return false;
    }

    shouldSkipCloudVideoProcessing(user, mediaType) {
        return !this.isAttendee && !this.isStudioEvent && this.isCloudVideoUid(user);
    }

    async handleScreenVideoPublished(user) {
        if (this.screenSharing) {
            await this.toggleWindowShare(false, true);
            this.stopBackUpScreenShareStream();
        }
        this.sendRecordingStatus(`user-published_${user.uid}_screenShare`);
        setTimeout(() => {
            this.screenSharingStream = user;
            this.isScreenShareAvaiable = true;
            this.screenShareData = user;
            this.participantStatusUpdated.next({ type: 'screenSharingStarted', data: user });
        }, 600);
    }

    async handleCameraVideoPublished(user) {
        if (this.isCloudVideoUid(user)) {
            this.cloudVideoStream = user;
            this.typeOfVideoShare = 'screen';
        }
        this.participantStatusUpdated.next({ type: 'userStreamPublished', data: user });
    }

    async subscribeToUserByMediaType(user, mediaType) {
        try {
            await this.jmMediaClient.subscribe(user, mediaType);
        } catch (err) {
            this.appLoggerService.error('Error subscribing to user by media type', err);
        }
    }

    async setPlaybackDevice(audioTrack) {
        try {
            await audioTrack?.setPlaybackDevice(String(this.selectedLocalSpeaker));
        } catch (err) {
            this.appLoggerService.error('Error setting playback device', err);
        }
    }

    async handleUserAudioPublished(user, mediaType, isScreenAudio = false) {
        try {
            if (this.isEventPreviewViewer) return;
            await this.subscribeToUserByMediaType(user, mediaType);
            const audioTrack = !isScreenAudio ? user.audioTrack : user.screenShareAudioTrack;
            if (!audioTrack) return;
            await audioTrack.play();
            this.checkForJmTranslation(audioTrack);
            await this.setPlaybackDevice(audioTrack);
            this.attendeeViewStateService.updateMediaState(user, 'audio', true, isScreenAudio);
        } catch (err) {
            this.appLoggerService.error('Error handling user audio published', err);
            this.attendeeViewStateService.updateMediaState(user, 'audio', false, isScreenAudio);
        }
    }

    async handleUserPublished(user, mediaType) {
        console.log('user-published', user);
        this.sendRecordingStatus(`user-published_${user.uid}_${mediaType}`);

        this.checkForCloudVideoOnUserPublish(user, mediaType);
        if (this.shouldSkipCloudVideoProcessing(user, mediaType)) return;
        if (this.isAttendeeWithPausedBroadcast(user, mediaType)) return;
        this.handleUserPublishedByType(user, mediaType);
    }

    async handleUserPublishedByType(user, mediaType) {
        switch (mediaType) {
            case CUSTOM_MEDIA_TYPE.SCREEN_VIDEO: {
                await this.handleScreenVideoPublished(user);
                break;
            }
            case CUSTOM_MEDIA_TYPE.CAMERA_VIDEO: {
                await this.handleCameraVideoPublished(user);
                break;
            }
            case CUSTOM_MEDIA_TYPE.MICROPHONE_AUDIO: {
                await this.handleUserAudioPublished(user, mediaType);
                break;
            }
            case CUSTOM_MEDIA_TYPE.SCREEN_AUDIO: {
                await this.handleUserAudioPublished(user, mediaType, true);
                break;
            }
            default: {
                break;
            }
        }
    }

    checkForJmTranslation(audioTrack) {
        if (!this.jmTranslateService.enableJmTranslateService) return;
        this.jmTranslateService.startTranslating(audioTrack);
    }

    handleUserUnpublished(user, mediaType) {
        this.sendRecordingStatus(`user-unpublished_${user.uid}_${mediaType}`);
        console.log('user-unpublished', user);
        const uid = user.uid;
        if (!user.hasAudio) {
            this.setAudioLevelToRemoteParticipant(uid, 0);
        }

        if (mediaType === CUSTOM_MEDIA_TYPE.MICROPHONE_AUDIO) {
            this.handleTranslateOnAudioUnpublish();
            this.attendeeViewStateService.updateMediaState(user, 'audio', false);
        }

        if (mediaType === CUSTOM_MEDIA_TYPE.SCREEN_AUDIO) {
            this.attendeeViewStateService.updateMediaState(user, 'audio', false, true);
        }

        if (mediaType === CUSTOM_MEDIA_TYPE.SCREEN_VIDEO) {
            this.meeting$.next({ type: 'CLEAR_SCREENSHARE_INFO', data: {} });
            this.isScreenShareStreamAvaiable();
            this.participantStatusUpdated.next({ type: 'screenSharingStopped', data: user });
            this.attendeeViewStateService.updateMediaState(user, 'video', false, true);
        }

        if (mediaType === CUSTOM_MEDIA_TYPE.CAMERA_VIDEO) {
            this.attendeeViewStateService.updateMediaState(user, 'video', false);
        }

        if (mediaType !== 'screenAudio' && mediaType !== 'microphoneAudio' && !user.hasVideo) {
            this.participantStatusUpdated.next({
                type: 'videoOff',
                data: {
                    id: uid,
                    ...user
                }
            });
        }
    }

    handleUserLeft(user) {
        this.sendRecordingStatus(`user-left_${user.uid}`);
        console.log('user-left', user);
        if (this.isCloudVideoUid(user)) {
            this.cloudVideoEvents = [];
            this.cloudVideoStream = null;
        }
        this.galleryParticipants = this.galleryParticipants.filter((participant) => participant.uid != user.uid);
        this.nonGalleryParticipants = this.nonGalleryParticipants.filter((participant) => participant.uid != user.uid);
        if (this.isCloudVideoUid(user)) this.participantStatusUpdated.next({ type: 'cloudPlayerLeft', data: user });
        this.participantRemoved.next(user);
        if (this.screenSharingStream && this.screenSharingStream.uid === user.uid) {
            // this.setRemoteStreamType(0);
            this.meeting$.next({ type: 'CLEAR_SCREENSHARE_INFO', data: {} });
            this.screenSharingStream = null;
            this.isScreenShareStreamAvaiable();
            this.participantStatusUpdated.next({ type: 'screenSharingStopped', data: user });
        }
        this.updateRemoteParticipants();
        if (this.rtcService.isChimeAudioEnabledForHost()) {
            this.sendControlStatus({ participantLeft: true });
        }
    }

    handleUserRoleUpdated(user, oldRole, newRole) {
        if (this.jmMediaClient.localUser != user) {
            if (newRole === 'host') {
                this.participantAdded.next(user);
                this.updateGalleryParticipantsOnRoleUpdate(user);
                if (this.rtcService.isChimeAudioEnabledForHost()) {
                    this.sendControlStatus({ participantJoined: true });
                }
            } else if (newRole === 'audience') {
                this.galleryParticipants = this.galleryParticipants.filter(
                    (participant) => participant.uid != user.uid
                );
                this.participantRemoved.next(user);
                if (this.screenSharingStream && this.screenSharingStream.uid === user.uid) {
                    // this.setRemoteStreamType(0);
                    this.meeting$.next({ type: 'CLEAR_SCREENSHARE_INFO', data: {} });
                    this.screenSharingStream = null;
                    this.isScreenShareStreamAvaiable();
                    this.participantStatusUpdated.next({ type: 'screenSharingStopped', data: user });
                }
                if (this.rtcService.isChimeAudioEnabledForHost()) {
                    this.sendControlStatus({ participantLeft: true });
                }
            }
            this.updateRemoteParticipants();
        }
    }

    handleUserJoined(user) {
        this.sendRecordingStatus(`user-joined_${user.uid}`);
        console.log('user-joined', user);
        if (
            user.role === 'audience' ||
            user.role === 'recorder' ||
            user.role === 'livestream' ||
            user.role === 'CPBot'
        ) {
            if (user.role === 'CPBot') this.nonGalleryParticipants.push(user);
            return;
        }
        this.participantAdded.next(user);
        this.galleryParticipants.push(user);
        this.updateRemoteParticipants();
        if (this.rtcService.isChimeAudioEnabledForHost()) {
            this.sendControlStatus({ participantJoined: true });
        }
        //should handle non gallery participants
    }

    handleBroadcastMessage(msgData) {
        this.appLoggerService.log('Received broadcast message', msgData);
        this.currentSenderId = msgData.sender.participantId;
        this.chatMessages$.next(msgData);
    }

    handleBroadcastMessageToPeer(msgData) {
        this.currentSenderId = msgData.sender.participantId;
        this.chatMessages$.next(msgData);
    }

    handleTopSpeaker(topSpeaker: Array<{ peerId: string; volume: number }>) {
        if (!topSpeaker) return;
        topSpeaker.forEach((user) => {
            user.volume = ((user.volume + 127) * 100) / 127;
            this.activeSpeakerListCreation(user.peerId, user.volume);

            //if (volume.uid != this.localParticipant.id || 1) { // allowing local participant volumn animation as well..
            if (user.peerId != this.localParticipant?.id) {
                this.setAudioLevelToRemoteParticipant(user.peerId, user.volume);
            }
            //}
            //local audiolevel is only being used in participant controls and sending audiolevel in case of livestreaming
            if (user.peerId == this.localParticipant?.id) {
                this.audioLevel.next({
                    uid: user.peerId,
                    level: user.volume
                });
            }
        });
        // this.setAudioLevelToAllRemoteParticipant(topSpeaker);
    }

    handleConnectionStateChange(curState: IConnectionState, previousState: IConnectionState) {
        if (curState == ConnectionState.RECONNECTING) {
            this.reconnecting = true;
            this.meeting$.next({
                type: 'JM_RECONNECTING',
                data: null
            });
        }

        if (curState === ConnectionState.CONNECTED && this.reconnecting) {
            clearTimeout(this.reconnectPopupTimer);
            this.reconnectPopupTimer = undefined;
            this.reconnecting = false;
            this.meeting$.next({
                type: 'JM_RECONNECTED', //RE_CONNECTED
                data: null
            });
        }

        if (curState === 'DISCONNECTED') {
            this.jmCallDisconnected = true;
            this.reconnecting = true;
            if (this.isAutoJoinRecordingAttendee)
                this.eventEmitterService.emit({ type: APP_EVENTS.JM_MEDIA_RECORDER_JOIN_FAILED, data: {} });
            this.meeting$.next({
                type: 'JM_RETRY_POPUP', //'JM_DISCONNECTED'
                data: null
            });
        }
    }

    registerForEventListeners() {
        this.registerToUserEvents();
        this.registerToNetworkQuality();
        this.registerToBroadcastMessages();
        this.registerToTopSpeaker();
    }

    registerToTopSpeaker() {
        this.jmMediaClient.on('topSpeaker', (topSpeaker: Array<{ peerId: string; volume: number }>) =>
            this.handleTopSpeaker(topSpeaker)
        );
    }

    registerToNetworkQuality() {
        this.jmMediaClient.enableNetworkQuality();
        this.jmMediaClient.on('network-quality', (networkQuality: any) => this.listenNetworkQuality(networkQuality));
        this.jmMediaClient.on(
            'connection-state-change',
            (curState: IConnectionState, previousState: IConnectionState) =>
                this.handleConnectionStateChange(curState, previousState)
        );
    }

    registerToUserEvents() {
        this.jmMediaClient.on('user-published', async (user, mediaType) => this.handleUserPublished(user, mediaType));
        this.jmMediaClient.on('user-joined', (user) => this.handleUserJoined(user));
        this.jmMediaClient.on('user-left', (user) => this.handleUserLeft(user));
        this.jmMediaClient.on('user-unpublished', async (user, mediaType) =>
            this.handleUserUnpublished(user, mediaType)
        );
        this.jmMediaClient.on('user-role-updated', (user, oldRole, newRole) =>
            this.handleUserRoleUpdated(user, oldRole, newRole)
        );
    }

    registerToBroadcastMessages() {
        this.jmMediaClient.on('broadcastMessage', (msgData) => this.handleBroadcastMessage(msgData));
        this.jmMediaClient.on('broadcastMessageToPeer', (msgData) => this.handleBroadcastMessageToPeer(msgData));
    }

    handleTranslateOnAudioUnpublish() {
        if (!this.jmTranslateService.enableJmTranslateService) return;
        try {
            this.jmTranslateService.stopListening();
            const newParticipantToTranslate = this.galleryParticipants.find((p) => p.hasAudio);
            if (newParticipantToTranslate) {
                this.jmTranslateService.startTranslating(newParticipantToTranslate.audioTrack);
            }
        } catch (err) {
            this.appLoggerService.log('Error: Handling Translation on Audio unpublish', err);
        }
    }

    updateGalleryParticipantsOnRoleUpdate(user) {
        const galleryIndex = this.galleryParticipants.findIndex((p) => p.uid == user.uid);
        if (galleryIndex == -1) {
            this.galleryParticipants.push(user);
        } else {
            this.galleryParticipants[galleryIndex] = user;
        }
    }

    async sendRecordingStatus(activityState) {
        if (this.isAutoJoinRecordingAttendee) {
            this.attendeeViewStateService.sendMediaEngineEventStatus(activityState);
        }
    }

    async rejoinRoom() {
        try {
            this.isVideoOn.next(false);
            this.isBreakoutRoom = false;
            this.disconneting = true;
            this.joinedInRoom = false;
            this.screenSharingStream = null;
            this.isScreenShareAvaiable = false;
            this.oldPeerId = this.localPeerId;
            await this.jmMediaClient.leave();
            this.cloudVideoEvents = [];
            this.galleryParticipants = [];
            this.jmMediaClient.removeAttachListeners();
        } catch (exception) {
            this.externalInterfaceService.sendErrorOccuredOnJoined(exception?.message);
            console.log(exception);
        }
    }

    async subscribeUserAudioStream(user, mediaType) {
        if (this.isEventPreviewViewer) return;
        await this.jmMediaClient.subscribe(user, mediaType).catch((err) => {});
        const audioTrack =
            mediaType === CUSTOM_MEDIA_TYPE.MICROPHONE_AUDIO ? user.audioTrack : user.screenShareAudioTrack;

        if (!audioTrack) return;
        //play the audio
        audioTrack.play();
        await audioTrack?.setPlaybackDevice(String(this.selectedLocalSpeaker)).catch((e) => {
            console.log(e);
        });
    }

    pauseAllAudioTracks() {
        if (!this.galleryParticipants.length) {
            setTimeout(() => {
                this.pauseAllAudioTracks();
            }, 2000);
        }
        this.galleryParticipants.forEach((user) => {
            if (user.audioTrack) {
                this.jmMediaClient.unsubscribe(user, 'microphoneAudio');
            }
            if (user.screenShareAudioTrack) {
                this.jmMediaClient.unsubscribe(user, 'screenAudio');
            }
        });
        this.nonGalleryParticipants.forEach((user) => {
            if (user.audioTrack) {
                this.jmMediaClient.unsubscribe(user, 'microphoneAudio');
            }
        });
    }

    resumeAllTracks() {
        this.galleryParticipants.forEach((user) => {
            if (user.hasAudio) {
                this.subscribeUserAudioStream(user, 'microphoneAudio');
            }
            if (user.hasScreenShareAudio) {
                this.subscribeUserAudioStream(user, 'screenAudio');
            }
            if (
                user.hasVideo &&
                this.unprocessedVideoPublishEvents.findIndex((upe) => upe.user.uid === user.uid) === -1
            ) {
                setTimeout(() => this.handleUserPublished(user, 'cameraVideo'), 200);
            }
        });
        this.nonGalleryParticipants.forEach((user) => {
            if (user.hasAudio) {
                this.subscribeUserAudioStream(user, 'microphoneAudio');
            }
        });
        // check if video is there
        setTimeout(() => {
            this.unprocessedVideoPublishEvents.forEach((upe) => {
                this.handleUserPublished(upe.user, upe.mediaType);
            });
            this.unprocessedVideoPublishEvents = [];
        }, 1000);

        // play relevant video tracks
    }

    setJoinAsSpeaker() {
        const whitelistedTenantForSpeakerEntry = this.appService.getConfigVariable('whitelistedTenantForSpeakersEntry');
        const MAX_COHOST_LIMIT = this.appService.getConfigVariableWithDefault(
            'WEBINAR_MAX_COHOST',
            APP_CONSTANT.WEBINAR_MAX_COHOST
        );
        // check if the current user is host then return true
        if (this.joinData?.isInitiater || this.localParticipant?.isHost) return true;

        return CheckJoinAsSpeaker(
            this.conferenceInfo,
            this.currentUser,
            whitelistedTenantForSpeakerEntry,
            this.isAttendee,
            this.SPEAKER_LIMIT,
            MAX_COHOST_LIMIT
        );
    }

    checkJoinAsSpeaker() {
        return this.setJoinAsSpeaker();
    }

    setMaxSpeakersCount() {
        // Max speakers count is 5 less than the total participants count
        const totalParticipantCount = Number(
            this.conferenceInfo.jmMediaMaxParticipants || this.conferenceInfo.agoraMaxParticipants
        );
        const MAX_COHOST_LIMIT = this.appService.getConfigVariableWithDefault(
            'WEBINAR_MAX_COHOST',
            APP_CONSTANT.WEBINAR_MAX_COHOST
        );

        this.SPEAKER_LIMIT = totalParticipantCount;
    }

    async checkIfStreamInEnabled() {
        const isStreamInEnabled = await this.webinarService.getStreamInEnabledState(
            this.joinData?.meetingObj?.meetingId
        );
        if (isStreamInEnabled) {
            this.participantControlService.setMicVisibility(false);
        }
        return isStreamInEnabled;
    }

    async publishTracks() {
        if (!this.noMediaPermission) {
            try {
                if (this.selectedLocalMicrophone === 0) {
                    //check added for to handle issue on safari
                    this.selectedLocalMicrophone = this.microphones[0]?.id;
                }
                const isStreamInEnabled = await this.checkIfStreamInEnabled();
                let settings: any = {
                    trackSettings: {
                        isAudioEnabled: !this.localParticipant.microphoneMute && !isStreamInEnabled,
                        isVideoEnabled: !this.localParticipant.cameraMute,
                        audioInputDeviceId: String(this.selectedLocalMicrophone)
                    },
                    virtualBackgroundSettings: { isVirtualBackground: false, sourceType: 'none', sourceValue: '' }
                };
                if (this.selectedLocalCamera) {
                    settings = {
                        trackSettings: { ...settings.trackSettings, videoDeviceId: String(this.selectedLocalCamera) }
                    };
                }
                if (this.vbService.backgroundConfig.type === 'blur') {
                    settings = {
                        trackSettings: { ...settings.trackSettings },
                        virtualBackgroundSettings: {
                            isVirtualBackground: true,
                            sourceType: 'blur',
                            sourceValue: '10'
                        }
                    };
                } else if (this.vbService.backgroundConfig.type === 'image') {
                    settings = {
                        trackSettings: { ...settings.trackSettings },
                        virtualBackgroundSettings: {
                            isVirtualBackground: true,
                            sourceType: 'image',
                            sourceValue: this.vbService.backgroundConfig.imageUrl
                        }
                    };
                }
                //this.jmMediaClient.noiseSuppression = this.appService.getIsNoiseSuppression();
                await this.jmMediaClient.startPublish(settings);
                if (this.jmMediaClient.localUser.videoTrack) {
                    this.isVideoOn.next(true);
                    this.cameraState = true;
                    this.isVideoPublished = true;
                    // this.localCameraTrack = this.jmMediaClient.localUser.videoTrack;
                }
                if (this.jmMediaClient.localUser.audioTrack) {
                    this.micState = true;
                    this.isAudioPublished = true;
                } else {
                    const track = await this.jmMediaClient.createMicrophoneTrack();
                    if (track) {
                        this.unPublishMicrophoneTrack = track;
                    }
                }
            } catch (e) {
                this.externalInterfaceService.sendErrorOccuredOnJoined({
                    message: e?.message,
                    code: e?.code,
                    area: 'Creating video track'
                });
            }
        }
    }

    async createCustomCameraTrack() {
        let screenStream;
        let cameraStream;
        // let _mediaStreamTrack;
        if (this.streamMixerType === 'screen') {
            try {
                screenStream = this.localScreenTrack ? this.localScreenShareStream : await this.startScreenCapture();
                this.localScreenShareStream = screenStream;
                this.screenSharing = true;
            } catch (err) {
                this.appLoggerService.error('Failed to get Screen share stream');
                return;
            }
            screenStream.fullcanvas = true;
            screenStream.width = 1280; // or 3840
            screenStream.height = 720; // or 2160
            screenStream.sourceOfStream = 'screen';
        }

        cameraStream = await this.getUserCameraFeed();
        cameraStream.sourceOfStream = 'camera';
        this.localCameraStream = cameraStream;
        // this.getTracksJmMediaTrack(cameraStream, 'custom-camera-test');
        // this.getTracksJmMediaTrack(screenStream, 'custom-screen-test');
        if (!this.streamsMixer) {
            this.isVideoPublished = false;
            // if (this.localCameraTrack && !this.streamsMixer) {
            //     this.localCameraTrack.setEnabled(false);
            //     await this.jmmediaClientClient.unpublish(this.localCameraTrack);
            //     this.streamsMixer = null;
            // }
            this.streamsMixer = new MultiStreamsMixer(
                this.streamMixerType !== 'screen' ? [cameraStream] : [screenStream, cameraStream]
            );
            this.streamsMixer.streamMixingType = this.streamMixerType;
        } else {
            this.streamsMixer.streamMixingType = this.streamMixerType;
            await this.streamsMixer.resetVideoStreams(
                this.streamMixerType !== 'screen' ? [cameraStream] : [screenStream, cameraStream]
            );
        }
        await this.getMixedStreamCustomJmMediaTrack();

        this.streamsMixer.pptSlidesImageArray = this.pptSlideImages;
        if (this.streamMixerType === 'screen') {
            this.streamsMixer.changeLayoutForScreenShareStream();
        } else {
            this.streamsMixer.changeLayoutForPresentationStream();
        }
        await this.streamsMixer.startDrawingFrames();
        document.body.appendChild(this.streamsMixer.canvas);
        // Play the screen share track to local view (preview)
        this.isVideoOn.next(true);
        await this.localScreenTrack.play(this.joinData?.viewId, { mirror: false, fit: 'contain' });
    }

    async getMixedStreamCustomJmMediaTrack() {
        try {
            let _mediaStream = await this.streamsMixer.getMixedStream();
            this.customScreenTracks = [];
            console.log('_mediastream from mixer =', _mediaStream);
            if (_mediaStream) {
                this.customScreenTracks = await this.jmMediaClient.createCustomScreenTrack(_mediaStream);
            }
            console.log('custom tracks from create custom track', this.customScreenTracks);
            this.localScreenTrack = this.customScreenTracks[0];
        } catch (err) {
            this.appLoggerService.error('Error while creating Custom Track', err);
        }
    }

    async unpublishLocalCameraTrack() {
        if (!!this.localCameraTrack) {
            this.jmMediaClient.setLocalVideoEnabled(false);
            this.isVideoPublished = false;
        }
    }

    async getScreenCapture() {
        this.localScreenShareStream = await this.startScreenCapture();
    }

    async startScreenCapture(displayMediaOptions = {}): Promise<any> {
        if (this.localScreenShareStream) return this.localScreenShareStream;
        if (this.localScreenShareStreamBackUp) {
            return this.getClonedScreenShareStream(this.localScreenShareStreamBackUp);
        }
        let captureStream: MediaStream = null;
        // @ts-ignore
        captureStream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });

        /**
         * Creating a backup Stream, as Jm Media stops track on unpublish
         */
        this.localScreenShareStreamBackUp = captureStream;
        this.localScreenShareStreamBackUp.getTracks()[0].onended = () => {
            this.localScreenShareStreamBackUp = null;
            this.meeting$.next({ type: 'SHARE_STOP', data: { stopShare: true } });
        };

        return this.getClonedScreenShareStream(this.localScreenShareStreamBackUp);
    }

    async getClonedScreenShareStream(stream) {
        let newStream = stream.clone();
        newStream.getTracks()[0].onended = () => {
            // emit this event when its not stream Mix Transfer
            this.meeting$.next({ type: 'SHARE_STOP', data: { stopShare: true } });
            this.stopBackUpScreenShareStream();
        };
        this.clonedStreams.push(newStream);
        return newStream;
    }

    async getUserCameraFeed() {
        let stream = null;
        if (this.selectedLocalCamera === 0 && this.cameras.length > 0) {
            this.selectedLocalCamera = this.cameras[0].id;
        }

        try {
            stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    width: { ideal: 1920 },
                    height: { ideal: 1080 },
                    deviceId: { exact: String(this.selectedLocalCamera) }
                },
                audio: false
            });
        } catch (err) {
            this.appLoggerService.error('Failed to get camera feed from browser', err);
        }
        return stream;
    }

    changeStreamMixerLayout(idx = null) {
        this.streamsMixer.changeLayout(idx);
    }

    uploadPptImages() {}

    handleDropFileEvent(event) {
        var isFileValid = this.utilService.validateFileToUpload(event.target.files[0]);

        if (isFileValid) {
            let file = event.target.files[0];
            // this.uploadLogo(event[0]);
            this.pptSlideImages.push(this.utilService.markUrlAsSafe(this.createBlobUrl(event.target.files[0])));
        }
    }

    createBlobUrl(file) {
        // URL.revokeObjectURL()
        // THis should be run when the url is no longer in use
        return URL.createObjectURL(file);
    }

    changeStreamMixerBgSlide() {
        this.streamsMixer.changeLayoutBgSlide();
    }

    activeSpeakerListCreation(u, v) {
        let obj = {
            uid: String(u),
            volume: v,
            lastSpeaking: new Date().getTime()
        };

        let index = this.activeSpeakerList.findIndex((e) => e.uid === String(u));

        if (index === -1) {
            this.activeSpeakerList.push(obj);
        } else {
            this.activeSpeakerList[index].volume = v;
            this.activeSpeakerList[index].lastSpeaking = new Date().getTime();
        }
    }

    isScreenShareUid(uid) {
        return uid.toString().length > 9 && uid.toString().charAt(0) === '1' ? true : false;
    }

    isCloudVideoUid(user) {
        return user.role === 'CPBot';
    }

    setAudioLevelToRemoteParticipant(uid, audiolevel) {
        this.participantStatusUpdated.next({
            type: 'isVolumeChanged',
            data: {
                id: uid,
                volume: audiolevel
            }
        });
    }

    setAudioLevelToAllRemoteParticipant(topSpeaker) {
        if (!topSpeaker) return;
        let audioLevels = [];
        this.galleryParticipants.forEach((user) => {
            let speakerIndex = topSpeaker.findIndex((speaker) => speaker.peerId == user.uid);
            if (speakerIndex != -1) audioLevels.push({ uid: user.uid, volume: topSpeaker[speakerIndex].volume });
            else audioLevels.push({ uid: user.uid, volume: 0 });
        });
        audioLevels.forEach((user) => {
            this.setAudioLevelToRemoteParticipant(user.uid, user.volume);
        });
    }

    updateRemoteParticipants() {
        this.remoteParticipants = [];
        this.remoteParticipants = this.jmMediaClient.peers?.filter((peer) => peer?.role !== 'recorder');
    }

    isScreenShareStreamAvaiable() {
        if (this.jmMediaClient.remoteUsers?.size === 0) {
            // if there is no remote participant then the below forEach will not iterate
            // and this.isScreenShareAvaiable won't reset to false
            // this condition used to reset it to false
            this.isScreenShareAvaiable = false;
            return;
        }
        if (this.jmMediaClient.publishedScreenShareUsers.length !== 0) {
            this.isScreenShareAvaiable = true;
        } else {
            this.isScreenShareAvaiable = false;
        }
    }

    async selectDevices() {
        let speakerId = this.utilService.getCookie('cacheSpeaker');
        let micId = this.utilService.getCookie('cacheMic');
        let cameraId = this.utilService.getCookie('cacheCamera');

        //setting device from url
        if (this.externalInterfaceService.cameraLabel) {
            const camera = this.cameras.find((x) => x.name === this.externalInterfaceService.cameraLabel);
            cameraId = camera.id;
        }
        if (this.externalInterfaceService.micLabel) {
            const mic = this.microphones.find((x) => x.name === this.externalInterfaceService.micLabel);
            micId = mic.id;
        }
        if (this.externalInterfaceService.speakerLabel) {
            const speaker = this.speakers.find((x) => x.name === this.externalInterfaceService.speakerLabel);
            speakerId = speaker.id;
        }

        //setting mic
        if (micId) {
            this.microphones.map(async (mic) => {
                if (mic.id == micId) {
                    this.selectedLocalMicrophone = mic.id;
                }
            });
            if (!this.selectedLocalMicrophone) {
                if (this.microphones.length > 0) this.selectedLocalMicrophone = this.microphones[0].id;
            }
        } else {
            if (this.microphones.length > 0) this.selectedLocalMicrophone = this.microphones[0].id;
        }

        //setting camera
        if (cameraId) {
            this.cameras.map(async (camera) => {
                if (camera.id == cameraId) {
                    this.selectedLocalCamera = camera.id;
                }
            });
            if (!this.selectedLocalMicrophone) {
                if (this.microphones.length > 0) this.selectedLocalMicrophone = this.microphones[0].id;
            }
        } else {
            if (this.cameras.length > 0) this.selectedLocalCamera = this.cameras[0].id;
        }

        //setting speaker
        if (speakerId) {
            this.speakers.forEach((speaker) => {
                if (speaker.id === speakerId) {
                    this.selectedLocalSpeaker = speaker.id;
                }
            });
            if (!this.selectedLocalSpeaker) {
                if (this.speakers.length > 0) this.selectedLocalSpeaker = this.speakers[0].id;
            }
        } else {
            if (this.speakers.length > 0) this.selectedLocalSpeaker = this.speakers[0].id;
        }
    }

    async manuallyChangeSpeaker(localSpeaker) {
        document.cookie = `cacheSpeaker=${localSpeaker.id}`;
        this.selectedLocalSpeaker = localSpeaker.id;
        await this.updateJmClientSpeakerOut(String(this.selectedLocalSpeaker));
    }

    async updateJmClientSpeakerOut(id: string) {
        try {
            await this.jmMediaClient.setPlaybackDevice({ deviceId: id });
        } catch (e) {
            console.log(e);
        }
    }

    async manuallyChangeMicrophone(localMicrophone) {
        document.cookie = `cacheMic=${localMicrophone.id}`;
        this.selectedLocalMicrophone = localMicrophone.id;
        try {
            if (this.unPublishMicrophoneTrack) {
                await this.unPublishMicrophoneTrack.setDevice(String(this.selectedLocalMicrophone));
            }
            await this.jmMediaClient.setAudioSettings({
                deviceId: String(this.selectedLocalMicrophone)
            });
        } catch (err) {
            this.appLoggerService.log(err);
        }
    }

    async manuallyChangeCamera(localCamera) {
        document.cookie = `cacheCamera=${localCamera.id}`;
        this.selectedLocalCamera = localCamera.id;
        try {
            await this.jmMediaClient.setVideoSettings({ deviceId: String(this.selectedLocalCamera) });
            this.participantStatusUpdated.next({
                type: 'locaUserCameraChange',
                data: this.localParticipant
            });
        } catch (err) {
            this.appLoggerService.log(err);
        }
    }

    async leaveRoom() {
        console.log('leave room called');
        try {
            this.cameraState = false;
            this.micState = false;
            this.isVideoOn.next(false);
            this.isBreakoutRoom = false;
            this.disconneting = true;
            this.isPostionChecked = false;
            this.joinedInRoom = false;
            this.joiningRoom = false;
            this.unprocessedVideoPublishEvents = [];
            this.virtualBackgroundInstance = undefined;
            await this.jmMediaClient?.leave();
            await this.jmMediaClient?.removeAttachListeners();
            if (this.isAttendee) {
                this.jmMediaClient = null;
            }
            // remove attendee check - networking rooms
            this.processor = null; // To be destroyed along local camera track
            // this.localCameraTrack = null;
            // this.localMicrophoneTrack = null;
            this.streamsMixer = null;
            this.spotlightedUserIds = [];
            this.galleryParticipants = [];
            this.isScreenShareAvaiable = false;
            clearInterval(this.localVolumeIndicator);
            // this.localCameraTrack.close();
            // this.localMicrophoneTrack.close();
        } catch (exception) {
            console.log(exception);
        }
        this.toggleWindowShare(false, true);
    }

    async toggleCameraPrivacy(enable) {
        if (enable !== this.localHasVideo) {
            this.cameraState = this.localHasVideo;
            return;
        }
        if (this.processingToggleCamera) return;
        this.processingToggleCamera = true;
        this.cameraState = !this.localHasVideo;
        try {
            if (!this.localHasVideo) {
                this.rtcSync = true;
                await this.jmMediaClient.setLocalVideoEnabled(true);
                this.studioUpdates.next({ type: 'videoPublished', data: this.localParticipant });
                this.participantStatusUpdated.next({
                    type: 'localUserStreamPublished',
                    data: this.localParticipant
                });
                this.isVideoOn.next(true);
                await this.localCameraTrack.play(this.joinData?.viewId);
                this.localParticipant.cameraMute = false;
                this.externalInterfaceService.sendCameraStatus(true);
            } else {
                await this.jmMediaClient.setLocalVideoEnabled(false);
                this.localParticipant.cameraMute = true;
                this.externalInterfaceService.sendCameraStatus(false);
                this.isVideoOn.next(false);
                this.participantStatusUpdated.next({
                    type: 'localUserStreamUnPublished',
                    data: this.localParticipant
                });
            }
            this.sendControlStatus();
        } catch (error) {
            this.processingToggleCamera = false;
            if (this.noCameraPermission) {
                this.errors.next({ type: APP_EVENTS.ERRORS.NO_CAMERA_PERMISSION });
                this.eventEmitterService.emit({
                    type: APP_EVENTS.SHOW_PERMISSION_UI,
                    data: { show: true, skipOption: false }
                });
            }
        } finally {
            this.processingToggleCamera = false;
            this.cameraState = this.localHasVideo;
        }
        this.sendControlStatus();
    }

    async handleCannotPublishMultipleTracks(source, state) {
        if (source === 'camera') {
            await this.unpublishLocalCameraTrack();
            await this.toggleCameraPrivacy(state);
            if (this.micState) {
                this.toggleMicPrivacy(!this.micState);
            }
        }
    }

    async stopLocalCameraTrack() {
        await this.jmMediaClient.setLocalVideoEnabled(false);
        this.isVideoOn.next(false);
        if (this.isVirtualBackgroundApplied) {
            this.vbService.closeSession();
        }
        // this.stopAndResetStreamMixerStreams();
    }

    async stopAndResetStreamMixerStreams(exlcudeScreenShare = false) {
        if (this.streamsMixer) {
            await this.streamsMixer.stopExistingMediaStreams(exlcudeScreenShare);
            await this.streamsMixer.resetVideoStreams([]);
        }
    }

    async toggleMicPrivacy(enable) {
        if (enable !== this.localHasAudio) {
            this.micState = this.localHasAudio;
            return;
        }
        if (this.processingToggleMic) return;
        this.processingToggleMic = true;
        this.micState = !this.localHasAudio;
        try {
            if (this.localHasAudio) {
                if (this.localMicrophoneTrack) {
                    await this.localMicrophoneTrack.pauseTrack();
                } else {
                    this.processingToggleMic = false;
                    return;
                }
            } else {
                if (this.localMicrophoneTrack) {
                    await this.localMicrophoneTrack.resumeTrack();
                } else if (this.unPublishMicrophoneTrack) {
                    await this.jmMediaClient.publish(this.unPublishMicrophoneTrack);
                    console.log('settings', this.jmMediaClient.localUserSettings);
                    this.unPublishMicrophoneTrack = null;
                } else {
                    await this.jmMediaClient.setLocalAudioEnabled(true);
                }
                this.isAudioPublished = true;
            }
            if (this.localParticipant) {
                this.localParticipant.microphoneMute = !this.jmMediaClient.localUser.hasAudio;
                this.externalInterfaceService.sendMicStatus(this.jmMediaClient.localUser.hasAudio);
            }
        } catch (error) {
            if (this.localParticipant) {
                this.localParticipant.microphoneMute = !this.jmMediaClient.localUser.hasAudio;
            }
            this.processingToggleCamera = false;
            throw error;
        } finally {
            this.micState = this.localHasAudio;
            this.processingToggleMic = false;
        }
        this.sendControlStatus();
    }

    disableCustomStream() {
        this.enableStreamMixer = false;
    }

    toggleRecordingStatus(enable) {
        this.recording = enable;
        this.sendControlStatus();
    }

    toggleMicAll(enable) {
        this.microphoneMutedAll = enable;
        this.sendControlStatus();
    }

    toggleLockStatus(enable) {
        this.roomLocked = enable;
        this.sendControlStatus();
    }

    setHostDisconnectParticipant(id) {
        if (this.users[id]) {
            this.users[id].disconnectedByHost = true;
        }
    }

    async sendChatMessage({
        type,
        message,
        targetParticipantId = '',
        targetParticipantName = '',
        agoraShareUid,
        data = ''
    }) {
        let payload;

        if (type === 'PublicChat') {
            payload = {
                type,
                message,
                sender: {
                    participantId: this.roomData?.localParticipant.participantId,
                    name: this.roomData?.localParticipant.participantName,
                    userId: this.roomData?.localParticipant.userId,
                    agoraShareUid
                },
                agoraShareUid,
                targetParticipantId: '',
                data
            };

            if (
                message === MEDIA_ENGINE_EVENTS.PARTICIPANT_COHOST_GRANT ||
                message === MEDIA_ENGINE_EVENTS.PARTICIPANT_COHOST_REVOKE ||
                message === MEDIA_ENGINE_EVENTS.HOST_LOWER_HAND
            ) {
                payload.targetParticipantId = targetParticipantId ? targetParticipantId : this.currentSenderId;
                // if (targetParticipantId) {
                //   payload = { ...payload, ...{ targetParticipantId } };
                // }
            }
        } else {
            payload = {
                type,
                message,
                targetParticipantName,
                targetParticipantId,
                targetHostUserId: this.roomData?.localParticipant.userId,
                sender: {
                    participantId: this.roomData?.localParticipant.participantId,
                    name: this.roomData?.localParticipant.participantName,
                    userId: this.roomData?.localParticipant.userId
                },
                data
            };
        }

        console.log('chat message', payload);

        if (type === 'PublicChat') {
            return await this.jmMediaClient.broadCastMessage(payload, this.roomData?.localParticipant.participantId);
        } else {
            return await this.jmMediaClient.broadCastMessageToPeer(
                payload,
                this.roomData?.localParticipant.participantId,
                payload.targetParticipantId
            );
        }
    }

    setHostIdentity(sender) {
        if (this.users[sender.id]) {
            this.users[sender.id].isHost = true;
        }
    }

    getAspectRatio() {
        const feedHeight = this.localCameraTrack.getCurrentFrameData().height;
        const feedWidth = this.localCameraTrack.getCurrentFrameData().width;
        return feedWidth / feedHeight;
    }

    sendStartScreenShareRtmMessage() {
        const chatObj = {
            agoraShareUid: this.localParticipantId,
            type: 'PublicChat',
            message: MEDIA_ENGINE_EVENTS.PARTRICIPANT_START_SHARE,
            targetParticipantId: '',
            targetParticipantName: ''
        };
        this.sendChatMessage(chatObj);
    }

    async createScreenTrackWithGetUserMedia() {
        if (!this.localScreenShareStream) {
            const screenShareStream = await this.startScreenCapture();
            this.localScreenShareStream = screenShareStream;
        }
        await this.getLocalScreenTracks(this.localScreenShareStream);
    }

    async getLocalScreenTracks(screenShareStream: MediaStream) {
        if (this.enableStreamMixer) {
            await this.createCustomCameraTrack();
            // Screen Audio Track is not required for custom stream.
        } else {
            try {
                console.log(
                    'calling create custom stream track jmmediaclient =',
                    this.customScreenTracks,
                    'screensharestream =',
                    screenShareStream
                );
                this.customScreenTracks = await this.jmMediaClient.createCustomScreenTrack(screenShareStream);
                console.log('custom screen tracks =', this.customScreenTracks);
                this.localScreenTrack = this.customScreenTracks[0];
            } catch (err) {
                console.error(err);
                this.meeting$.next({ type: 'SHARE_STOP', data: { stopShare: true } });
            }

            this.localScreenTrack.getMediaStreamTrack().onended = () => {
                this.meeting$.next({ type: 'SHARE_STOP', data: { stopShare: true } });
            };
        }
        this.localScreenAudioTrack = this.customScreenTracks[1];
    }

    async createScreenShareTrack() {
        if (this.localScreenTrack) return;
        await this.createScreenTrackWithGetUserMedia();
    }

    async toggleWindowShare(enable, deleteScreenTrack = false) {
        console.log('toogle window share called with screenshare =', this.screenSharing);
        console.log('enable = ', enable, 'deletescreensharetrack =', deleteScreenTrack);
        console.log(
            'local screen stream = ',
            this.localScreenShareStream,
            'localuser video track =',
            this.jmMediaClient.localUser.screenShareVideoTrack
        );
        if (enable) {
            try {
                console.log('toogle enabled');
                await this.jmMediaClient.setCustomScreenShareEnabled(this.customScreenTracks);
                this.jmMediaClient.localUser.screenShareVideoTrack?.on('track-ended', async () => {
                    this.meeting$.next({ type: 'SHARE_STOP', data: { stopShare: true } });
                });
                this.participantStatusUpdated.next({
                    type: 'localScreenShareStarted',
                    data: this.localScreenShareStream
                });
                this.screenSharing = true;
                this.sendControlStatus();
                this.sendStartScreenShareRtmMessage();
                //this.externalInterfaceService.sendScreenShareStatus(true);
                return true;
            } catch (e) {
                console.log('publish error');
                throw e;
            }
        } else {
            try {
                console.log('before screenEnabled', this.localScreenShareStream);
                console.log('screen has video', this.localHasScreenVideo);
                if (this.localHasScreenVideo) await this.jmMediaClient.setScreenShareEnabled(false);
                console.log('After screenEnabled', this.localScreenShareStream);
                if (deleteScreenTrack) {
                    await this.stopAndResetStreamMixerStreams();
                    this.localScreenTrack = undefined;
                    this.localScreenShareStream = undefined;
                    this.participantStatusUpdated.next({ type: 'localScreenShareStopped' });
                    // await this.stopClonedMediaStream();
                    // this.clonedScreenStream = undefined;
                    this.localScreenAudioTrack = undefined;
                    this.screenSharing = false;
                }
                this.sendControlStatus();
                //this.externalInterfaceService.sendScreenShareStatus(false);
            } catch (e) {
                console.log('Unpublish error');
                throw e;
            }
        }
        console.log('toogle window share finished with screenshare =', this.screenSharing);
    }

    // async stopClonedMediaStream() {
    //     this.clonedScreenStream?.getTracks().forEach((t) => t.stop());
    // }

    async stopCustomStreamOnDisableCamera() {
        this.isVideoOn.next(false);
        // this.localScreenTrack.stop();
        await this.stopAndResetStreamMixerStreams(true);
        await this.unpublishCustomScreenShareTrack();
        await this.publishScreenShareTrackExclusively();
        // this.enableStreamMixer = false;
    }

    async unpublishCustomScreenShareTrack() {
        this.toggleWindowShare(false);
    }

    async publishScreenShareTrackExclusively() {
        /**
         * This is called only in case of stop custom screen in case of camera.
         */
        const stream = await this.getClonedScreenShareStream(this.localScreenShareStreamBackUp);
        this.localScreenShareStream = stream;
        await this.getLocalScreenTracks(stream);
        await this.jmMediaClient.setCustomScreenShareEnabled(this.customScreenTracks);
    }

    addUserInWaitingRoom(user) {
        if (user.memberName) {
            this.waitingUsers[user.memberId] = user;
            this.sendControlStatus();
        }
    }

    removeUserFromWaitingRoom(user) {
        delete this.waitingUsers[user.memberId];
        this.sendControlStatus();
    }

    setSharingScreenStatus(enable) {
        this.alreadySharingScreen = enable;
    }

    getLocalParticipant() {
        return this.localParticipant;
    }

    private sendControlStatus(updates: any = {}) {
        this.participantsStatus$.next({
            users: this.users,
            microphoneMutedAll: this.microphoneMutedAll,
            roomLocked: this.roomLocked,
            recording: this.recording,
            screenSharing: this.screenSharing,
            waitingUsers: this.waitingUsers,
            alreadySharingScreen: this.alreadySharingScreen,
            vcs: this.vcs,
            participantJoined: updates.participantJoined,
            participantLeft: updates.participantLeft
        });
    }

    clear() {
        this.localParticipant = {};
        this.joinData = {};
        this.users = {};
        this.microphoneMutedAll = false;
        this.roomLocked = false;
        this.recording = false;
        this.screenSharing = false;
        this.reconnecting = false;
        this.whiteboardShared = false;
        this.attendeeInfo = {};
        //     this.localCameraTrack?.close();
        //    this.localCameraTrack = null;
        // this.localMicrophoneTrack?.close();
        // this.localMicrophoneTrack = null;
        clearInterval(this.reConnectInterval);
    }

    async setWhiteboardStatus(state) {
        this.whiteboardShared = state;
        let streamType = this.whiteboardShared ? 1 : 0;
        // this.setRemoteStreamType(streamType);
        // this.isOptimizeOnScreenSharingEnabled && (await this.updateVideoConfig(streamType));
    }

    releaseDevices() {}

    async makeSpeaker(showToast) {
        this.localParticipant.participantType = 'speaker';
        if (!showToast) {
            this.localParticipant.microphoneMute = !this.joinData.micPrivacy;
            this.localParticipant.cameraMute = !this.joinData.cameraPrivacy;
        }
        this.meeting$.next({ type: 'SEND_CONNECTION_STATUS', data: {} });
        this.externalInterfaceService.sendUserStatus(false);
    }

    async makeAudience(showToast) {
        this.localParticipant.participantType = 'audience';
        if (showToast) {
            this.meeting$.next({ type: 'SHOW_DOWNGRADE_AUDIENCE_TOAST', data: {} });
        }
        this.localParticipant.microphoneMute = true;
        this.localParticipant.cameraMute = true;
        this.isVideoOn.next(false);
        await this.unpublishTracks();
        // await this.localCameraTrack?.close();
        // await this.localMicrophoneTrack?.close();
        // this.localCameraTrack = null;
        // this.localMicrophoneTrack = null;
        this.processor = null;
        if (showToast) this.meeting$.next({ type: 'WHITEBOARD_STOP', data: {} });
        this.toggleWindowShare(false, true);
        this.sendControlStatus();
        console.log('participant role', this.jmMediaClient?.roleInfo);
        this.meeting$.next({ type: 'SEND_CONNECTION_STATUS', data: {} });
        this.externalInterfaceService.sendUserStatus(true);
    }

    async unpublishTracks() {
        try {
            if (this.localHasVideo) this.jmMediaClient.setLocalVideoEnabled(false);
            if (this.localHasAudio) this.jmMediaClient.setLocalAudioEnabled(false);
            this.isAudioPublished = false;
            this.isVideoPublished = false;
        } catch (error) {
            console.log('stream unpublish error:', error);
        }
    }

    checkPostion(participants, isHardMute) {
        if (this.breakoutRoomInfo?.breakoutRoomId) {
            return;
        }

        const numberOfSpeaker = participants?.filter((p) => p.participantType === 'speaker').length;
        this.showLimitReached(participants, numberOfSpeaker);
        if (this.isPostionChecked === false) {
            this.externalInterfaceService.sendHardMuteAudiostatus(isHardMute);
        }

        if (!this.localParticipant.isHost) {
            if (this.isPostionChecked === false && !this.externalInterfaceService.isEmbibe) {
                if (numberOfSpeaker < this.SPEAKER_LIMIT) {
                    const index = participants.findIndex((p) => p.participantUri === this.localParticipant.userId);
                    if (participants[index]?.participantType === 'audience' && this.checkJoinAsSpeaker()) {
                        this.makeSpeaker(false);
                    }
                } else {
                    if (this.isLocalParticipantCoHost(participants)) {
                        const index = participants.findIndex((p) => p.participantUri === this.localParticipant.userId);
                        if (participants[index]?.participantType === 'audience' && this.checkJoinAsSpeaker()) {
                            this.makeSpeaker(false);
                        }
                    }
                }

                if (this.localParticipant.participantType === 'audience') {
                    this.meeting$.next({ type: 'SHOW_DOWNGRADE_AUDIENCE_INFO', data: {} });
                }

                this.isPostionChecked = true;
                return;
            }

            if (this.localParticipant.participantType === 'speaker') {
                let speakerList = participants.filter((p) => p.participantType === 'speaker');

                speakerList = this.getParticipantsWithActiveSpeakerValue(speakerList);

                const speakerListLength = speakerList.length;

                if (speakerListLength > this.SPEAKER_LIMIT) {
                    const localPfromL = this.getLocalParticipantFromParticipants(speakerList);

                    if (!localPfromL.isHost) {
                        if (!localPfromL.isSharingScreen) {
                            //  if(!this.checkIfLocalParticipantActive(localPfromL)){
                            if (!localPfromL.isActiveSpeaker) {
                                if (!localPfromL.isCoHost) {
                                    const speakerList1 = speakerList.filter(
                                        (s) => !s.isHost && !s.isSharingScreen && !s.isActiveSpeaker && !s.isCoHost
                                    );

                                    let multidevicelist = speakerList1.filter((s) => s.userId === localPfromL.userId);
                                    if (multidevicelist.length > 1) {
                                        this.sendMakeAudience(multidevicelist);
                                    } else {
                                        const speakerlist2 = speakerList.filter(
                                            (s) => !s.isHost && !s.isSharingScreen && !s.isActiveSpeaker && s.isCoHost
                                        );
                                        if (this.toFindDuplicates(speakerlist2)?.length === 0) {
                                            this.sendMakeAudience(speakerList1);
                                        }
                                    }
                                } //else if is cohost
                                else {
                                    let cohostmultidevicelist = speakerList.filter(
                                        (s) => s.userId === localPfromL.userId
                                    );

                                    if (cohostmultidevicelist.length > 1) {
                                        this.sendMakeAudience(cohostmultidevicelist);
                                    } else {
                                        if (cohostmultidevicelist.length === 1) {
                                            let cohostlist = speakerList.filter(
                                                (s) =>
                                                    !s.isHost && !s.isSharingScreen && !s.isActiveSpeaker && !s.isCoHost
                                            );
                                            if (cohostlist.length === 0) {
                                                let cohostlist = speakerList.filter(
                                                    (s) =>
                                                        !s.isHost &&
                                                        !s.isSharingScreen &&
                                                        !s.isActiveSpeaker &&
                                                        s.isCoHost
                                                );
                                                this.sendMakeAudience(cohostlist);
                                            }
                                        }
                                    }
                                }
                            } //else if all activ
                        }
                    }
                }
            }
        }
        this.isPostionChecked = true;
    }

    checkForSpotlightUpdate(spotlightedParticipantsLatest) {
        if (!_.isEqual(this.spotlightedUserIds, spotlightedParticipantsLatest)) {
            // this.isSpotlighted = spotlightedParticipantsLatest.length > 0;
            this.updateSpotlightedParticipants(spotlightedParticipantsLatest);
        }
    }

    showLimitReached(participants, numberOfSpeaker) {
        if (numberOfSpeaker >= this.SPEAKER_LIMIT) {
            if (
                (this.localParticipant.isHost || this.isLocalParticipantCoHost(participants)) &&
                this.showedLimitReached
            ) {
                this.meeting$.next({ type: 'SHOW_LARGE_MEETINGINFO', data: {} });
                this.showedLimitReached = false;
            }
        }
    }

    sendMakeAudience(speakerList) {
        speakerList = speakerList.sort(this.sortFunction);
        const participantToDemote = speakerList[speakerList.length - 1];
        if (participantToDemote.participantUri === this.localParticipant.userId) {
            if (participantToDemote.isCoHost) {
                this.meeting$.next({
                    type: 'DOWNGRADE_CO_HOST',
                    data: { userId: participantToDemote.userId }
                });
            }
            this.makeAudience(true);
        }
    }

    isLocalParticipantCoHost(participants) {
        let localListParticipant = participants.find(
            (p) => p.isCoHost === true && p.participantUri === this.localParticipant.userId
        );
        if (localListParticipant) {
            let numberofcohost = participants.filter((p) => p.userId === localListParticipant.userId);

            if (numberofcohost.length > 1) {
                return false;
            } else {
                if (numberofcohost.length === 1) {
                    return true;
                }
            }
        } else {
            return false;
        }
    }

    sortFunction(a, b) {
        var dateA = new Date(a.participantJoinTime).getTime();
        var dateB = new Date(b.participantJoinTime).getTime();
        return dateA > dateB ? 1 : -1;
    }

    checkIfLocalParticipantActive(localPfromL) {
        return this.activeSpeakerList.findIndex((elment) => elment.uid === localPfromL.participantUri) === -1
            ? false
            : true;
    }

    getParticipantsWithActiveSpeakerValue(list) {
        for (let index = 0; index < list.length; index++) {
            if (this.activeSpeakerList.findIndex((a) => a.uid === list[index].participantUri) > -1) {
                list[index]['isActiveSpeaker'] = true;
            } else {
                list[index]['isActiveSpeaker'] = false;
            }
        }

        return list;
    }

    getLocalParticipantFromParticipants(list) {
        return list.find((l) => l.participantUri === this.localParticipant.userId);
    }

    setFitToScreen(value) {
        this.isFitToScreen = value;
        this.participantStatusUpdated.next({ type: 'fitToScreen', data: { value } });
    }

    enabledHDScreenShare(value) {
        this.isEnableHDshare = value;
    }

    toFindDuplicates(arry) {
        const lookup = arry.reduce((a, e) => {
            a[e.userId] = ++a[e.userId] || 0;
            return a;
        }, {});

        return arry.filter((e) => lookup[e.userId]);
    }

    isPermissionError(err) {
        return err.code === 'PERMISSION_DENIED' || (err instanceof DOMException && err.name === 'NotAllowedError');
    }

    isSystemPermissionError(err) {
        return this.isPermissionError(err) && err?.message?.indexOf('system') > -1;
    }

    spotLightUser(participantId) {
        if (!this.spotlightedUserIds.includes(participantId)) {
            this.spotlightedUserIds.push(participantId);
            this.participantStatusUpdated.next({
                type: 'spotlight',
                data: {
                    spotlightedUserIds: this.spotlightedUserIds
                    // add or remove
                }
            });
        }
    }

    updateSpotlightedParticipants(newSpotlightees) {
        this.spotlightedUserIds = newSpotlightees;
        this.participantStatusUpdated.next({
            type: 'spotlight',
            data: {
                spotlightedUserIds: this.spotlightedUserIds
                // add or remove type action.
            }
        });
    }

    isParticipantSpotlighted(pId) {
        return this.roomData?.spotlightedParticipants?.findIndex((spId) => spId === pId) > -1;
    }

    // for playing locally on selected divs
    async toggleCameraForSetting(divId, status) {
        if (divId == 'preview-renderer') {
            console.log('entering into the camera log', this.previewInstance.localUser?.hasVideo);
            if (!this.previewInstance.localUser?.hasVideo) {
                await this.previewInstance.createPreview({
                    trackSettings: {
                        ...this.jmMediaClient.localUserSettings.trackSettings,
                        isVideoEnabled: true
                    },
                    virtualBackgroundSettings: {
                        ...this.virtualBackgroundInstance.localUserSettings.virtualBackgroundSettings
                    }
                });
                await this.previewInstance.localUser.videoTrack?.play(divId);
            } else this.previewInstance.setVideoEnabled(false);
        } else if (divId == 'video-preview') {
            if (!this.virtualBackgroundInstance) this.virtualBackgroundInstance = new Preview();
            if (!this.virtualBackgroundInstance.localUser?.hasVideo) {
                await this.virtualBackgroundInstance.createPreview({
                    trackSettings: {
                        ...this.jmMediaClient.localUserSettings.trackSettings,
                        isVideoEnabled: true
                    }
                });
                await this.virtualBackgroundInstance.localUser.videoTrack?.play(divId);
            }
        }
    }

    //Set bg blur
    async setBackgroundBlurring() {
        if (this.virtualBackgroundInstance.localUser?.hasVideo) {
            try {
                await this.virtualBackgroundInstance.setVirtualBackground({
                    sourceType: 'blur',
                    sourceValue: '10'
                });
            } catch (e) {
                console.log(e);
            }
        }
    }

    // initiate virtual background instances
    createVbInstances() {
        if (!this.previewInstance) this.previewInstance = new Preview();
        if (!this.virtualBackgroundInstance) this.virtualBackgroundInstance = new Preview();
    }

    // Set an image as the background
    async setBackgroundImage(url = '', addChroma = false) {
        if (this.virtualBackgroundInstance.localUser?.hasVideo) {
            try {
                await this.virtualBackgroundInstance.setVirtualBackground({
                    sourceType: 'image',
                    sourceValue: url
                });
            } catch (error) {
                console.log('error', error);
            }
        }
    }

    async stopPreviewVb() {
        if (this.previewInstance.localUser.videoTrack) {
            this.previewInstance.setVideoEnabled(false);
        }
    }

    async stopVirtualBackgroundInstance() {
        if (this.virtualBackgroundInstance.localUser.videoTrack) {
            this.virtualBackgroundInstance.setVideoEnabled(false);
        }
    }

    async confirmVbSetting() {
        if (this.jmMediaClient.localUserSettings?.userRole)
            await this.jmMediaClient.updateLocalUserTrack({
                trackSettings: { ...this.jmMediaClient.localUserSettings.trackSettings },
                virtualBackgroundSettings: {
                    ...this.virtualBackgroundInstance.localUserSettings.virtualBackgroundSettings
                }
            });
        await this.previewInstance.updateLocalUserTrack({
            trackSettings: { ...this.jmMediaClient.localUserSettings.trackSettings },
            virtualBackgroundSettings: {
                ...this.virtualBackgroundInstance.localUserSettings.virtualBackgroundSettings
            }
        });
        this.vbService.backgroundConfig.type =
            this.virtualBackgroundInstance.localUserSettings.virtualBackgroundSettings.sourceType;
        this.vbService.backgroundConfig.imageUrl =
            this.virtualBackgroundInstance.localUserSettings.virtualBackgroundSettings.sourceValue;
        this.participantStatusUpdated.next({
            type: 'locaUserCameraChange',
            data: this.localParticipant
        });
    }

    async disableBg() {
        try {
            await this.virtualBackgroundInstance.removeVirtualBackground();
        } catch (error) {}
    }
    removeRecentChatMessage() {
        this.chatMessages$.next(null);
    }

    stopBackUpScreenShareStream() {
        if (this.localScreenShareStream) {
            this.localScreenShareStream.getTracks().forEach((track) => {
                track?.stop();
            });
            this.localScreenShareStream = null;
        }
        this.localScreenShareStreamBackUp?.getTracks().forEach((track) => {
            track?.stop();
        });
        this.localScreenShareStreamBackUp = null;
        this.clonedStreams.forEach((stream) => {
            stream?.getTracks().forEach((t) => {
                t?.stop();
            });
        });
        this.clonedStreams = [];
    }
}
