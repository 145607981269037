export const APP_EVENTS = {
    AUTHORIZED: 'AUTHORIZED',
    LOGOUT: 'LOGOUT',
    SESSION_EXPIRED: 'SESSION_EXPIRED',
    GUEST_JOIN: 'GUEST_JOIN',
    TOP_NAV_SEARCH_QUERY_CHANGE: 'TOP_NAV_SEARCH_QUERY_CHANGE',
    ACTIVATE_CONFERENCE: 'ACTIVATE_CONFERENCE',
    CONFERENCE_ENDED: 'CONFERENCE_ENDED',
    PARTICIPANT_REFRESH: 'PARTICIPANT_REFRESH',
    ACTIVE_CALL_ON_ANOTHER_DEVICE: 'ACTIVE_CALL_ON_ANOTHER_DEVICE',
    SEARCH_TARGET_CHANGE: 'SEARCH_TARGET_CHANGE',
    EXTENSION_REQUIRED: 'EXTENSION_REQUIRED',
    MEETING_SCHEDULED: 'MEETING_SCHEDULED',
    VIEW_PARTICIPANTS: 'VIEW_PARTICIPANTS',
    HIDE_PARTICIPANTS: 'HIDE_PARTICIPANTS',
    SHOW_CHAT: 'SHOW_CHAT',
    HIDE_CHAT: 'HIDE_CHAT',
    SHOW_UNSEEN_COUNT: 'SHOW_UNSEEN_COUNT',
    UPDATE_LIVE_POLL_COUNT: 'UPDATE_LIVE_POLL_COUNT',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    USER_REQUESTING: 'USER_REQUESTING',
    SHOW_INVITE_POPUP: 'SHOW_INVITE_POPUP',
    SHOW_INVITE_CODE_FORM_POPUP: 'SHOW_INVITE_CODE_FORM_POPUP',
    SHOW_PREVIEW: 'SHOW_PREVIEW',
    SHOW_WAITING_ROOM_POPUP: 'SHOW_WAITING_ROOM_POPUP',
    MEETING_DATA: 'MEETING_DETAILS',
    ACTIVATE_ROOM_SETTINGS: 'ACTIVATE_ROOM_SETTINGS',
    OPEN_FILE_INFO: 'OPEN_FILE_INFO',
    REFRESH_BREAKOUT_INFO: 'REFRESH_BREAKOUT_INFO',
    REFRESH_MEETINGS: 'REFRESH_MEETINGS',
    DATE_CHANGE: 'DATE_CHANGE',
    EDIT_MEETING: 'EDIT_MEETING',
    NAME_CHANGED: 'NAME_CHANGED',
    JWT_TOKEN: 'JWT_TOKEN',
    JM_MEDIA_JOIN_FAILED: 'JM_MEDIA_JOIN_FAILED',
    JM_MAX_LIMIT_REACHED: 'JM_MAX_LIMIT_REACHED',
    JM_MEDIA_RECORDER_JOIN_FAILED: 'JM_MEDIA_RECORDER_JOIN_FAILED',
    NATIVE_BACK_BUTTON: 'NATIVE_BACK_BUTTON',
    NATIVE_FORWARD_BUTTON: 'NATIVE_FORWARD_BUTTON',
    APP_IN_BACKGROUND: 'APP_IN_BACKGROUND',
    SOCKET_RECONNECTED: 'SOCKET_RECONNECTED',
    VB_WORKER_STATES: {
        START_TIMEOUT: 'start',
        STOP_TIMEOUT: 'stop',
        TIMEOUT_TICK: 'tick'
    },
    PAGE_RESIZED: 'PAGE_RESIZED',
    ERRORS: {
        NO_CAMERA_PERMISSION: 'no_camera_permission',
        GENERIC_CAMERA_ERROR: 'generic_camera_error',
        NO_AUDIO_PERMISSION: 'no_audio_permission',
        GENERIC_AUDIO_ERROR: 'generic_audio_error',
        AGORA_JOIN_ERROR: 'agora_join_error'
    },
    TOGGLE_CALL_CONTROLS: 'TOGGLE_CALL_CONTROLS',
    LOCAL_PARTICIPANT_SPOTLIGHTED: 'LOCAL_PARTICIPANT_SPOTLIGHTED',
    VIEW_SPOTLIGHT_LIST: 'VIEW_SPOTLIGHT_LIST',
    LOCAL_USER_JOINED: 'LOCAL_USER_JOINED',
    REFRESH_LIVE_STREAMS: 'REFRESH_LIVE_STREAMS',
    SHOW_PERMISSION_UI: 'SHOW_PERMISSION_UI',
    OPEN_VC_DIALOG: 'OPEN_VC_DIALOG',
    POLLS: {
        VIEW_POLL: 'VIEW_POLL',
        CLOSE_POLL: 'CLOSE_POLL'
    },
    ADD_TO_CAL_CLICK: 'ADD_TO_CAL_CLICK',
    IN_CALL_JOIN_BACKSTAGE: 'JOIN_BACKSTAGE',
    EVENT_ENDED: 'EVENT_ENDED',
    USER_LEFT: 'USER_LEFT',
    ATTENDEE: {
        NETWORKING_ROOM_SELECTED: 'ATTENDEE_NETWORKING_ROOM_SELECTED',
        NO_AUTH_FOR_NETWORKING_LIST: 'NO_AUTH_FOR_NETWORKING_LIST',
        NETWORKING_ROOM_REFRESH: 'NETWORKING_ROOM_REFRESH',
        SWITCH_NETWORKING_ROOM: 'SWITCH_NETWORKING_ROOM',
        ACCEPT_NWROOM_INVITATION: 'ACCEPT_NWROOM_INVITATION',
        LEAVE_TO_JOIN_ANOTHER_EVENT: 'LEAVE_TO_JOIN_ANOTHER_EVENT'
    },

    NETWORKING: {
        LEAVE: 'NW_LEAVE',
        JOIN: 'NW_JOIN',
        HIDE_CALL_PREVIEW: 'NW_HIDE_CALL_PREVIEW'
    },
    CALL: {
        LEAVE: 'CALL_LEAVE',
        JOIN: 'CALL_JOIN',
        HIDE_CALL_PREVIEW: 'HIDE_CALL_PREVIEW'
    },
    LEAVE: 'LEAVE',
    TOGGLE_PIP_MODE: 'togglePipMode',
    ENABLE_PIP_MODE: 'ENABLE_PIP_MODE',
    DISABLE_PIP_MODE: 'DISABLE_PIP_MODE',
    NAVIGATE_TO_LANDING_PAGE: 'NAVIGATE_TO_LANDING_PAGE',
    NAVIGATE_TO_SCHEDULE: 'NAVIGATE_TO_SCHEDULE',
    HIDE_CALL_OPTIONS: 'HIDE_CALL_OPTIONS',
    CALL_OPTIONS_TOGGLED: 'CALL_OPTIONS_TOGGLED',
    FULL_SCREEN_MODE: 'FULL_SCREEN_MODE',
    JOIN_ERROR: 'JOIN_ERROR',
    NAVIGATE_TO_NETWORKING_LOUNGE: 'NAVIGATE_TO_NETWORKING_LOUNGE',
    NAVIGATE_TO_MAIN_STAGE: 'NAVIGATE_TO_MAIN_STAGE',
    UPDATE_CALL_CLOSE_TIMER: 'UPDATE_CALL_CLOSE_TIMER',
    SHOW_CALL_PREVIEW: 'SHOW_CALL_PREVIEW',
    SHOW_ATTENDEE_STAGE_PREVIEW: 'SHOW_ATTENDEE_STAGE_PREVIEW',
    HANDLE_REMIND_SESSIONS: 'HANDLE_REMIND_SESSIONS',
    EVENT_BROADCAST_ENDED: 'EVENT_BROADCAST_ENDED',
    NAVIGATE_TO_MAINSTAGE_ON_SESSION_SELECT: 'NAVIGATE_TO_MAINSTAGE_ON_SESSION_SELECT',
    NAVIGATE_TO_MAINSTAGE_ON_PRERECORD_SESSION_SELECT: 'NAVIGATE_TO_MAINSTAGE_ON_PRERECORD_SESSION_SELECT',
    HIDE_SCHEDULE_NAV_TAB: 'HIDE_SCHEDULE_NAV_TAB',
    HIDE_MAINSTAGE_NAV_TAB: 'HIDE_MAINSTAGE_NAV_TAB',
    WAITING_ROOM: {
        EXIT: 'EXIT_WAITING_ROOM',
        ENTER: 'ENTER_WAITING_ROOM'
    },
    JOIN_BEFORE_HOST: {
        EXIT: 'EXIT_JOIN_BEFORE_HOST'
    },
    SHOW_THANK_YOU_PAGE: 'SHOW_THANK_YOU_PAGE',
    NAVIGATED_TO_OTHER_HEADER_NAV_TAB: 'NAVIGATED_TO_OTHER_HEADER_NAV_TAB',
    NAVIGATING_TO_MAIN_STAGE: 'NAVIGATING_TO_MAIN_STAGE',
    UPDATE_MAINSTAGE_ON_LEAVE: 'UPDATE_MAINSTAGE_ON_LEAVE',
    GET_WEBINAR_INFO: 'GET_WEBINAR_INFO',
    MYEVENT_ACTION_MENU_CLICK: 'MYEVENT_ACTION_MENU_CLICK',
    NWROOMS_CLOSED: 'NWROOMS_CLOSED',
    REMOVE_LOADER_MASK: 'REMOVE_LOADER_MASK',
    OPEN_ADD_TO_CAL: 'OPEN_ADD_TO_CAL',
    CLOUD_MEDIA_ENDED: 'CLOUD_MEDIA_ENDED',
    CREATE_MEETING: 'CREATE_MEETING',
    CREATE_PRERECORD_MEETING: 'CREATE_PRERECORD_MEETING',
    AUTO_JOIN: 'AUTO_JOIN',
    MOBILE_HEADER_MENU_CLICK: 'MOBILE_HEADER_MENU_CLICK',
    CLOSE_ADD_TO_CAL_CLICK: 'CLOSE_ADD_TO_CAL_CLICK',
    SHOW_LIVE_CHAT: 'SHOW_LIVE_CHAT',
    HIDE_LIVE_CHAT: 'HIDE_LIVE_CHAT',
    REFRESH_ANALYTICS_DATA: 'REFRESH_ANALYTICS_DATA',
    SHOW_GAMES: 'SHOW_GAMES',
    HIDE_GAMES: 'HIDE_GAMES',
    REFRESH_TEMPLATES: 'REFRESH_TEMPLATES'
};
