import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Subject } from 'rxjs';
import { APP_CONSTANT, APP_EVENTS, MEDIA_ENGINE_EVENTS } from 'src/app/constants';
import { RtcService } from './rtc.service';
// import AgoraRTM, { RtmChannel, RtmClient } from 'agora-rtm-sdk';
import { UtilService } from './util.service';
import { UserService } from './user.service';
import { ExternalInterfaceService } from './external-interface.service';
import { VirtualBackgroundService } from './virtual-background.service';
import { AppService } from '.';
import { AppLoggerService } from './app-logger.service';
import { EventEmitterService } from './event-emitter.service';
import * as _ from 'lodash';
import { CheckJoinAsSpeaker } from '../helpers/meeting-check-util';
import { MultiStreamsMixer } from '../classes/multi-stream-mixer';
import { EventsPlusService } from './events-plus.service';
import { WebinarService } from 'src/app/dashboard-webinars/services';
import { AttendeeViewStateService } from 'src/app/webinar-attendee/attendee-view-state.service';
// import VirtualBackgroundExtension from "agora-extension-virtual-background";

// import {
//   IAgoraRTCClient,
//   IMicrophoneAudioTrack,
//   ICameraVideoTrack
// } from 'agora-rtc-sdk-ng';

declare var VC: any;
declare const window;

enum ConnectionState {
    DISCONNECTED = 'DISCONNECTED',
    CONNECTING = 'CONNECTING',
    RECONNECTING = 'RECONNECTING',
    CONNECTED = 'CONNECTED',
    DISCONNECTING = 'DISCONNECTING'
}

// declare var AgoraRTC: any;

@Injectable({
    providedIn: 'root'
})
export class AgoraService {
    vidyoConnector;
    AgoraRTC = null;
    libraryLoaded = false;
    isAudioPublished = false;
    isVideoPublished = false;
    currentUser;
    localParticipant;
    joinData;
    reconnecting = false;
    isUserOnline = true;

    users = {};
    vcs = {};
    galleryParticipants = [];
    nonGalleryParticipants = [];
    microphoneMutedAll = false;
    roomLocked = false;
    recording = false;
    screenSharing = false;
    waitingUsers = {};
    cameras = [];
    microphones = [];
    speakers = [];
    selectedLocalCamera = 0;
    selectedLocalMicrophone = 0;
    selectedLocalSpeaker = 0;

    oldSelectedLocalCamera = 0;
    oldSelectedLocalMicrophone = 0;
    oldSelectedLocalSpeaker = 0;

    private disconneting = false;
    private alreadySharingScreen = false;
    private windowShares = {};
    private whiteboardShared;
    private isOptimizeOnScreenSharingEnabled = false;

    private meeting$: BehaviorSubject<any> = new BehaviorSubject(null);
    private chatMessages$: BehaviorSubject<any> = new BehaviorSubject(null);
    private deviceChanged$: BehaviorSubject<any> = new BehaviorSubject(null);
    private participantsStatus$: BehaviorSubject<any> = new BehaviorSubject(null);

    private rtmMessages$: BehaviorSubject<any> = new BehaviorSubject(null);

    reConnectInterval;
    localVolumeIndicator;

    MAX_VIDEO_TILES = 9;
    MAX_VIDEO_TILES_IN_SCREEN_SHARING = 4;

    SPEAKER_LIMIT = 25;
    MAX_HOST_LIMIT = 1;

    isConnected = new Subject<boolean>();
    isVideoOn = new Subject<boolean>();

    videoDevicesUpdated = new Subject<boolean>();
    audioDevicesUpdated = new Subject<boolean>();

    localParticipantUpdated = new Subject<boolean>();
    public participantAdded = new Subject<any>();
    public participantRemoved = new Subject<any>();

    public participantStatusUpdated = new Subject<any>();
    public studioUpdates = new Subject<any>();

    errors = new Subject<any>();

    meetingObj;

    roomData;
    isHandRaise = false;

    groupId = '';
    callOptionsInternal = {
        audioOptions: {
            isMuted: true,
            audioOutputDeviceId: '',
            audioInputDeviceId: ''
        },
        videoOptions: {
            isVideoOn: false,
            videoDeviceId: ''
        }
    };
    displayName = '';
    streams: any = [];
    streamsCollection = [];
    remoteParticipants = [];
    localVideoStream;
    customCollection = [];

    visibleParticipants = [];
    inVisibleParticipants = [];
    //agora variables
    agoraClient;
    agoraRtmClient: any;
    agoraRtmChannel: any;
    localMicrophoneTrack: any;
    localCameraTrack: any = null;
    conferenceInfo;
    joiningRoom: boolean = false;
    public joinedInRoom: boolean = false;
    screenSharingStream: any;

    agoraVideoProfiles = [
        '120p_1',
        '120p_3',
        '180p_1',
        '180p_3',
        '180p_4',
        '240p_1',
        '240p_3',
        '240p_4',
        '360p_1',
        '360p_3',
        '360p_4',
        '360p_6',
        '360p_7',
        '360p_8',
        '360p_9',
        '360p_10',
        '360p_11',
        '480p_1',
        '480p_2',
        '480p_3',
        '480p_4',
        '480p_6',
        '480p_8',
        '480p_9',
        '480p_10',
        '720p_1',
        '720p_2',
        '720p_3',
        '720p_5',
        '720p_6',
        '1080p_1',
        '1080p_2',
        '1080p_3',
        '1080p_5',
        '1440p',
        '1440p_1',
        '1440p_2',
        '4K_1',
        '4K_3'
    ];

    agoraBandwidthConfig = {
        video: {
            width: 840,
            height: 480,
            fps: 15,
            bitrate: 0,
            orientation: 0
        },
        screenShare: {
            width: 1920,
            height: 1080,
            fps: 5,
            bitrate: 0,
            orientation: 0,
            hdfps: 15,
            hdwidth: 1920,
            hdheight: 1080
        },
        lowQualityVideo: {
            width: 320,
            height: 180,
            fps: 10,
            bitrate: 140,
            orientation: 0
        }
    };

    screenClient: any;

    screenClientUid = 0;

    screenClientToken = '';

    roomInfo;

    currentSenderId = '';

    currentUid = 0;
    currentRoomID = '';
    currentRtmToken = '';

    localScreenTrack;
    localScreenAudioTrack;

    isBreakoutRoom = false;

    noMediaPermission = false;
    noAudioPermission = false;
    noCameraPermission = false;

    leaveBreakoutRoom = false;
    agoraToken = '';

    isPostionChecked = false;

    activeSpeakerList = [];

    breakoutRoomInfo;

    localCameraOn = true;
    localMicOn = true;
    isScreenShareAvaiable = false;

    isFitToScreen = true;
    currentConnectionState;
    previousConnectionState;

    reconnectPopupTimer;

    canJoinAsSpeaker = false;

    currentSpeakerCount = 0;

    showedLimitReached = true;

    showHDScreenShareOption = false;
    isEnableHDshare = false;
    attendeeInfo: any = {};
    isAttendee: boolean = false; // Depends on the type of room they are joining.
    isAutoJoinRecordingAttendee: boolean = false;
    currentWebinarId: any;
    rtcSync = false;
    isVirtualBackgroundApplied = false;
    audioLevel = new Subject();

    spotlightedUserIds = [];
    isSpotlighted: boolean;
    noAudioPermissionBrowser: boolean;
    noAudioPermissionDevice: boolean;
    processingToggleMic: boolean = false;
    processingToggleCamera: boolean = false;
    virtualBackgroundExtension;
    processor = null;
    micState: boolean = false;
    cameraState: boolean = false;
    subscriptionTimeOuts = [];
    screenShareData: any;
    isPaused: boolean;
    unprocessedVideoPublishEvents = [];
    streamsMixer: MultiStreamsMixer;
    enableStreamMixer: boolean = false;
    pptSlideImages = [];
    streamMixerType: 'ppt' | 'screen' = 'screen';
    canUserCustomStream: boolean = false;
    localScreenShareStream: any;
    localCameraStream: any;
    greenScreenImage = 'assets/images/green-screen.jpg';
    cloudVideoStream: any;
    typeOfVideoShare = '';
    isEventPreviewViewer: boolean;

    constructor(
        private rtcService: RtcService,
        private utilService: UtilService,
        private externalInterfaceService: ExternalInterfaceService,
        private userService: UserService,
        private vbService: VirtualBackgroundService,
        private appService: AppService,
        private appLoggerService: AppLoggerService,
        private eventEmitterService: EventEmitterService,
        private eventsPlusService: EventsPlusService,
        private attendeeViewStateService: AttendeeViewStateService
    ) {}

    getMeetingObs() {
        return this.meeting$;
    }

    getChatMessages() {
        return this.chatMessages$;
    }

    getDeviceCahnge() {
        return this.deviceChanged$;
    }

    getParticipantStatus() {
        return this.participantsStatus$;
    }

    getRtmMessages() {
        return this.rtmMessages$;
    }

    setAttendeeInfo(info) {
        this.attendeeInfo = info;
        this.isAttendee = true;
        this.isAutoJoinRecordingAttendee = this.attendeeInfo.isRecordingAttendee;
        this.currentWebinarId = this.attendeeInfo.currentWebinarId;
    }

    intializeValue = () => {
        this.users = {};
        this.cameras = [];
        this.microphones = [];
        this.speakers = [];
        this.selectedLocalCamera = 0;
        this.selectedLocalMicrophone = 0;
        this.selectedLocalSpeaker = 0;
        this.disconneting = false;
        this.reconnecting = false;
    };

    isBehindProxy() {
        var proxyHeader = 'via';
        var req = new XMLHttpRequest();
        // req.open('GET', '/api/roomstatus', false); // for local
        req.open('GET', window.location, false); // for production
        req.send();
        var header = req.getResponseHeader(proxyHeader);
        if (header) {
            console.log('is behind proxy');
            // we are on a proxy
            return true;
        }
        return false;
    }

    async createAgoraRTC(dest = null) {
        await import('agora-rtc-sdk-ng').then(async (m) => {
            console.log(m);
            this.AgoraRTC = m.default;
        });
    }

    async initializeVidyoConnector(dest = null) {
        if (dest === 'attendeestage') {
            this.agoraClient = null;
            this.isAttendee = dest === 'attendeestage';
        }
        if (this.agoraClient) return;
        if (this.AgoraRTC === null) {
            await this.createAgoraRTC();
        }
        this.currentUser = this.userService.getUserSync();
        this.AgoraRTC.enableLogUpload();
        // Set the log output level as INFO
        this.AgoraRTC.setLogLevel(4);
        // this.AgoraRTC.setArea({
        //   areaCode: "INDIA"
        // }) //removing geofencing
        this.agoraClient = this.AgoraRTC.createClient({ codec: 'vp8', mode: 'live' });

        const EVENT_REPORT_SEND_INTERVAL = this.appService.getConfigVariable('AGORA_EVENT_REPORT_SEND_INTERVAL');
        if (EVENT_REPORT_SEND_INTERVAL) {
            this.AgoraRTC.setParameter('EVENT_REPORT_SEND_INTERVAL', EVENT_REPORT_SEND_INTERVAL);
        }
        var enableCloudProxy = this.appService.getConfigVariable('ENABLE_AGORA_CLOUD_PROXY');
        var cloudProxyValue = this.appService.getConfigVariable('CLOUD_PROXY_VALUE')
            ? this.appService.getConfigVariable('CLOUD_PROXY_VALUE')
            : 5;
        if (enableCloudProxy && this.isBehindProxy()) {
            // 3 is for udp cloud proxy
            this.agoraClient.startProxyServer(cloudProxyValue);
        }
        if (this.joinData?.isInitiater) {
            this.agoraClient.setClientRole('host');
            this.externalInterfaceService.sendUserStatus(false);
        } else {
            this.agoraClient.setClientRole('audience');
        }
        let width = this.agoraBandwidthConfig.lowQualityVideo.width;
        let height = this.agoraBandwidthConfig.lowQualityVideo.height;
        let framerate = this.agoraBandwidthConfig.lowQualityVideo.fps;
        let bitrate = this.agoraBandwidthConfig.lowQualityVideo.bitrate;
        try {
            var cInfo = this.conferenceInfo || this.rtcService.getConferenceInfo();
            width = cInfo.agoraBandwidthConfig.lowQualityVideo.width;
            height = cInfo.agoraBandwidthConfig.lowQualityVideo.height;
            framerate = cInfo.agoraBandwidthConfig.lowQualityVideo.fps;
            bitrate = cInfo.agoraBandwidthConfig.lowQualityVideo.bitrate;
        } catch (e) {
            console.log('error fetching lowQualityVideo from config');
        }
        try {
            this.showHDScreenShareOption = this.conferenceInfo.agoraBandwidthConfig.screenShare.ishdsharingenabled;
        } catch (e) {
            console.log('unable to get config');
        }
        this.agoraClient
            .enableDualStream()
            .then(() => {
                console.log('Enable Dual stream success!');
                this.agoraClient.setLowStreamParameter({
                    width: width,
                    height: height,
                    framerate: framerate,
                    bitrate: bitrate
                });
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(async () => {
                if (!this.isAttendee) {
                    await this.updateAllDevices();
                    await this.onDeviceChangeListener();
                }
                if ((this.rtcService.getPreparatorySetupDone() || this.isAttendee) && !!dest) {
                    this.setPrivacy(dest);
                    await this.joinRoom(dest);
                }
                this.meeting$.next({ type: 'SHOW_PREVIEW_CNTROLS', data: null });
            });
    }

    private setPrivacy(dest = null) {
        if (this.isAttendee) return;
        this.toggleMicPrivacy(!this.joinData.micPrivacy);
        if (this.joinData.cameraPrivacy) {
            this.toggleCameraPrivacy(false);
        }
    }

    flipCamera() {
        const selectedCamera = this.selectedLocalCamera;
        let newCameraId;
        if (this.cameras.length > 1) {
            for (let i = 0; i < this.cameras.length; i++) {
                if (this.cameras[i].id === selectedCamera) {
                    if (i + 1 >= this.cameras.length) newCameraId = this.cameras[0];
                    else newCameraId = this.cameras[i + 1];
                }
            }

            if (newCameraId) {
                this.manuallyChangeCamera(newCameraId);
            } else {
                this.manuallyChangeCamera(this.cameras[0]);
            }
        }
    }

    setJoinData({
        host,
        displayName,
        roomKey,
        roomPin,
        micPrivacy,
        cameraPrivacy,
        isInitiater = false,
        viewId = 'localVideo',
        isEmbedInMobile = false,
        unlockAndJoin = false,
        meetingObj = null,
        userId = ''
    }) {
        this.joinData = {
            host,
            displayName,
            roomKey,
            roomPin,
            micPrivacy,
            cameraPrivacy,
            isInitiater,
            viewId,
            isEmbedInMobile,
            unlockAndJoin,
            meetingObj,
            userId
        };
    }

    userTalkListener(callback): void {}

    updateAllDevices() {
        return new Promise<void>((resolve, reject) => {
            Promise.all([
                this.AgoraRTC.getCameras().catch((errorInfo) => {
                    return {
                        hasError: true,
                        error: errorInfo
                    };
                }),
                this.AgoraRTC.getMicrophones().catch((errorInfo) => {
                    return {
                        hasError: true,
                        error: errorInfo
                    };
                }),
                this.AgoraRTC.getPlaybackDevices().catch((errorInfo) => {
                    return {
                        hasError: true,
                        error: errorInfo
                    };
                })
            ])
                .then((data) => {
                    if (data[0]?.hasError) {
                        this.appLoggerService.error('Unable to load Camera', data[0]?.error);
                    } else {
                        this.cameras = data[0];
                    }
                    this.videoDevicesUpdated.next(true);
                    if (data[1]?.hasError) {
                        this.appLoggerService.error('Unable to load Microphone', data[1]?.error);
                    } else {
                        this.microphones = data[1];
                    }
                    if (data[2]?.hasError) {
                        this.appLoggerService.error('Unable to load Playback Devices', data[2]?.error);
                    } else {
                        this.speakers = data[2];
                    }
                    this.audioDevicesUpdated.next(true);
                    this.noMediaPermission = false;
                    this.cameras = this.mapDeviceCollection(this.cameras);
                    this.microphones = this.mapDeviceCollection(this.microphones);
                    this.speakers = this.mapDeviceCollection(this.speakers);

                    console.log(this.cameras, this.microphones, this.speakers);
                    this.sendControlStatus();
                    this.selectDevices();
                })
                .catch((error) => {
                    console.log(error);
                    this.noMediaPermission = true;
                    resolve();
                })

                .finally(() => {
                    resolve();
                });
        });
    }

    async onDeviceChangeListener() {
        this.AgoraRTC.onCameraChanged = async (info) => {
            console.log(info.state);
            this.cameras = this.mapDeviceCollection(await this.AgoraRTC.getCameras());

            if (info.state === 'INACTIVE') {
                if (this.cameras.length > 0) {
                    if (String(this.selectedLocalCamera) === info.device.deviceId) {
                        this.oldSelectedLocalCamera = this.selectedLocalCamera;
                        this.selectedLocalCamera = this.cameras[0].id;
                        await this.localCameraTrack.setDevice(String(this.selectedLocalCamera));
                    }
                } else {
                    this.oldSelectedLocalCamera = this.selectedLocalCamera;
                    this.toggleCameraPrivacy(true);
                }
            }

            if (info.state === 'ACTIVE') {
                this.selectedLocalCamera = info.device.deviceId;
                await this.localCameraTrack.setDevice(String(this.selectedLocalCamera));
            }

            this.sendControlStatus();
        };

        this.AgoraRTC.onMicrophoneChanged = async (info) => {
            console.log(info?.state);
            this.microphones = this.mapDeviceCollection(await this.AgoraRTC.getMicrophones());

            if (info.state === 'INACTIVE') {
                if (this.microphones.length > 0) {
                    if (String(this.selectedLocalMicrophone) === info.device.deviceId) {
                        this.oldSelectedLocalMicrophone = this.selectedLocalMicrophone;
                        this.selectedLocalMicrophone = this.microphones[0].id;
                        // await this.localMicrophoneTrack.setDevice(String(this.selectedLocalMicrophone));
                    }
                    await this.localMicrophoneTrack.setDevice(String(this.selectedLocalMicrophone));
                } else {
                    this.oldSelectedLocalMicrophone = this.selectedLocalMicrophone;
                    this.toggleMicPrivacy(true);
                }
            }

            if (info.state === 'ACTIVE') {
                this.selectedLocalMicrophone = info.device.deviceId;
                await this.localMicrophoneTrack.setDevice(String(this.selectedLocalMicrophone));
            }

            this.sendControlStatus();
        };

        this.AgoraRTC.onPlaybackDeviceChanged = async (info) => {
            console.log(info);
            this.speakers = this.mapDeviceCollection(await this.AgoraRTC.getPlaybackDevices());
            if (info.state === 'INACTIVE') {
                if (this.speakers.length > 0) {
                    if (String(this.selectedLocalSpeaker) === info.device.deviceId) {
                        this.oldSelectedLocalSpeaker = this.selectedLocalSpeaker;
                        this.selectedLocalSpeaker = this.speakers[0].id;
                        // this.updateAgoraClientSpeakerOut(String(this.selectedLocalSpeaker));
                    }
                    this.updateAgoraClientSpeakerOut(String(this.selectedLocalSpeaker));
                } else {
                    this.oldSelectedLocalSpeaker = this.selectedLocalSpeaker;
                    //this.toggleMicPrivacy(true);
                }
            }

            if (info.state === 'ACTIVE') {
                // if (String(this.oldSelectedLocalSpeaker) === info.device.deviceId) {
                //     this.selectedLocalSpeaker = this.oldSelectedLocalSpeaker;
                //     this.updateAgoraClientSpeakerOut(String(this.selectedLocalSpeaker));
                // }
                this.selectedLocalSpeaker = info.device.deviceId;
                this.updateAgoraClientSpeakerOut(String(this.selectedLocalSpeaker));
            }
            this.sendControlStatus();
        };
    }

    mapDeviceCollection(arr) {
        return arr.map((device) => {
            return { ...device, id: device.deviceId, name: device.label };
        });
    }

    notifyReady() {
        if (typeof window.navigator.notifyReady === 'function') {
            window.navigator.notifyReady();
        }
    }

    reConnect() {
        clearInterval(this.reConnectInterval);
        this.joinData.micPrivacy = !this.getLocalParticipant().microphoneMute;
        this.joinData.cameraPrivacy = !this.getLocalParticipant().cameraMute;
        this.joinRoom();

        this.reConnectInterval = setInterval(() => {
            this.joinRoom();
        }, 5 * 1000);
    }

    reConnectOnConnectionOnline() {
        this.joinData.micPrivacy = !this.getLocalParticipant().microphoneMute;
        this.joinData.cameraPrivacy = !this.getLocalParticipant().cameraMute;
        this.joinRoom();
    }

    clearAllTimeOuts() {
        this.subscriptionTimeOuts.forEach((timeoutId) => clearTimeout(timeoutId));
        this.subscriptionTimeOuts = [];
    }

    async joinRoom(dest = null) {
        // TODO: add check for conference Info
        this.clearAllTimeOuts();
        this.isAttendee = dest === 'attendeestage';
        if (this.joiningRoom || this.joinedInRoom) {
            // Removing isAttendee to join networking room.
            // TODO: Make sure attendee cannot join main room
            let err = new Error('error while joining into the room');
            err.message = `agoraAppId: ${this.conferenceInfo.agoraAppId}, 
            currentRoomId:${this.currentRoomID}, 
            agoraToken:${this.agoraToken},
            currentUid:${this.currentUid}`;
            this.appLoggerService.debug('Duplicate call to JoinRoom', err);
            return;
        }
        let agoraToken = '';
        if (!this.agoraClient) return;

        // reset publish flag
        this.isAudioPublished = false;
        this.isVideoPublished = false;
        // change made here, removed dest flag
        this.conferenceInfo = !this.isAttendee ? this.rtcService.getConferenceInfo() : this.attendeeInfo;
        if (this.conferenceInfo?.agoraBandwidthConfig?.lowQualityVideo?.isOptimizeOnScreenSharingEnabled) {
            this.isOptimizeOnScreenSharingEnabled =
                this.conferenceInfo?.agoraBandwidthConfig?.lowQualityVideo?.isOptimizeOnScreenSharingEnabled;
        }
        const roomInfo = this.rtcService.getRoomInfo();

        if (!isNaN(Number(this.conferenceInfo.agoraMaxParticipants))) {
            this.setMaxSpeakersCount();
        }

        this.isBreakoutRoom = false;
        this.currentUid = this.conferenceInfo.agoraUid;
        agoraToken = this.conferenceInfo.agoraToken;
        this.currentRoomID = !this.isAttendee ? roomInfo?.roomID : this.conferenceInfo?.agoraChannel;

        this.setJoinAsSpeaker();

        if (
            this.rtcService.isVirtualBackgroundEnabledForRoom() &&
            !this.rtcService.disableVirtualBackgroundSupport()
            // removing isAttendee. Instead we need to check if they have access to join
        ) {
            this.vbService.initializeVirtualBackgroundService();
        }
        this.joiningRoom = true;
        this.registerForEventListeners();
        let participant = {
            id: String(this.currentUid),
            userId: String(this.currentUid),
            name: this.joinData?.displayName,
            isLocal: true,
            isHost: this.joinData?.isInitiater ? true : false,
            microphoneMute: !this.isAttendee ? !this.joinData.micPrivacy : true,
            cameraMute: !this.isAttendee ? !this.joinData.cameraPrivacy : true,
            // participantType: this.joinData?.isInitiater || dest === 'networking' ? 'speaker' : 'audience'
            participantType: this.joinData?.isInitiater || dest === 'networking' ? 'speaker' : 'audience'
        };
        this.localParticipant = participant;

        try {
            await this.agoraClient.join(
                this.conferenceInfo.agoraAppId,
                this.currentRoomID,
                agoraToken,
                this.currentUid
            );
            // added callback to update timeout for webpage recording for recording
            this.notifyReady();

            this.meeting$.next({ type: 'VC_CREATED', data: null });

            this.meeting$.next({ type: 'ACTIVE', data: null });

            setTimeout(
                () =>
                    this.meeting$.next({
                        type: 'LOCAL_PARTICIPANT_CONNECTED',
                        data: null
                    }),
                200
            );

            this.listenToRTCMessages();

            this.isUserOnline = true;

            this.joinedInRoom = true;
            this.joiningRoom = false;

            if (
                this.breakoutRoomInfo?.breakoutRoomId ||
                (this.checkJoinAsSpeaker() && !this.externalInterfaceService.isEmbibe)
            ) {
                participant.participantType = 'speaker';
                await this.agoraClient.setClientRole('host');
                this.externalInterfaceService.sendUserStatus(false);
            }

            if (participant.participantType === 'audience') {
                participant.cameraMute = true;
                participant.microphoneMute = true;
                await this.agoraClient.setClientRole('audience');
                this.externalInterfaceService.sendUserStatus(true);
            }

            if (this.localParticipant.isHost || this.breakoutRoomInfo?.breakoutRoomId || this.setJoinAsSpeaker()) {
                await this.streamCreator();
            }
        } catch (error) {
            this.appLoggerService.error(`Error at joinroom`, error);
            return Promise.reject(error);
        }

        this.remoteParticipants = this.agoraClient.remoteUsers;
        this.agoraClient.enableAudioVolumeIndicator();

        // if (!this.isAttendee) {
        this.initRTM();
        // }
    }

    listenToRTCMessages() {
        this.agoraClient.on('onMessageReceived', function (message) {
            let parsedMessage = JSON.parse(message['text']);
            console.log(' MessageFromPeer', parsedMessage);
            this.currentSenderId = parsedMessage.sender.participantId;
            this.chatMessages$.next(parsedMessage);
            //reset the last value
            this.chatMessages$.next(null);
        });
    }

    async sendRtcTextMessage(message) {
        await this.agoraClient.sendMessage({
            text: message
        });
    }

    async sendRecordingStatus(activityState) {
        if (this.isAutoJoinRecordingAttendee) {
            this.attendeeViewStateService.sendMediaEngineEventStatus(activityState);
        }
    }

    async handleUserPublished(user, mediaType) {
        const uid = user.uid;
        if (this.isScreenShareUid(user.uid) && this.screenClientUid === user.uid) {
            // TODO this can be used to confirm if the event is published
            // if its not updated within a set time period then show error ?
            return;
        }

        if (this.isCloudVideoUid(user.uid)) {
            this.typeOfVideoShare = 'cloud';
        }

        if (!this.isAttendee && this.isCloudVideoUid(user.uid)) {
            // if the uid is of cloud video then skip any further processing
            return;
        }

        if (this.isAttendee && this.isPaused) {
            if (mediaType === 'video') {
                this.unprocessedVideoPublishEvents.push({ user, mediaType });
            }
            return;
        }

        // await this.agoraClient.subscribe(user, mediaType);
        // If the subscribed track is an audio track
        this.sendRecordingStatus(`user-published_${user.uid}_${mediaType}`);
        if (mediaType === 'audio') {
            this.subscribeUserAudioStream(user, mediaType);
        } else {
            if (this.isScreenShareUid(user.uid)) {
                this.sendRecordingStatus(`user-published_${user.uid}_screenShare`);
                if (this.screenSharing) {
                    this.toggleWindowShare(false);
                }
                this.screenSharingStream = user;
                this.setRemoteStreamType(1);
                // await this.agoraClient.subscribe(user, mediaType);
                this.participantStatusUpdated.next({ type: 'screenSharingStarted', data: user });
                this.isScreenShareAvaiable = true;
                this.screenShareData = user;
                this.typeOfVideoShare = 'screen';
                this.isOptimizeOnScreenSharingEnabled && (await this.updateVideoConfig(1));
            } else {
                if (this.isCloudVideoUid(user.uid)) {
                    this.cloudVideoStream = user;
                    this.typeOfVideoShare = 'screen';
                    this.setRemoteStreamType(1);
                }
                this.participantStatusUpdated.next({ type: 'userStreamPublished', data: user });
            }
            this.studioUpdates.next({ type: 'videoPublished', data: user });
        }
    }

    registerForEventListeners() {
        this.agoraClient.on('user-published', async (user, mediaType) => this.handleUserPublished(user, mediaType));

        this.agoraClient.on('user-joined', (user) => {
            this.sendRecordingStatus(`user-joined_${user.uid}`);
            if (!this.isScreenShareUid(user.uid) && !this.isCloudVideoUid(user.uid)) {
                this.agoraClient.setStreamFallbackOption(user.uid, 2);
                this.participantAdded.next(user);
                this.galleryParticipants.push(user);
                this.updateRemoteParticipants();
                if (this.rtcService.isChimeAudioEnabledForHost()) {
                    this.sendControlStatus({ participantJoined: true });
                }
            } else {
                this.nonGalleryParticipants.push(user);
            }
        });

        this.agoraClient.on('user-left', (user) => {
            this.sendRecordingStatus(`user-left_${user.uid}`);
            this.galleryParticipants = this.galleryParticipants.filter((participant) => participant.uid != user.uid);
            this.nonGalleryParticipants = this.nonGalleryParticipants.filter(
                (participant) => participant.uid != user.uid
            );
            this.participantRemoved.next(user);
            if (this.isScreenShareUid(user.uid)) {
                // this.setRemoteStreamType(0);
                this.meeting$.next({ type: 'CLEAR_SCREENSHARE_INFO', data: {} });
                this.screenSharingStream = null;
                this.isScreenShareStreamAvaiable();
                this.participantStatusUpdated.next({
                    type: 'screenSharingStopped',
                    data: {
                        id: user.uid
                    }
                });
            }
            this.updateRemoteParticipants();
            if (this.rtcService.isChimeAudioEnabledForHost()) {
                this.sendControlStatus({ participantLeft: true });
            }
        });
        this.agoraClient.on('user-unpublished', async (user, mediaType) => {
            const uid = user.uid;
            this.sendRecordingStatus(`user-unpublished_${user.uid}_${mediaType}`);
            if (!user.hasAudio) {
                this.setAudioLevelToRemoteParticipant(uid, 0);
            }
            if (!user.hasVideo && !this.isScreenShareUid(uid)) {
                this.participantStatusUpdated.next({
                    type: 'videoOff',
                    data: {
                        id: uid
                    }
                });
            }
            if (this.isScreenShareUid(user.uid)) {
                // set local participant to high quality again
                this.isOptimizeOnScreenSharingEnabled && (await this.updateVideoConfig(0));
                this.typeOfVideoShare = 'screen';
            }
            if (mediaType === 'video') {
                this.studioUpdates.next({ type: 'videoUnpublished', data: user });
            } else if (mediaType === 'audio') {
                this.studioUpdates.next({ type: 'audioUnpublished', data: user });
            }
        });

        this.agoraClient.on('volume-indicator', (volumes) => {
            // console.table(volumes);
            volumes.forEach((volume, index) => {
                if (volume.level > 4) {
                    this.activeSpeakerListCreation(volume.uid, volume.level);
                }

                //if (volume.uid != this.localParticipant.id || 1) { // allowing local participant volumn animation as well..
                if (volume.uid != this.localParticipant.id) {
                    if (volume.level > 4) {
                        this.setAudioLevelToRemoteParticipant(volume.uid, volume.level);
                    }
                }
                // }
                if (volume.uid == this.localParticipant.id) {
                    this.audioLevel.next(volume);
                }
            });
        });

        // interval to share local user volume details
        this.localVolumeIndicator = setInterval(() => {
            const volumeLevel = this.localMicrophoneTrack?.getVolumeLevel() * 10;
            if (this.localMicrophoneTrack && this.agoraClient.uid && volumeLevel > 4) {
                const data = {
                    uid: this.localParticipant.uid,
                    volumeLevel
                };
                this.externalInterfaceService.sendVolumeDetails(data);
            }
        }, 2000);

        this.agoraClient.on('stream-fallback', (uid, isFallbackOrRecover) => {
            this.sendRecordingStatus(`stream-fallback_${uid}_${isFallbackOrRecover}`);
            console.log(uid, isFallbackOrRecover, 'is fallback');
        });

        this.agoraClient.on('stream-type-changed', (uid, streamType) => {
            this.sendRecordingStatus(`stream-type-changed_${uid}_${streamType}`);
            console.log(uid, streamType, 'stream-type-changed');
        });

        this.agoraClient.on('connection-state-change', this.onConnectionStateChange.bind(this));
    }

    async subscribeUserAudioStream(user, mediaType) {
        if (this.isAttendee && this.eventsPlusService.showCustomAttendeeLayout) {
            this.participantStatusUpdated.next({ type: 'userStreamPublished', data: user });
        } else {
            if (this.isEventPreviewViewer) return;
            await this.agoraClient.subscribe(user, mediaType);
            const audioTrack = user.audioTrack;
            // Play the audio
            audioTrack.play();
            if (!this.isAttendee) {
                await audioTrack.setPlaybackDevice(String(this.selectedLocalSpeaker)).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    pauseAllAudioTracks() {
        if (!this.galleryParticipants.length) {
            setTimeout(() => {
                this.pauseAllAudioTracks();
            }, 2000);
        }
        this.galleryParticipants.forEach((user) => {
            if (user.audioTrack) {
                this.agoraClient.unsubscribe(user, 'audio');
            }
        });
        this.nonGalleryParticipants.forEach((user) => {
            if (user.audioTrack) {
                this.agoraClient.unsubscribe(user, 'audio');
            }
        });
    }

    resumeAllTracks() {
        this.galleryParticipants.forEach((user) => {
            if (user._audio_added_) {
                this.subscribeUserAudioStream(user, 'audio');
            }
            if (
                user._video_added_ &&
                this.unprocessedVideoPublishEvents.findIndex((upe) => upe.user.uid === user.uid) === -1
            ) {
                setTimeout(() => this.handleUserPublished(user, 'video'), 200);
            }
        });
        this.nonGalleryParticipants.forEach((user) => {
            if (user._audio_added_) {
                this.subscribeUserAudioStream(user, 'audio');
            }
        });
        // check if video is there
        setTimeout(() => {
            this.unprocessedVideoPublishEvents.forEach((upe) => {
                this.handleUserPublished(upe.user, upe.mediaType);
            });
            this.unprocessedVideoPublishEvents = [];
        }, 1000);

        // play relevant video tracks
    }

    setJoinAsSpeaker() {
        const whitelistedTenantForSpeakerEntry = this.appService.getConfigVariable('whitelistedTenantForSpeakersEntry');
        const MAX_COHOST_LIMIT = this.appService.getConfigVariableWithDefault(
            'WEBINAR_MAX_COHOST',
            APP_CONSTANT.WEBINAR_MAX_COHOST
        );
        // check if the current user is host then return true
        if (this.localParticipant?.isHost) return true;

        return CheckJoinAsSpeaker(
            this.conferenceInfo,
            this.currentUser,
            whitelistedTenantForSpeakerEntry,
            this.isAttendee,
            this.SPEAKER_LIMIT,
            MAX_COHOST_LIMIT
        );
    }

    checkJoinAsSpeaker() {
        return this.setJoinAsSpeaker();
    }

    setMaxSpeakersCount() {
        // Max speakers count is 5 less than the total participants count
        const totalParticipantCount = Number(this.conferenceInfo.agoraMaxParticipants);
        const MAX_COHOST_LIMIT = this.appService.getConfigVariableWithDefault(
            'WEBINAR_MAX_COHOST',
            APP_CONSTANT.WEBINAR_MAX_COHOST
        );

        this.SPEAKER_LIMIT = totalParticipantCount;
    }

    async streamCreator() {
        if (!this.noMediaPermission) {
            console.log(this.selectedLocalCamera, this.selectedLocalMicrophone, 'mobile permission issue');

            try {
                if (this.cameras.length > 0) {
                    if (!this.localCameraTrack && !this.localParticipant.cameraMute)
                        // removing rtcSync flag
                        this.localCameraTrack = await this.createCameraTrack();
                }
            } catch (error) {
                console.log(error, 'mobile permission issue 2');
                console.log('mobile permission track', this.localCameraTrack);
            }

            if (this.microphones.length > 0) {
                if (this.selectedLocalMicrophone === 0) {
                    //check added for to handle issue on safari
                    this.selectedLocalMicrophone = this.microphones[0].id;
                }

                if (!this.localMicrophoneTrack)
                    this.localMicrophoneTrack = await this.AgoraRTC.createMicrophoneAudioTrack({
                        microphoneId: String(this.selectedLocalMicrophone)
                    }).catch((err) => {
                        if (this.isPermissionError(err)) {
                            this.noAudioPermission = true;
                            this.noAudioPermissionBrowser = true;
                        } else {
                            this.noAudioPermissionDevice = true;
                        }
                        this.appLoggerService.error('error while creating local microphone track', err);
                        return Promise.reject(err);
                    });
            }
            await this.publishTracks();
        }
    }

    async publishTracks() {
        let tracksToBePublished = [];
        if (!this.localParticipant.cameraMute && this.localCameraTrack) {
            tracksToBePublished.push(this.localCameraTrack);
            await this.localCameraTrack.setEnabled(true);
            // playing camera Track from local view component on initialization
            this.isVideoOn.next(true);
            this.cameraState = true;
            this.isVideoPublished = true;
        }

        if (!this.localParticipant.microphoneMute) {
            tracksToBePublished.push(this.localMicrophoneTrack);
            this.micState = true;
            this.isAudioPublished = true;
        }

        if (this.localParticipant.participantType === 'speaker' && tracksToBePublished.length) {
            await this.agoraClient.publish(tracksToBePublished);
        }
    }

    async initRTM() {
        let options = {
            uid: String(this.currentUid),
            token: this.conferenceInfo.agoraRtmToken
        };
        try {
            await import('agora-rtm-sdk').then(async (m: any) => {
                this.agoraRtmClient = m.createInstance(this.conferenceInfo.agoraAppId);
            });

            (window as any).clientRTM = this.agoraRtmClient;

            await this.agoraRtmClient.login(options);

            (window as any).rtmChannel = this.agoraRtmChannel;

            this.agoraRtmChannel = this.agoraRtmClient.createChannel(this.currentRoomID);
            console.log(' createChannel', { agoraRtmChannel: this.agoraRtmChannel.channelId });

            await this.agoraRtmChannel.join();
        } catch (error) {
            console.log(' initRTM error', error);
        }

        // send info about main stage attendee
        this.agoraRtmClient.addOrUpdateLocalUserAttributes({
            isMainStageAttendee: this.utilService.isMainStageAttendee ? 'true' : 'false'
        });

        this.agoraRtmClient.on('MessageFromPeer', (message, peerId) => {
            let parsedMessage = JSON.parse(message['text']);
            console.log(' MessageFromPeer', parsedMessage);
            this.currentSenderId = parsedMessage.sender.participantId;
            this.chatMessages$.next(parsedMessage);
            //reset the last value
            this.chatMessages$.next(null);
        });
        // Display connection state changes
        this.agoraRtmClient.on('ConnectionStateChanged', async (state, reason) => {
            //console.log('State changed To: ' + state + ' Reason: ' + reason);
        });

        this.agoraRtmChannel.on('ChannelMessage', (message, memberId) => {
            const data = JSON.parse(message['text']);
            console.log(' ChannelMessage', data);

            // this.screenClientUid = data.sender.screenClientUid;
            this.chatMessages$.next(JSON.parse(message['text']));
        });

        // Display channel member stats
        this.agoraRtmChannel.on('MemberJoined', (memberId) => {
            // console.log(memberId + ' joined the channel');
        });

        // Display channel member stats
        this.agoraRtmChannel.on('MemberLeft', (memberId) => {
            //  console.log(memberId + ' left the channel');
        });
    }

    async createCameraTrack() {
        // if (this.localCameraTrack && !this.streamsMixer) return this.localCameraTrack;
        // let isCustomTrackUnpublished = false;
        // if (this.localCameraTrack && this.streamsMixer) {
        //     this.localCameraTrack.setEnabled(false);
        //     await this.agoraClient.unpublish(this.localCameraTrack);
        //     isCustomTrackUnpublished = true;
        //     this.streamsMixer = null;
        // }
        const videoTrack = await this.createLocalVideoTrack();
        // if (isCustomTrackUnpublished) {
        //     this.localCameraTrack = videoTrack;
        //     this.localCameraTrack.setEnabled(true);
        //     await this.agoraClient.publish(this.localCameraTrack);
        // }
        return videoTrack;
    }

    async createCustomCameraTrack() {
        let screenStream;
        let cameraStream;
        // let _mediaStreamTrack;
        if (this.streamMixerType === 'screen') {
            try {
                screenStream = this.localScreenTrack ? this.localScreenShareStream : await this.startScreenCapture();
                this.localScreenShareStream = screenStream;
                this.screenSharing = true;
            } catch (err) {
                this.appLoggerService.error('Failed to get Screen share stream');
                return;
            }
            screenStream.fullcanvas = true;
            screenStream.width = 1280; // or 3840
            screenStream.height = 720; // or 2160
            screenStream.sourceOfStream = 'screen';
        }

        cameraStream = await this.getUserCameraFeed();
        cameraStream.sourceOfStream = 'camera';
        this.localCameraStream = cameraStream;
        if (!this.streamsMixer) {
            this.isVideoPublished = false;
            // if (this.localCameraTrack && !this.streamsMixer) {
            //     this.localCameraTrack.setEnabled(false);
            //     await this.agoraClient.unpublish(this.localCameraTrack);
            //     this.streamsMixer = null;
            // }
            this.streamsMixer = new MultiStreamsMixer(
                this.streamMixerType !== 'screen' ? [cameraStream] : [screenStream, cameraStream],
                'multi-streams-mixer'
            );
            this.streamsMixer.streamMixingType = this.streamMixerType;
        } else {
            this.streamsMixer.streamMixingType = this.streamMixerType;
            await this.streamsMixer.resetVideoStreams(
                this.streamMixerType !== 'screen' ? [cameraStream] : [screenStream, cameraStream]
            );
        }
        await this.getMixedStreamCustomAgoraVideoTrack();

        this.streamsMixer.pptSlidesImageArray = this.pptSlideImages;
        if (this.streamMixerType === 'screen') {
            this.streamsMixer.changeLayoutForScreenShareStream();
        } else {
            this.streamsMixer.changeLayoutForPresentationStream();
        }
        await this.streamsMixer.startDrawingFrames();
        document.body.appendChild(this.streamsMixer.canvas);
        // Play the screen share track to local view (preview)
        this.isVideoOn.next(true);
        await this.localScreenTrack.play(this.joinData?.viewId);
    }

    async getMixedStreamCustomAgoraVideoTrack() {
        try {
            let _mediaStreamTrack = await this.streamsMixer.getMixedStream().getVideoTracks()[0];
            var customVideoTrack = await this.AgoraRTC.createCustomVideoTrack({
                mediaStreamTrack: _mediaStreamTrack,
                optimizationMode: 'detail'
            });
            this.localScreenTrack = customVideoTrack;
        } catch (err) {
            this.appLoggerService.error('Error while creating Custom Track', err);
        }
    }

    async unpublishLocalCameraTrack() {
        if (!!this.localCameraTrack) {
            this.localCameraTrack.setEnabled(false);
            await this.agoraClient.unpublish([this.localCameraTrack]);
            this.localCameraTrack?.close();
            this.localCameraTrack = null;
            this.isVideoPublished = false;
        }
    }

    async getScreenCapture() {
        this.localScreenShareStream = await this.startScreenCapture();
    }

    async startScreenCapture(displayMediaOptions = {}): Promise<any> {
        if (this.localScreenShareStream) return this.localScreenShareStream;
        let captureStream = null;
        // @ts-ignore
        captureStream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: true });
        captureStream.getTracks()[0].onended = () => {
            // emit this event when its not stream Mix Transfer
            this.meeting$.next({ type: 'SHARE_STOP', data: { stopShare: true } });
        };
        return captureStream;
    }

    async getUserCameraFeed(constraints = {}) {
        let stream = null;

        try {
            stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    width: { ideal: 1920 },
                    height: { ideal: 1080 },
                    deviceId: { exact: String(this.selectedLocalCamera) }
                },
                audio: true
            });
        } catch (err) {
            this.appLoggerService.error('Failed to get camera feed from browser', err);
        }
        return stream;
    }

    changeStreamMixerLayout(idx = null) {
        this.streamsMixer.changeLayout(idx);
    }

    uploadPptImages() {}

    handleDropFileEvent(event) {
        var isFileValid = this.utilService.validateFileToUpload(event.target.files[0]);

        if (isFileValid) {
            let file = event.target.files[0];
            // this.uploadLogo(event[0]);
            this.pptSlideImages.push(this.utilService.markUrlAsSafe(this.createBlobUrl(event.target.files[0])));
        }
    }

    createBlobUrl(file) {
        // URL.revokeObjectURL()
        // THis should be run when the url is no longer in use
        return URL.createObjectURL(file);
    }

    changeStreamMixerBgSlide() {
        this.streamsMixer.changeLayoutBgSlide();
    }

    async createCameraTrackv2() {
        var enableVb = await this.appService.canEnableVirtualBackground();
        if (enableVb && !this.rtcService.disableVirtualBackgroundSupport()) {
            try {
                await this.vbService.runCamera();
                this.isVirtualBackgroundApplied = true;
                const newStream = this.vbService.startCapture();
                const videoTrack = newStream?.getVideoTracks()[0];
                if (!!newStream && !!videoTrack) {
                    var customVideoTrack = this.AgoraRTC.createCustomVideoTrack({
                        mediaStreamTrack: videoTrack
                    });
                    return customVideoTrack;
                }
            } catch (err) {
                this.appLoggerService.error('Error while starting camera with virtual background', err);
                if (this.isPermissionError(err)) {
                    this.noCameraPermission = true;
                    this.errors.next({ type: APP_EVENTS.ERRORS.NO_CAMERA_PERMISSION });
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.SHOW_PERMISSION_UI,
                        data: { show: true, skipOption: false }
                    });
                } else {
                    this.errors.next({ type: APP_EVENTS.ERRORS.GENERIC_CAMERA_ERROR });
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.SHOW_PERMISSION_UI,
                        data: { show: true, skipOption: false }
                    });
                }
                return Promise.reject(err);
            }
        } else {
            if (this.selectedLocalCamera)
                return this.AgoraRTC.createCameraVideoTrack({
                    cameraId: String(this.selectedLocalCamera),
                    encoderConfig: this.getAgoraVideoProfile(true)
                }).catch((err) => {
                    if (this.isPermissionError(err)) {
                        this.noCameraPermission = true;
                        this.errors.next({ type: APP_EVENTS.ERRORS.NO_CAMERA_PERMISSION });
                        this.eventEmitterService.emit({
                            type: APP_EVENTS.SHOW_PERMISSION_UI,
                            data: { show: true, skipOption: false }
                        });
                    } else {
                        this.errors.next({ type: APP_EVENTS.ERRORS.GENERIC_CAMERA_ERROR });
                        this.eventEmitterService.emit({
                            type: APP_EVENTS.SHOW_PERMISSION_UI,
                            data: { show: true, skipOption: false }
                        });
                    }
                    this.appLoggerService.error('error while creating local camera track', err);
                    return Promise.reject(err);
                });
            else
                return this.AgoraRTC.createCameraVideoTrack({
                    encoderConfig: this.getAgoraVideoProfile(true)
                }).catch((err) => {
                    if (this.isPermissionError(err)) {
                        this.noCameraPermission = true;
                        this.errors.next({ type: APP_EVENTS.ERRORS.NO_CAMERA_PERMISSION });
                        this.eventEmitterService.emit({
                            type: APP_EVENTS.SHOW_PERMISSION_UI,
                            data: { show: true, skipOption: false }
                        });
                    } else {
                        this.errors.next({ type: APP_EVENTS.ERRORS.GENERIC_CAMERA_ERROR });
                        this.eventEmitterService.emit({
                            type: APP_EVENTS.SHOW_PERMISSION_UI,
                            data: { show: true, skipOption: false }
                        });
                    }
                    this.appLoggerService.error('error while creating local camera track', err);
                    return Promise.reject(err);
                });
        }
    }

    createLocalVideoTrack() {
        if (this.selectedLocalCamera)
            return this.AgoraRTC.createCameraVideoTrack({
                cameraId: String(this.selectedLocalCamera),
                encoderConfig: this.getAgoraVideoProfile(true)
            }).catch((err) => {
                if (this.isPermissionError(err)) {
                    this.noCameraPermission = true;
                    this.errors.next({ type: APP_EVENTS.ERRORS.NO_CAMERA_PERMISSION });
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.SHOW_PERMISSION_UI,
                        data: { show: true, skipOption: false }
                    });
                } else {
                    this.errors.next({ type: APP_EVENTS.ERRORS.GENERIC_CAMERA_ERROR });
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.SHOW_PERMISSION_UI,
                        data: { show: true, skipOption: false }
                    });
                }
                this.appLoggerService.error('error while creating local camera track', err);
                return Promise.reject(err);
            });
        else
            return this.AgoraRTC.createCameraVideoTrack({
                encoderConfig: this.getAgoraVideoProfile(true)
            }).catch((err) => {
                if (this.isPermissionError(err)) {
                    this.noCameraPermission = true;
                    this.errors.next({ type: APP_EVENTS.ERRORS.NO_CAMERA_PERMISSION });
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.SHOW_PERMISSION_UI,
                        data: { show: true, skipOption: false }
                    });
                } else {
                    this.errors.next({ type: APP_EVENTS.ERRORS.GENERIC_CAMERA_ERROR });
                    this.eventEmitterService.emit({
                        type: APP_EVENTS.SHOW_PERMISSION_UI,
                        data: { show: true, skipOption: false }
                    });
                }
                this.appLoggerService.error('error while creating local camera track', err);
                return Promise.reject(err);
            });
    }

    activeSpeakerListCreation(u, v) {
        let obj = {
            uid: String(u),
            volume: v,
            lastSpeaking: new Date().getTime()
        };

        let index = this.activeSpeakerList.findIndex((e) => e.uid === String(u));

        if (index === -1) {
            this.activeSpeakerList.push(obj);
        } else {
            this.activeSpeakerList[index].volume = v;
            this.activeSpeakerList[index].lastSpeaking = new Date().getTime();
        }
    }

    isScreenShareUid(uid) {
        return uid.toString().length > 9 && uid.toString().charAt(0) === '1' ? true : false;
    }

    isCloudVideoUid(uid) {
        return uid.toString().length > 9 && uid.toString().charAt(0) === '2' ? true : false;
    }

    setAudioLevelToRemoteParticipant(uid, audiolevel) {
        this.participantStatusUpdated.next({
            type: 'isVolumeChanged',
            data: {
                id: uid,
                volume: audiolevel
            }
        });
    }

    updateRemoteParticipants() {
        this.remoteParticipants = [];
        this.agoraClient.remoteUsers.forEach((p) => {
            if (!this.isScreenShareUid(p.uid)) {
                this.remoteParticipants.push(p);
            }
        });

        // if (this.remoteParticipants.length < 5) {
        //     this.setRemoteStreamType(0);
        // } else {
        //     this.setRemoteStreamType(1);
        // }
    }

    isScreenShareStreamAvaiable() {
        this.agoraClient.remoteUsers.forEach((p) => {
            if (this.isScreenShareUid(p.uid)) {
                this.isScreenShareAvaiable = true;
                return p;
            } else {
                this.isScreenShareAvaiable = false;
            }
        });
        return null;
    }

    setRemoteStreamType(bitrate) {
        this.agoraClient.remoteUsers.forEach(async (element) => {
            if (!this.isScreenShareUid(element.uid)) {
                await this.agoraClient.setRemoteVideoStreamType(element.uid, bitrate);
            }
        });
    }

    async changeRemoteStreamForIndividualUser(uid, bitrate) {
        await this.agoraClient.setRemoteVideoStreamType(uid, bitrate);
    }

    async setLocalStreamProfile(level) {
        console.log('set local user video profile', level);
        if (level) {
            // set to lower
            let config = this.getLowQualityVideoProfile();
            if (config && this.localCameraTrack?.isPlaying) {
                try {
                    await this.localCameraTrack?.setEncoderConfiguration({
                        width: config.width,
                        height: config.height,
                        frameRate: config.framerate,
                        bitrateMax: config.bitrate
                    });
                } catch (err) {
                    console.log('cannot setencoderConfiguration', err);
                }
            }
        } else {
            // set to higher
            if (this.localCameraTrack?.isPlaying) {
                try {
                    await this.localCameraTrack?.setEncoderConfiguration(this.getAgoraVideoProfile(true));
                } catch (err) {
                    console.log('cannot setencoderConfiguration', err);
                }
            }
        }
    }

    async updateVideoConfig(flag) {
        /* 0 => high
       1 => low
    */

        await this.setLocalStreamProfile(flag);
        if (flag) {
            // disable dual stream as we lowered the sender resolution
            await this.agoraClient.disableDualStream();
        } else {
            // enable dual stream
            if (!this.agoraClient._isDualStreamEnabled) {
                this.agoraClient
                    .enableDualStream()
                    .then(() => {
                        let config = this.getLowQualityVideoProfile();
                        if (config) {
                            console.log('Enable Dual stream success!');
                            this.agoraClient.setLowStreamParameter(config);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    }

    getLowQualityVideoProfile() {
        let width = this.agoraBandwidthConfig.lowQualityVideo.width;
        let height = this.agoraBandwidthConfig.lowQualityVideo.height;
        let framerate = this.agoraBandwidthConfig.lowQualityVideo.fps;
        let bitrate = this.agoraBandwidthConfig.lowQualityVideo.bitrate;

        try {
            width = this.conferenceInfo.agoraBandwidthConfig.lowQualityVideo.width;
            height = this.conferenceInfo.agoraBandwidthConfig.lowQualityVideo.height;
            framerate = this.conferenceInfo.agoraBandwidthConfig.lowQualityVideo.fps;
            bitrate = this.conferenceInfo.agoraBandwidthConfig.lowQualityVideo.bitrate;
        } catch (e) {
            console.log('error fetching lowQualityVideo from config');
        }

        return { width, height, framerate, bitrate };
    }

    onConnectionStateChange(curState, previousState, reason?) {
        if (curState == ConnectionState.RECONNECTING) {
            this.reconnecting = true;
            this.invokeRetryPopup();
        }

        if (previousState === ConnectionState.RECONNECTING && curState === ConnectionState.CONNECTED) {
            clearTimeout(this.reconnectPopupTimer);
            this.reconnectPopupTimer = undefined;
            this.reconnecting = false;
            this.meeting$.next({
                type: 'RE_CONNECTED',
                data: null
            });
        }
    }

    resetPopupTimer() {
        clearTimeout(this.reconnectPopupTimer);
        this.reconnectPopupTimer = undefined;
        this.invokeRetryPopup();
    }

    invokeRetryPopup() {
        if (this.reconnectPopupTimer === undefined) {
            this.reconnectPopupTimer = setTimeout(() => {
                this.meeting$.next({
                    type: 'AGORA_RETRY_POPUP',
                    data: null
                });
            }, 60 * 1000);
        }
    }

    registerCameraFramesListener() {
        this.vidyoConnector.OnLocalCameraFrame((obj1, obj2) => {
            console.log(obj1, obj2);
        });
    }

    async selectDevices() {
        let speakerId = this.utilService.getCookie('cacheSpeaker');
        let micId = this.utilService.getCookie('cacheMic');
        let cameraId = this.utilService.getCookie('cacheCamera');

        //setting device from url
        if (this.externalInterfaceService.cameraLabel) {
            const camera = this.cameras.find((x) => x.name === this.externalInterfaceService.cameraLabel);
            cameraId = camera.id;
        }
        if (this.externalInterfaceService.micLabel) {
            const mic = this.microphones.find((x) => x.name === this.externalInterfaceService.micLabel);
            micId = mic.id;
        }
        if (this.externalInterfaceService.speakerLabel) {
            const speaker = this.speakers.find((x) => x.name === this.externalInterfaceService.speakerLabel);
            speakerId = speaker.id;
        }

        //setting mic
        if (micId) {
            this.microphones.map(async (mic) => {
                if (mic.id == micId) {
                    this.selectedLocalMicrophone = mic.id;
                }
            });
            if (!this.selectedLocalMicrophone) {
                if (this.microphones.length > 0) this.selectedLocalMicrophone = this.microphones[0].id;
            }
        } else {
            if (this.microphones.length > 0) this.selectedLocalMicrophone = this.microphones[0].id;
        }

        //setting camera
        if (cameraId) {
            this.cameras.map(async (camera) => {
                if (camera.id == cameraId) {
                    this.selectedLocalCamera = camera.id;
                }
            });
            if (!this.selectedLocalMicrophone) {
                if (this.microphones.length > 0) this.selectedLocalMicrophone = this.microphones[0].id;
            }
        } else {
            if (this.cameras.length > 0) this.selectedLocalCamera = this.cameras[0].id;
        }

        //setting speaker
        if (speakerId) {
            this.speakers.forEach((speaker) => {
                if (speaker.id === speakerId) {
                    this.selectedLocalSpeaker = speaker.id;
                }
            });
            if (!this.selectedLocalSpeaker) {
                if (this.speakers.length > 0) this.selectedLocalSpeaker = this.speakers[0].id;
            }
        } else {
            if (this.speakers.length > 0) this.selectedLocalSpeaker = this.speakers[0].id;
        }
    }

    async manuallyChangeSpeaker(localSpeaker) {
        document.cookie = `cacheSpeaker=${localSpeaker.id}`;
        this.selectedLocalSpeaker = localSpeaker.id;
        this.updateAgoraClientSpeakerOut(String(this.selectedLocalSpeaker));
    }

    updateAgoraClientSpeakerOut(id: string) {
        this.agoraClient.remoteUsers.forEach(async (p) => {
            if (!this.isScreenShareUid(p.uid)) {
                await p.audioTrack?.setPlaybackDevice(id);
            }
        });
    }

    async manuallyChangeMicrophone(localMicrophone) {
        document.cookie = `cacheMic=${localMicrophone.id}`;
        this.selectedLocalMicrophone = localMicrophone.id;
        await this.localMicrophoneTrack.setDevice(String(this.selectedLocalMicrophone));
    }

    async manuallyChangeCamera(localCamera) {
        document.cookie = `cacheCamera=${localCamera.id}`;
        this.selectedLocalCamera = localCamera.id;
        await this.localCameraTrack.setDevice(String(this.selectedLocalCamera));
    }

    async leaveRoom() {
        try {
            this.cameraState = false;
            this.micState = false;
            this.isVideoOn.next(false);
            this.isBreakoutRoom = false;
            this.disconneting = true;
            this.isPostionChecked = false;
            this.joinedInRoom = false;
            this.joiningRoom = false;
            this.unprocessedVideoPublishEvents = [];
            await this.agoraClient?.removeAllListeners();
            await this.agoraClient?.leave();
            if (this.isAttendee) {
                this.agoraClient = null;
            }
            // remove attendee check - networking rooms
            // if (!this.isAttendee) {
            await this.agoraRtmChannel?.leave();
            await this.agoraRtmChannel?.removeAllListeners();
            await this.agoraRtmClient?.logout();
            await this.agoraRtmClient?.removeAllListeners();
            this.localCameraTrack?.close();
            this.localMicrophoneTrack?.close();
            // }
            this.processor = null; // To be destroyed along local camera track
            this.localCameraTrack = null;
            this.localMicrophoneTrack = null;
            this.streamsMixer = null;
            this.spotlightedUserIds = [];
            this.galleryParticipants = [];
            this.isScreenShareAvaiable = false;
            clearInterval(this.localVolumeIndicator);
            // this.localCameraTrack.close();
            // this.localMicrophoneTrack.close();
        } catch (exception) {
            console.log(exception);
        }
        this.toggleWindowShare(false);
    }

    async toggleCameraPrivacy(enable) {
        this.cameraState = !enable;
        if (this.processingToggleCamera) return;
        this.processingToggleCamera = true;
        if (!enable) {
            if (this.agoraClient != undefined) {
                //destroying previous instance of localTrack and creating new one
                // if (!!this.localCameraTrack) {
                //     await this.agoraClient.unpublish([this.localCameraTrack]);
                //     this.isVideoPublished = false;
                //     this.localCameraTrack?.close();
                //     this.localCameraTrack = null;
                // }
                try {
                    // if (this.enableStreamMixer) {
                    //     await this.createCustomCameraTrack();
                    // } else if (!this.enableStreamMixer && !(this.localCameraTrack && !this.streamsMixer)) {
                    //     // (this.localCameraTrack === null || this.localCameraTrack === undefined) &&
                    //     this.localCameraTrack = await this.createCameraTrack();
                    // }
                    if (this.localCameraTrack === null) {
                        this.localCameraTrack = await this.createCameraTrack();
                    }
                    if (!this.localCameraTrack) {
                        throw new Error('Failed to create track');
                    }
                    this.rtcSync = true;
                    await this.localCameraTrack.setEnabled(true);
                    await this.localCameraTrack.play(this.joinData?.viewId);
                    this.studioUpdates.next({ type: 'videoPublished', data: this.localParticipant });
                    this.participantStatusUpdated.next({
                        type: 'localUserStreamPublished',
                        data: this.localParticipant
                    });
                    this.isVideoOn.next(true);
                    await this.publishCameraTrack();
                    if (this.eventsPlusService.showCustonLayoutSpeakerSide || this.eventsPlusService.showStudioHost) {
                        // As of now proceeding without Green screen/background
                        // this.setBackgroundImage('', true);
                    }
                    if (this.screenSharing || this.whiteboardShared || this.isScreenShareAvaiable) {
                        // if screen or whiteboard shared set the resolution to lower quality

                        this.isOptimizeOnScreenSharingEnabled && (await this.updateVideoConfig(1));
                    }
                } catch (err) {
                    // there is an error while enabling camera
                    this.processingToggleCamera = false;
                    if (this.noCameraPermission) {
                        this.errors.next({ type: APP_EVENTS.ERRORS.NO_CAMERA_PERMISSION });
                        this.eventEmitterService.emit({
                            type: APP_EVENTS.SHOW_PERMISSION_UI,
                            data: { show: true, skipOption: false }
                        });
                    }
                    this.cameraState = enable;
                    if (err?.code === 'CAN_NOT_PUBLISH_MULTIPLE_VIDEO_TRACKS') {
                        await this.handleCannotPublishMultipleTracks('camera', enable);
                    } else {
                        throw new Error('Failed to create track');
                    }
                }
            }
        } else {
            if (this.localCameraTrack) {
                await this.stopLocalCameraTrack();
                this.studioUpdates.next({ type: 'videoUnpublished', data: this.localParticipant });
            }
        }
        if (this.localParticipant) {
            this.localParticipant.cameraMute = enable;
            this.externalInterfaceService.sendCameraStatus(!enable);
        }
        this.sendControlStatus();
        this.processingToggleCamera = false;
        if (enable == this.cameraState) {
            this.toggleCameraPrivacy(!this.cameraState);
        }
    }

    async handleCannotPublishMultipleTracks(source, state) {
        if (source === 'camera') {
            await this.unpublishLocalCameraTrack();
            await this.toggleCameraPrivacy(state);
            if (this.micState) {
                this.toggleMicPrivacy(!this.micState);
            }
        }
    }

    async publishCameraTrack() {
        if (!!this.localCameraTrack && !this.isVideoPublished && this.rtcService.getPreparatorySetupDone()) {
            try {
                if (this.localParticipant.participantType === 'speaker') {
                    await this.agoraClient.publish([this.localCameraTrack]);
                    this.isVideoPublished = true;
                }
            } catch (e) {
                this.appLoggerService.error('Error while publishing video', e);
                this.stopLocalCameraTrack();
                this.processingToggleCamera = false;
                return Promise.reject(e);
            }
        }
    }

    async stopLocalCameraTrack() {
        this.isVideoOn.next(false);
        this.localCameraTrack.stop();
        await this.localCameraTrack.setEnabled(false);
        if (this.isVirtualBackgroundApplied) {
            this.vbService.closeSession();
        }
        // this.stopAndResetStreamMixerStreams();
    }

    async stopAndResetStreamMixerStreams(exlcudeScreenShare = false) {
        if (this.streamsMixer) {
            await this.streamsMixer.stopExistingMediaStreams(exlcudeScreenShare);
            await this.streamsMixer.resetVideoStreams([]);
        }
    }

    async toggleMicPrivacy(enable) {
        this.micState = !enable;
        if (this.processingToggleMic) return;
        this.processingToggleMic = true;
        if (this.agoraClient != undefined && this.localMicrophoneTrack) {
            if (!enable) {
                if (!this.localMicrophoneTrack?.enabled) {
                    await this.localMicrophoneTrack.setEnabled(true);
                    await this.localMicrophoneTrack.setMuted(false);
                } else {
                    await this.localMicrophoneTrack.setMuted(false);
                }
                if (!this.isAudioPublished) {
                    try {
                        await this.agoraClient.publish([this.localMicrophoneTrack]);
                    } catch (e) {
                        this.appLoggerService.error('Error while publishing audio', e);
                        this.processingToggleMic = false;
                        return Promise.reject(e);
                    }

                    this.isAudioPublished = true;
                }
            } else {
                await this.localMicrophoneTrack.setMuted(true);
            }
        }

        if (this.localParticipant) {
            this.localParticipant.microphoneMute = enable;

            this.externalInterfaceService.sendMicStatus(!enable);
        }

        this.sendControlStatus();
        this.processingToggleMic = false;
        if (enable == this.micState) {
            this.toggleMicPrivacy(!this.micState);
        }
    }

    disableCustomStream() {
        this.enableStreamMixer = false;
    }

    toggleRecordingStatus(enable) {
        this.recording = enable;
        this.sendControlStatus();
    }

    toggleMicAll(enable) {
        this.microphoneMutedAll = enable;
        this.sendControlStatus();
    }

    toggleLockStatus(enable) {
        this.roomLocked = enable;
        this.sendControlStatus();
    }

    setHostDisconnectParticipant(id) {
        if (this.users[id]) {
            this.users[id].disconnectedByHost = true;
        }
    }

    async sendChatMessage({
        type,
        message,
        targetParticipantId = '',
        targetParticipantName = '',
        agoraShareUid,
        data = ''
    }) {
        let payload;

        if (type === 'PublicChat') {
            payload = {
                type,
                message,
                sender: {
                    participantId: this.roomData?.localParticipant.participantId,
                    name: this.roomData?.localParticipant.participantName,
                    userId: this.roomData?.localParticipant.userId,
                    agoraShareUid
                },
                agoraShareUid,
                targetParticipantId: '',
                data
            };

            if (
                message === MEDIA_ENGINE_EVENTS.PARTICIPANT_COHOST_GRANT ||
                message === MEDIA_ENGINE_EVENTS.PARTICIPANT_COHOST_REVOKE ||
                message === MEDIA_ENGINE_EVENTS.HOST_LOWER_HAND
            ) {
                payload.targetParticipantId = targetParticipantId ? targetParticipantId : this.currentSenderId;
                // if (targetParticipantId) {
                //   payload = { ...payload, ...{ targetParticipantId } };
                // }
            }
        } else {
            payload = {
                type,
                message,
                targetParticipantName,
                targetParticipantId,
                targetHostUserId: this.roomData?.localParticipant.userId,
                sender: {
                    participantId: this.roomData?.localParticipant.participantId,
                    name: this.roomData?.localParticipant.participantName,
                    userId: this.roomData?.localParticipant.userId
                },
                data
            };
        }

        console.log(payload);

        if (type === 'PublicChat') {
            return await this.agoraRtmChannel.sendMessage({ text: JSON.stringify(payload) });
        } else {
            return await this.agoraRtmClient.sendMessageToPeer(
                { text: JSON.stringify(payload) },
                payload.targetParticipantId
            );
        }
    }

    setHostIdentity(sender) {
        if (this.users[sender.id]) {
            this.users[sender.id].isHost = true;
        }
    }

    getAspectRatio() {
        const feedHeight = this.localCameraTrack.getCurrentFrameData().height;
        const feedWidth = this.localCameraTrack.getCurrentFrameData().width;
        return feedWidth / feedHeight;
    }

    async createScreenClient() {
        if (!this.screenClient) {
            this.screenClient = await this.AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
            var enableCloudProxy = this.appService.getConfigVariable('ENABLE_AGORA_CLOUD_PROXY');
            var cloudProxyValue = this.appService.getConfigVariable('CLOUD_PROXY_VALUE_SCREEN_SHARE')
                ? this.appService.getConfigVariable('CLOUD_PROXY_VALUE_SCREEN_SHARE')
                : 3;
            if (enableCloudProxy && this.isBehindProxy()) {
                // 3 is for udp cloud proxy
                this.screenClient.startProxyServer(cloudProxyValue);
            }
            this.screenClient.setClientRole('host');
        }
    }

    async joinChannelByScreenClient() {
        await this.screenClient
            .join(this.conferenceInfo.agoraAppId, this.currentRoomID, this.screenClientToken, this.screenClientUid)
            .catch((err) => {
                if (this.enableStreamMixer) return;
                console.log(err);
                this.isScreenShareAvaiable = false;
                this.meeting$.next({ type: 'SHARE_STOP', data: null });
            });
    }

    async publishScreenShareTracks(isStreamRepublish = false) {
        const publishTracks = !!this.localScreenAudioTrack
            ? [this.localScreenTrack, this.localScreenAudioTrack]
            : this.localScreenTrack;
        await this.screenClient
            .publish(publishTracks)
            .then(() => {
                if (!isStreamRepublish) {
                    this.screenSharing = true;
                    this.sendControlStatus();
                    const chatObj = {
                        agoraShareUid: this.screenClientUid,
                        type: 'PublicChat',
                        message: MEDIA_ENGINE_EVENTS.PARTRICIPANT_START_SHARE,
                        targetParticipantId: '',
                        targetParticipantName: ''
                    };
                    this.sendChatMessage(chatObj);
                    this.studioUpdates.next({ type: 'localScreenTrackPublished', data: this.localScreenTrack });
                    this.typeOfVideoShare = 'screen';
                }
            })
            .catch((err) => {
                console.log(err);
                this.meeting$.next({ type: 'SHARE_STOP', data: null });
            });
    }

    sendStartScreenShareRtmMessage() {
        const chatObj = {
            agoraShareUid: this.screenClientUid,
            type: 'PublicChat',
            message: MEDIA_ENGINE_EVENTS.PARTRICIPANT_START_SHARE,
            targetParticipantId: '',
            targetParticipantName: ''
        };
        this.sendChatMessage(chatObj);
    }

    async startScreenCall() {
        // await this.createScreenShareTrack();
        await this.createScreenClient();
        this.sendStartScreenShareRtmMessage();
        await this.joinChannelByScreenClient();
        await this.publishScreenShareTracks();
    }

    async createScreenTrackWithGetUserMedia() {
        if (!this.localScreenShareStream) {
            const screenShareStream = await this.startScreenCapture();
            this.localScreenShareStream = screenShareStream;
        }
        const screenShareTracks = this.localScreenShareStream.getTracks();
        await this.getLocalScreenTracks(screenShareTracks);
    }

    async getLocalScreenTrackWithAgoraSdk() {
        const screenShareTracks = await this.AgoraRTC.createScreenVideoTrack(
            {
                encoderConfig: this.getAgoraVideoProfile(false)
            },
            'auto'
        ).catch((e) => {
            this.meeting$.next({ type: 'SHARE_STOP', data: { stopShare: true } });
            console.log('Error while creating screen share');
            console.log(e);
            return Promise.reject(e);
        });
        if (screenShareTracks instanceof Array) {
            this.localScreenTrack = screenShareTracks[0];
            this.localScreenAudioTrack = screenShareTracks[1];
        } else {
            this.localScreenTrack = screenShareTracks;
        }

        this.localScreenTrack.getMediaStreamTrack().onended = () => {
            this.meeting$.next({ type: 'SHARE_STOP', data: { stopShare: true } });
        };
    }

    async getLocalScreenTracks(screenShareTracks) {
        if (this.enableStreamMixer) {
            await this.createCustomCameraTrack();
            // Screen Audio Track is not required for custom stream.
        } else {
            try {
                this.localScreenTrack = await this.AgoraRTC.createCustomVideoTrack({
                    mediaStreamTrack: screenShareTracks.find((track) => track?.kind === 'video'),
                    optimizationMode: 'detail'
                });
            } catch (err) {
                this.meeting$.next({ type: 'SHARE_STOP', data: { stopShare: true } });
            }

            this.localScreenTrack.getMediaStreamTrack().onended = () => {
                this.meeting$.next({ type: 'SHARE_STOP', data: { stopShare: true } });
            };
        }
        await this.getLocalScreenAudio(screenShareTracks);
    }

    async getLocalScreenAudio(screenShareTracks) {
        if (screenShareTracks instanceof Array && screenShareTracks.length > 1) {
            // this.localScreenAudioTrack = screenShareTracks[1];
            this.localScreenAudioTrack = await this.AgoraRTC.createCustomAudioTrack({
                mediaStreamTrack: screenShareTracks.find((track) => track?.kind === 'audio'),
                optimizationMode: 'detail'
            });
        }
    }

    async createScreenShareTrack() {
        if (this.localScreenTrack) return;
        // if (this.canUserCustomStream) {
        await this.createScreenTrackWithGetUserMedia();
        // } else {
        //     await this.getLocalScreenTrackWithAgoraSdk();
        // }
    }

    async toggleWindowShare(enable, deleteScreenTrack = false) {
        if (enable) {
            await this.startScreenCall();
        } else {
            if (this.screenClient) {
                if (deleteScreenTrack) {
                    this.screenClient.localTracks.forEach((t) => {
                        t.close();
                    });
                    this.stopAndResetStreamMixerStreams();
                }
                await this.screenClient.leave().then(() => {
                    if (deleteScreenTrack) {
                        this.localScreenTrack = undefined;
                        this.localScreenShareStream = undefined;
                        this.localScreenAudioTrack = undefined;
                        this.screenSharing = false;
                        this.studioUpdates.next({ type: 'localScreenTrackUnpublished', data: this.localScreenTrack });
                    }
                    this.sendControlStatus();
                });
            } else if (this.localScreenShareStream) {
            }
        }
        let streamType = enable ? 1 : 0;
        this.isOptimizeOnScreenSharingEnabled && (await this.updateVideoConfig(streamType));
    }

    async stopCustomStreamOnDisableCamera() {
        this.isVideoOn.next(false);
        this.localScreenTrack.stop();
        this.stopAndResetStreamMixerStreams(true);
        await this.unpublishCustomScreenShareTrack();
        await this.publishScreenShareTrackExclusively();
        // this.enableStreamMixer = false;
    }

    async stopCustomStreamOnResizeVideo() {
        this.localScreenTrack.stop();
    }

    async unpublishCustomScreenShareTrack() {
        await this.screenClient.unpublish();
    }

    async publishScreenShareTrackExclusively() {
        await this.getLocalScreenTracks(this.localScreenShareStream.getTracks());
        // this.localScreenTrack = await this.AgoraRTC.createCustomVideoTrack({
        //     mediaStreamTrack: this.localScreenShareStream.getTracks()[0],
        //     optimizationMode: 'detail'
        // });
        // const publishTracks = !!this.localScreenAudioTrack
        //     ? [this.localScreenTrack, this.localScreenAudioTrack]
        //     : this.localScreenTrack;
        // await this.screenClient.publish(publishTracks);
        await this.publishScreenShareTracks(true);
    }

    addUserInWaitingRoom(user) {
        if (user.memberName) {
            this.waitingUsers[user.memberId] = user;
            this.sendControlStatus();
        }
    }

    removeUserFromWaitingRoom(user) {
        delete this.waitingUsers[user.memberId];
        this.sendControlStatus();
    }

    setSharingScreenStatus(enable) {
        this.alreadySharingScreen = enable;
    }

    getLocalParticipant() {
        return this.localParticipant;
    }

    private sendControlStatus(updates: any = {}) {
        this.participantsStatus$.next({
            users: this.users,
            microphoneMutedAll: this.microphoneMutedAll,
            roomLocked: this.roomLocked,
            recording: this.recording,
            screenSharing: this.screenSharing,
            waitingUsers: this.waitingUsers,
            alreadySharingScreen: this.alreadySharingScreen,
            vcs: this.vcs,
            participantJoined: updates.participantJoined,
            participantLeft: updates.participantLeft
        });
    }

    private handleWindowEvents() {
        window.onVidyoClientLoaded = (status) => {
            // this.onLoad(status);
        };

        window.onresize = () => {};

        window.onbeforeunload = () => {
            if (this.vidyoConnector) {
                this.vidyoConnector.Destruct();
            }
        };

        window.addEventListener('online', (e) => {
            this.isUserOnline = true;
        });
        window.addEventListener('offline', (e) => {
            this.isUserOnline = false;
        });
    }

    clear() {
        this.localParticipant = {};
        this.joinData = {};
        this.users = {};
        this.microphoneMutedAll = false;
        this.roomLocked = false;
        this.recording = false;
        this.screenSharing = false;
        this.reconnecting = false;
        this.whiteboardShared = false;
        this.attendeeInfo = {};
        //     this.localCameraTrack?.close();
        //    this.localCameraTrack = null;
        this.localMicrophoneTrack?.close();
        this.localMicrophoneTrack = null;
        clearInterval(this.reConnectInterval);
    }

    changeCamera() {
        this.vidyoConnector.CycleCamera();
    }

    changeSpeaker() {
        this.vidyoConnector.CycleSpeaker();
    }

    changeMicrophone() {
        this.vidyoConnector.CycleMicrophone();
    }

    async setWhiteboardStatus(state) {
        this.whiteboardShared = state;
        let streamType = this.whiteboardShared ? 1 : 0;
        this.setRemoteStreamType(streamType);
        this.isOptimizeOnScreenSharingEnabled && (await this.updateVideoConfig(streamType));
    }

    releaseDevices() {}

    getAgoraVideoProfile(isVideo) {
        let width, height, framerate, bitrate;
        if (isVideo) {
            width = this.agoraBandwidthConfig.video.width;
            height = this.agoraBandwidthConfig.video.height;
            framerate = this.agoraBandwidthConfig.video.fps;
            bitrate = this.agoraBandwidthConfig.video.bitrate;
            try {
                width = this.conferenceInfo.agoraBandwidthConfig.video.width;
                height = this.conferenceInfo.agoraBandwidthConfig.video.height;
                framerate = this.conferenceInfo.agoraBandwidthConfig.video.fps;
                bitrate = this.conferenceInfo.agoraBandwidthConfig.video.bitrate;
            } catch (e) {
                console.log('error fetching video attributes from config');
            }
            return {
                width: width,
                height: height,
                frameRate: framerate,
                bitrateMax: bitrate
            };
        } else {
            width = this.isEnableHDshare
                ? this.agoraBandwidthConfig.screenShare.hdwidth
                : this.agoraBandwidthConfig.screenShare.width;
            height = this.isEnableHDshare
                ? this.agoraBandwidthConfig.screenShare.hdheight
                : this.agoraBandwidthConfig.screenShare.height;
            framerate = this.isEnableHDshare
                ? this.agoraBandwidthConfig.screenShare.hdfps
                : this.agoraBandwidthConfig.screenShare.fps;
            bitrate = this.isEnableHDshare
                ? this.agoraBandwidthConfig.screenShare.hdfps
                : this.agoraBandwidthConfig.screenShare.bitrate;
            try {
                width = this.isEnableHDshare
                    ? this.conferenceInfo.agoraBandwidthConfig.screenShare.hdwidth
                    : this.conferenceInfo.agoraBandwidthConfig.screenShare.width;
                height = this.isEnableHDshare
                    ? this.conferenceInfo.agoraBandwidthConfig.screenShare.hdheight
                    : this.conferenceInfo.agoraBandwidthConfig.screenShare.height;
                framerate = this.isEnableHDshare
                    ? this.conferenceInfo.agoraBandwidthConfig.screenShare.hdfps
                    : this.conferenceInfo.agoraBandwidthConfig.screenShare.fps;
                bitrate = this.isEnableHDshare
                    ? this.conferenceInfo.agoraBandwidthConfig.screenShare.hdfps
                    : this.conferenceInfo.agoraBandwidthConfig.screenShare.bitrate;
            } catch (e) {
                console.log('error fetching screenShare attributes from config');
            }
            return {
                width: width,
                height: height,
                frameRate: framerate,
                bitrateMax: bitrate
            };
        }
    }

    async makeSpeaker(showToast) {
        this.localParticipant.participantType = 'speaker';
        if (!showToast) {
            this.localParticipant.microphoneMute = !this.joinData.micPrivacy;
            this.localParticipant.cameraMute = !this.joinData.cameraPrivacy;
        }
        await this.agoraClient.setClientRole('host');
        await this.streamCreator();
        this.meeting$.next({ type: 'SEND_CONNECTION_STATUS', data: {} });
        this.externalInterfaceService.sendUserStatus(false);
    }

    async makeAudience(showToast) {
        this.localParticipant.participantType = 'audience';
        if (showToast) {
            this.meeting$.next({ type: 'SHOW_DOWNGRADE_AUDIENCE_TOAST', data: {} });
        }
        this.localParticipant.microphoneMute = true;
        this.localParticipant.cameraMute = true;
        this.isVideoOn.next(false);
        await this.unpublishTracks();
        await this.localCameraTrack?.close();
        await this.localMicrophoneTrack?.close();
        this.localCameraTrack = null;
        this.localMicrophoneTrack = null;
        this.processor = null;
        if (showToast) this.meeting$.next({ type: 'WHITEBOARD_STOP', data: {} });
        this.toggleWindowShare(false);
        this.sendControlStatus();
        this.agoraClient.setClientRole('audience');
        this.meeting$.next({ type: 'SEND_CONNECTION_STATUS', data: {} });
        this.externalInterfaceService.sendUserStatus(true);
    }

    async unpublishTracks() {
        try {
            await this.agoraClient.unpublish();
            this.isAudioPublished = false;
            this.isVideoPublished = false;
        } catch (error) {
            console.log('stream unpublish error:', error);
        }
    }

    checkPostion(participants, isHardMute) {
        if (this.breakoutRoomInfo?.breakoutRoomId) {
            return;
        }

        const numberOfSpeaker = participants?.filter((p) => p.participantType === 'speaker').length;
        this.showLimitReached(participants, numberOfSpeaker);
        if (this.isPostionChecked === false) {
            this.externalInterfaceService.sendHardMuteAudiostatus(isHardMute);
        }

        if (!this.localParticipant.isHost) {
            if (this.isPostionChecked === false && !this.externalInterfaceService.isEmbibe) {
                if (numberOfSpeaker < this.SPEAKER_LIMIT) {
                    const index = participants.findIndex((p) => p.participantUri === this.localParticipant.userId);
                    if (participants[index]?.participantType === 'audience' && this.checkJoinAsSpeaker()) {
                        this.makeSpeaker(false);
                    }
                } else {
                    if (this.isLocalParticipantCoHost(participants)) {
                        const index = participants.findIndex((p) => p.participantUri === this.localParticipant.userId);
                        if (participants[index]?.participantType === 'audience' && this.checkJoinAsSpeaker()) {
                            this.makeSpeaker(false);
                        }
                    }
                }

                if (this.localParticipant.participantType === 'audience') {
                    this.meeting$.next({ type: 'SHOW_DOWNGRADE_AUDIENCE_INFO', data: {} });
                }

                this.isPostionChecked = true;
                return;
            }

            if (this.localParticipant.participantType === 'speaker') {
                let speakerList = participants.filter((p) => p.participantType === 'speaker');

                speakerList = this.getParticipantsWithActiveSpeakerValue(speakerList);

                const speakerListLength = speakerList.length;

                if (speakerListLength > this.SPEAKER_LIMIT) {
                    const localPfromL = this.getLocalParticipantFromParticipants(speakerList);

                    if (!localPfromL.isHost) {
                        if (!localPfromL.isSharingScreen) {
                            //  if(!this.checkIfLocalParticipantActive(localPfromL)){
                            if (!localPfromL.isActiveSpeaker) {
                                if (!localPfromL.isCoHost) {
                                    const speakerList1 = speakerList.filter(
                                        (s) => !s.isHost && !s.isSharingScreen && !s.isActiveSpeaker && !s.isCoHost
                                    );

                                    let multidevicelist = speakerList1.filter((s) => s.userId === localPfromL.userId);
                                    if (multidevicelist.length > 1) {
                                        this.sendMakeAudience(multidevicelist);
                                    } else {
                                        const speakerlist2 = speakerList.filter(
                                            (s) => !s.isHost && !s.isSharingScreen && !s.isActiveSpeaker && s.isCoHost
                                        );
                                        if (this.toFindDuplicates(speakerlist2)?.length === 0) {
                                            this.sendMakeAudience(speakerList1);
                                        }
                                    }
                                } //else if is cohost
                                else {
                                    let cohostmultidevicelist = speakerList.filter(
                                        (s) => s.userId === localPfromL.userId
                                    );

                                    if (cohostmultidevicelist.length > 1) {
                                        this.sendMakeAudience(cohostmultidevicelist);
                                    } else {
                                        if (cohostmultidevicelist.length === 1) {
                                            let cohostlist = speakerList.filter(
                                                (s) =>
                                                    !s.isHost && !s.isSharingScreen && !s.isActiveSpeaker && !s.isCoHost
                                            );
                                            if (cohostlist.length === 0) {
                                                let cohostlist = speakerList.filter(
                                                    (s) =>
                                                        !s.isHost &&
                                                        !s.isSharingScreen &&
                                                        !s.isActiveSpeaker &&
                                                        s.isCoHost
                                                );
                                                this.sendMakeAudience(cohostlist);
                                            }
                                        }
                                    }
                                }
                            } //else if all activ
                        }
                    }
                }
            }
        }
        this.isPostionChecked = true;
    }

    checkForSpotlightUpdate(spotlightedParticipantsLatest) {
        if (!_.isEqual(this.spotlightedUserIds, spotlightedParticipantsLatest)) {
            // this.isSpotlighted = spotlightedParticipantsLatest.length > 0;
            this.updateSpotlightedParticipants(spotlightedParticipantsLatest);
        }
    }

    showLimitReached(participants, numberOfSpeaker) {
        if (numberOfSpeaker >= this.SPEAKER_LIMIT) {
            if (
                (this.localParticipant.isHost || this.isLocalParticipantCoHost(participants)) &&
                this.showedLimitReached
            ) {
                this.meeting$.next({ type: 'SHOW_LARGE_MEETINGINFO', data: {} });
                this.showedLimitReached = false;
            }
        }
    }

    sendMakeAudience(speakerList) {
        speakerList = speakerList.sort(this.sortFunction);
        const participantToDemote = speakerList[speakerList.length - 1];
        if (participantToDemote.participantUri === this.localParticipant.userId) {
            if (participantToDemote.isCoHost) {
                this.meeting$.next({
                    type: 'DOWNGRADE_CO_HOST',
                    data: { userId: participantToDemote.userId }
                });
            }
            this.makeAudience(true);
        }
    }

    isLocalParticipantCoHost(participants) {
        let localListParticipant = participants.find(
            (p) => p.isCoHost === true && p.participantUri === this.localParticipant.userId
        );
        if (localListParticipant) {
            let numberofcohost = participants.filter((p) => p.userId === localListParticipant.userId);

            if (numberofcohost.length > 1) {
                return false;
            } else {
                if (numberofcohost.length === 1) {
                    return true;
                }
            }
        } else {
            return false;
        }
    }

    sortFunction(a, b) {
        var dateA = new Date(a.participantJoinTime).getTime();
        var dateB = new Date(b.participantJoinTime).getTime();
        return dateA > dateB ? 1 : -1;
    }

    checkIfLocalParticipantActive(localPfromL) {
        return this.activeSpeakerList.findIndex((elment) => elment.uid === localPfromL.participantUri) === -1
            ? false
            : true;
    }

    getParticipantsWithActiveSpeakerValue(list) {
        for (let index = 0; index < list.length; index++) {
            if (this.activeSpeakerList.findIndex((a) => a.uid === list[index].participantUri) > -1) {
                list[index]['isActiveSpeaker'] = true;
            } else {
                list[index]['isActiveSpeaker'] = false;
            }
        }

        return list;
    }

    getLocalParticipantFromParticipants(list) {
        return list.find((l) => l.participantUri === this.localParticipant.userId);
    }

    setFitToScreen(value) {
        this.isFitToScreen = value;
        this.participantStatusUpdated.next({ type: 'fitToScreen', data: { value } });
    }

    enabledHDScreenShare(value) {
        this.isEnableHDshare = value;
    }

    toFindDuplicates(arry) {
        const lookup = arry.reduce((a, e) => {
            a[e.userId] = ++a[e.userId] || 0;
            return a;
        }, {});

        return arry.filter((e) => lookup[e.userId]);
    }

    isPermissionError(err) {
        return err.code === 'PERMISSION_DENIED' || (err instanceof DOMException && err.name === 'NotAllowedError');
    }

    isSystemPermissionError(err) {
        return this.isPermissionError(err) && err?.message?.indexOf('system') > -1;
    }

    spotLightUser(participantId) {
        if (!this.spotlightedUserIds.includes(participantId)) {
            this.spotlightedUserIds.push(participantId);
            this.participantStatusUpdated.next({
                type: 'spotlight',
                data: {
                    spotlightedUserIds: this.spotlightedUserIds
                    // add or remove
                }
            });
        }
    }

    updateSpotlightedParticipants(newSpotlightees) {
        this.spotlightedUserIds = newSpotlightees;
        this.participantStatusUpdated.next({
            type: 'spotlight',
            data: {
                spotlightedUserIds: this.spotlightedUserIds
                // add or remove type action.
            }
        });
    }

    isParticipantSpotlighted(pId) {
        return this.roomData?.spotlightedParticipants?.findIndex((spId) => spId === pId) > -1;
    }

    // for playing locally on selected divs
    async toggleCameraForSetting(divId, status) {
        if (this.localCameraTrack === null) {
            this.localCameraTrack = await this.createLocalVideoTrack();
        }
        await this.localCameraTrack.setEnabled(status);
        this.localCameraTrack.play(divId);
        if (divId === 'video-preview') {
            this.studioUpdates.next({ type: 'videoUnpublished', data: this.localParticipant });
        } else {
            this.studioUpdates.next({ type: 'videoPublished', data: this.localParticipant });
        }
    }

    //Set bg blur
    async setBackgroundBlurring() {
        await this.checkForProcessorInstance();
        if (this.localCameraTrack) {
            try {
                this.processor.setOptions({ type: 'blur', blurDegree: 2 });
                await this.processor.enable();
            } catch (e) {
                console.log(e);
            }
        }
    }

    // Set an image as the background
    async setBackgroundImage(url = '', addChroma = false) {
        await this.checkForProcessorInstance();

        if (this.localCameraTrack) {
            const imgElement = document.createElement('img');
            imgElement.crossOrigin = 'Anonymous';
            imgElement.onload = async () => {
                try {
                    this.processor.setOptions({ type: 'img', source: imgElement });
                    await this.processor.enable();
                    this.participantStatusUpdated.next({
                        type: 'localUserStreamPublished',
                        data: this.localParticipant
                    });
                } catch (e) {
                    console.log(e);
                }
            };
            if (addChroma) {
                url = this.greenScreenImage;
            }
            imgElement.src = url;
        }
    }

    async checkForProcessorInstance() {
        if (this.processor === null) {
            await this.createProcessorInstanceAndAttach();
        }
    }

    async createProcessorInstanceAndAttach() {
        await import('agora-extension-virtual-background').then(async (m) => {
            this.virtualBackgroundExtension = m.default;
            const extension = new this.virtualBackgroundExtension();
            await this.AgoraRTC.registerExtensions([extension]);
            this.processor = await extension.createProcessor();
            if (this.processor && this.localCameraTrack) {
                // Create a VirtualBackgroundProcessor instance;
                try {
                    // Initialize the extension and pass in the URL of the Wasm file
                    await this.processor.init('./assets/wasms');
                } catch (e) {
                    console.log('Fail to load WASM resource!');
                    return null;
                }
                // Inject the extension into the video processing pipeline in the SDK
                await this.localCameraTrack.pipe(this.processor).pipe(this.localCameraTrack.processorDestination);
            }
        });
    }

    async playStreamInDOM(agoraId) {
        const participant = this.remoteParticipants.find((participant) => String(participant.uid) === String(agoraId));
        await this.agoraClient.subscribe(participant, 'video');
        const videoTrack = participant.videoTrack;
        await videoTrack.play(`video-${agoraId}-1`);
    }

    async disableBg() {
        await this.processor.disable();
    }
    removeRecentChatMessage() {
        this.chatMessages$.next(null);
    }
}
