import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { filter } from 'rxjs/operators';

import { ConfirmationService } from 'primeng/api';

import {
    UserService,
    AuthService,
    EventEmitterService,
    EventData,
    UtilService,
    AppService,
    LocalStorageService
} from 'src/app/core';

import { APP_EVENTS } from '../../../constants/app-events';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { GoogleTagMangerService } from 'src/app/core/services/google-tag-manger.service';
import { DASHBOARD_HEADER } from 'src/app/constants';

@Component({
    selector: 'app-landing-header',
    templateUrl: './landing-header.component.html',
    styleUrls: ['./landing-header.component.scss'],
    providers: [ConfirmationService]
})
export class LandingHeaderComponent implements OnInit {
    currentUser;
    loading = false;
    profilePicPath;
    isAuthenticated = false;
    showMenu;
    isDefaultPic: boolean;
    isMobile;
    canCreateWebinar = false;
    preRecordedFeatureEnabled = false;
    showprofiledetails: boolean = false;
    headerSettings = {
        isDarkTheme: false,
        showUseCase: true,
        showSignIn: true,
        showCreateEvent: false
    };
    tooltip = `You don't have access to create an event`;
    @Input() hasTransparentHeader: boolean = false;
    isJioEvents: boolean = false;
    enableNavigateToContactUs: boolean = this.appService.getConfigVariable('enableNavigateToContactUs');
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private userService: UserService,
        private confirmationService: ConfirmationService,
        private eventEmitterService: EventEmitterService,
        private localstoreService: LocalStorageService,
        private utilService: UtilService,
        private translate: TranslateService,
        private toastrService: ToastrService,
        private appService: AppService,
        private googleTagMangerService: GoogleTagMangerService
    ) {
        this.isJioEvents = this.appService.isJioEvents();
    }

    ngOnInit() {
        this.isMobile = this.utilService.isMobileBrowser();
        this.currentUser = this.userService.getUserSync();
        if (this.currentUser._id) {
            this.profilePicPath = this.userService.getProfilePicPath(this.currentUser._id);
        }

        this.authService.getIsAuthenticated$().subscribe((bool) => {
            this.isAuthenticated = bool;
        });
        this.canCreateWebinar = this.utilService.enablePaymentFeature(this.currentUser?.isOTT)
            ? this.currentUser?.eventsFeaturesEnabled?.canCreateWebinar
            : this.currentUser.canCreateWebinar;
        this.preRecordedFeatureEnabled = this.utilService.enablePaymentFeature(this.currentUser?.isOTT)
            ? this.currentUser?.eventsFeaturesEnabled?.preRecordedFeatureEnabled
            : this.currentUser.canCreateWebinar;
        this.eventEmitterService.subscribe((event: EventData) => {
            if (event.type === APP_EVENTS.UPDATE_PROFILE || event.type === APP_EVENTS.NAME_CHANGED) {
                this.currentUser = this.userService.getUserSync();
                this.profilePicPath = this.userService.getProfilePicPath(this.currentUser._id);
                this.currentUser = this.userService.getUserSync();
                this.isDefaultPic = !this.currentUser?.imageMeta && !this.currentUser?.imageMeta?.path;
            }
        });
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.setHeaderOptions(event.url);
        });
        this.setHeaderOptions(this.router.url);
    }

    getProfilePicPath() {
        return this.userService.getProfilePicPath(this.currentUser._id);
    }
    openDetails() {
        this.showprofiledetails = true;
    }
    closeProfile() {
        this.showprofiledetails = false;
    }

    navigateToHome() {
        this.googleTagMangerService.pushGoogleTagData(DASHBOARD_HEADER.JIOEVENTS);
        if (this.appService.isJioEvents() && this.appService.isJioEventsRedirectionEnabled()) {
            window.open(this.appService.getConfigVariable('JIO_EVENTS_REDIRECTION'), '_self');
        } else {
            if (this.showMenu) {
                this.showMenu = false;
            }
            this.router.navigate(['/home'], { relativeTo: this.activatedRoute });
            setTimeout(() => {
                this.utilService.scrollToAnchor('website-top', 50);
            }, 100);
        }
    }

    navigateToSignIn() {
        this.toggleMenu();
        this.router.navigate(['/login'], { relativeTo: this.activatedRoute });
    }

    navigateUsingUrl(endpoint) {
        let url = '/' + `${endpoint}`;
        this.router.navigateByUrl(url);
    }

    navigateToProfile() {
        this.googleTagMangerService.pushGoogleTagData(DASHBOARD_HEADER.VIEW_PROFILE);
        this.toggleMenu();
        this.router.navigate(['/profile'], { relativeTo: this.activatedRoute });
    }

    navigateToDashboard() {
        this.toggleMenu();
        this.router.navigate(['dashboard']);
    }

    createEvent() {
        this.toggleMenu();
        this.googleTagMangerService.pushGoogleTagData(DASHBOARD_HEADER.CREATE_VIRTUAL);
        if (!this.canCreateWebinar) {
            this.localstoreService.addItem('userFrom', 'dashboard');
            this.router.navigate(['/pricing']);
            return;
        }
        this.router.navigate(['/dashboard'], {
            relativeTo: this.activatedRoute,
            queryParams: { create: true }
        });
    }

    logout() {
        this.showMenu = false;
        this.translate.get(['sign_out_popup']).subscribe((translations) => {
            this.confirmationService.confirm({
                message: translations.sign_out_popup.text,
                header: translations.sign_out_popup.title,
                acceptLabel: translations.sign_out_popup.acceptLabel,
                rejectLabel: translations.sign_out_popup.rejectLabel,
                acceptButtonStyleClass: 'custom-button-revamp btn-confirm-primary lg',
                rejectButtonStyleClass: 'custom-button-revamp btn-confirm-secondary lg',
                accept: () => {
                    this.authService.logout().subscribe();
                    let EventData = DASHBOARD_HEADER.SIGN_OUT;
                    EventData.outcome = 'yes';
                    this.googleTagMangerService.pushGoogleTagData(EventData);
                },
                reject: () => {
                    let EventData = DASHBOARD_HEADER.SIGN_OUT;
                    EventData.outcome = 'no';
                    this.googleTagMangerService.pushGoogleTagData(EventData);
                }
            });
        });
    }

    toggleMenu() {
        this.showMenu = !this.showMenu;
    }

    onOutsideClick(event) {
        this.showMenu = false;
    }

    navigateToUseCases() {
        this.utilService.scrollToAnchor('use-cases', 50);
    }

    setHeaderOptions(route) {
        this.headerSettings.isDarkTheme = route === '/home' ? true : false;
        this.headerSettings.showUseCase = route === '/home' ? true : false;
        this.headerSettings.showSignIn = route === '/home' ? true : false;
        this.headerSettings.showCreateEvent = !(
            route === '/home' ||
            route === '/contactus' ||
            route === '/onboarding' ||
            route.includes('join') ||
            route.includes('preview') ||
            route.includes('attendee')
        )
            ? true
            : false;
    }

    createPreRecordedEvent() {
        this.toggleMenu();
        this.googleTagMangerService.pushGoogleTagData(DASHBOARD_HEADER.CREATE_PRERECORD);
        if (!this.preRecordedFeatureEnabled) {
            this.localstoreService.addItem('userFrom', 'dashboard');
            this.router.navigate(['/contactus']);
            return;
        }
        this.router.navigate(['/dashboard'], {
            relativeTo: this.activatedRoute,
            queryParams: { prerecorded: true }
        });
    }
    /* toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
      } */
    isDropdownOpen = false;

    toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
    }
}
