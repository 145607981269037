import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Inject, ErrorHandler } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';

import { CoreModule, TokenInterceptor, ErrorInterceptor, AppService, RestService } from './core';
import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import * as fromComponents from './components';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { LandingHeaderComponent } from './components/landing-page/landing-header/landing-header.component';
import { LandingFooterComponent } from './components/landing-page/landing-footer/landing-footer.component';
import { SolutionsComponent } from './components/pages/solutions/solutions.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { SupportComponent } from './components/pages/support/support.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { VirtualEventsComponent } from './components/pages/virtual-events/virtual-events.component';
import { WebinarsComponent } from './components/pages/webinars/webinars.component';
import { WhitegloveServiceComponent } from './components/pages/whiteglove-service/whiteglove-service.component';
import { CustomerComponent } from './components/pages/customer/customer.component';
import { AnimatedDigitComponent } from './components/utils/animated-digit/animated-digit.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SliderAnimationComponent } from './components/utils/slider-animation/slider-animation.component';
import { WordCloudComponent } from './components/utils/word-cloud/word-cloud.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { HeaderVersion2Component } from './components/landing-page/header-version2/header-version2.component';
import { LandingPageversion2Component } from './components/landing-page/landing-pageversion2/landing-pageversion2.component';
import { ProgressBarAccordionComponent } from './components/utils/progress-bar-accordion/progress-bar-accordion.component';
import { OnboardingV1Component } from './components/onboarding-v1/onboarding-v1.component';
import { ResetPasswordV1Component } from './components/reset-password-v1/reset-password-v1.component';
import { ForgotPasswordV1Component } from './components/forgot-password-v1/forgot-password-v1.component';
import { VerificationComponent } from './components/verification/verification.component';
import { AppLoggerService } from './core/services/app-logger.service';
import { AppErrorHandler } from './core/interceptors/error.handler';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { OverlayModule } from 'primeng/overlay';
import { ButtonModule } from 'primeng/button';
import { ServiceWorkerModule, SwRegistrationOptions } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PaginatorModule } from 'primeng/paginator';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Observable } from 'rxjs';
import { EventCardDatesComponent } from './components/landing-page/event-card-dates/event-card-dates.component';
import { TrialEventComponent } from './components/landing-page/trial-event/trial-event.component';
import { EventClientsComponent } from './components/landing-page/event-clients/event-clients.component';
import { MyCardComponent } from './components/landing-page/my-card/my-card.component';
import { OwlModule } from 'angular-owl-carousel';
import { HomeEventViewComponent } from './components/landing-page/home-event-view/home-event-view.component';
import { HomePageEventComponent } from './components/pages/home-page-event/home-page-event.component';
import { IntersectionObserverDirective } from './components/landing-page/intersection-observer.directive';
import { DropdownToggleDirective } from './shared/components/drop-down-toggle/dropdown-toggle.directive';
import { ContactV2Component } from './components/pages/contact-v2/contact-v2.component';
import { LandingPageversionOComponent } from './components/landing-page/landing-pageversion-o/landing-pageversion-o.component';
import { PricingOComponent } from './components/pages/pricing-o/pricing-o.component';
// import { HeaderVersionOComponent } from './components/landing-page/header-version-o/header-version-o.component';
// import { LandingFooterOComponent } from './components/landing-page/landing-footer/landing-footer-o/landing-footer-o.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ExternalLoginComponent } from './components/external-login/external-login.component';
import { FaqsComponent } from './components/pages/FAQ/faqs/faqs.component';
import { FaqsLinksComponent } from './components/pages/FAQ/faqs-links/faqs-links.component';
import { FaqQuestionsComponent } from './components/pages/FAQ/faq-questions/faq-questions.component';

function showInfoAndRedirect(config) {
    var { message, destination } = config;
    setTimeout(() => {
        window.location = destination;
    }, 0);
    window.alert(message);
}

function checkAndRedirect(config) {
    var reDirections = config && config['reDirections'];
    var redirectTo = reDirections && reDirections[window.location.toString()];
    if (redirectTo) {
        window.location = redirectTo;
    } else {
        // check event count config
        var reDirections = config && config['reDirections'];
        var reDirectionsToStreaming = reDirections && reDirections['toStreaming'];
        if (reDirectionsToStreaming) {
            var url = window.location.toString();
            for (const [key, value] of Object.entries(reDirectionsToStreaming)) {
                if (url.includes(key)) {
                    showInfoAndRedirect(value);
                    break;
                }
            }
        }
    }
}

export function loadConfig(appService: AppService, restService: RestService, appLoggerService: AppLoggerService) {
    window['profilingInfo']['configStartTime'] = Date.now();
    return async () => {
        let isSafariBrowser = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if ((isSafariBrowser || window.location.href.includes('isMyJio=true')) && 'serviceWorker' in navigator) {
            await navigator.serviceWorker.getRegistrations().then(function (registrations) {
                for (let registration of registrations) {
                    registration.unregister();
                }
            });
        }

        return restService
            .get(`${appService.getEnvVariable('BASE_URL')}/config?os_type=jioconfweb`)
            .toPromise()
            .then((config) => {
                window['profilingInfo']['configLeaveTime'] = Date.now();
                appService.setConfig(config);
                appLoggerService.initialize();
                checkAndRedirect(config);
            });
    };
}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function swRegistrationFactory() {
    return new Observable((subscriber) => {
        let isSafariBrowser = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (!isSafariBrowser) {
            subscriber.next(true);
        }
    });
}

@NgModule({
    declarations: [
        AppComponent,
        ...fromComponents.components,
        LandingHeaderComponent,
        LandingFooterComponent,
        HeaderVersion2Component,
        SolutionsComponent,
        PricingComponent,
        SupportComponent,
        ContactComponent,
        VirtualEventsComponent,
        WebinarsComponent,
        WhitegloveServiceComponent,
        CustomerComponent,
        AnimatedDigitComponent,
        SliderAnimationComponent,
        WordCloudComponent,
        ComingSoonComponent,
        HeaderVersion2Component,
        LandingPageversion2Component,
        ProgressBarAccordionComponent,
        OnboardingV1Component,
        ResetPasswordV1Component,
        ForgotPasswordV1Component,
        VerificationComponent,
        MyCardComponent,
        EventCardDatesComponent,
        TrialEventComponent,
        EventClientsComponent,
        HomeEventViewComponent,
        HomePageEventComponent,
        IntersectionObserverDirective,
        DropdownToggleDirective,
        ContactV2Component,
        LandingPageversionOComponent,
        PricingOComponent,
        ExternalLoginComponent,
        FaqsComponent,
        FaqsLinksComponent,
        FaqQuestionsComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        SharedModule,
        AppRoutingModule,
        OverlayPanelModule,
        OverlayModule,
        ButtonModule,
        PaginatorModule,
        // ScrollingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SwiperModule,
        OwlModule,

        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],

    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: loadConfig,
            multi: true,
            deps: [AppService, RestService, AppLoggerService]
        },
        { provide: ErrorHandler, useClass: AppErrorHandler },
        {
            provide: SwRegistrationOptions,
            useFactory: swRegistrationFactory
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
